import { render, staticRenderFns } from "./ApvDetail.vue?vue&type=template&id=38fd0db8&"
import script from "./ApvDetail.vue?vue&type=script&lang=ts&"
export * from "./ApvDetail.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QHeader,QToolbar,QSpace,QTabs,QTab,QPageContainer,QTabPanels,QPage,QTabPanel,QDialog});
