








import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class HdAlternativeMessage extends Vue {
  @Prop() message!: string;
  @Prop() height!: string;
  @Prop({ default: false }) useBorder!: boolean;

  getStyle() {
    var style = "";
    if (this.height) {
      style += `height:${this.height};`;
    }
    if (this.useBorder) {
      style += `border: 1px solid #cdcfda4d;`;
    }

    return style;
  }
}
