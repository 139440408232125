












































import { Component, Vue, Watch, Prop } from "vue-property-decorator";
import DATEHANDLER from "@/common/dateHandler";

@Component
export default class HdWeekCalendar extends Vue {
  @Prop() initDate!: Date | null;
  private weekDays: Date[] = [];
  // 표시되는 주의 시작일
  private currentWeekStartDay: Date = {} as Date;
  // 선택한 날짜
  private selected: Date | null = null;
  // 오늘
  private today: Date = {} as Date;

  created() {
    this.setCalendar();
  }

  setCalendar() {
    this.today = new Date();
    this.today.setHours(0, 0, 0, 0);
    this.initDate?.setHours(0, 0, 0, 0);

    // NOTE: initDate가 null로 셋팅되어 있으면 기본 선택값(initDate)을 지정하지 않는다.
    if (this.initDate == null) {
      this.currentWeekStartDay = this.addDays(this.today, -this.today.getDay());
    } else {
      this.selected = this.initDate;
      this.currentWeekStartDay = this.addDays(this.selected, -this.selected.getDay());
    }

    for (var i = 0; i < 7; i++) {
      this.weekDays[i] = this.addDays(this.currentWeekStartDay, i);
    }
  }

  addDays(day: Date, days: number) {
    var date = new Date(day);
    date.setDate(date.getDate() + days);
    return date;
  }

  // 요일별 클래스 적용
  getDateClass(day: Date) {
    var cls = "";
    if (day.getDay() == 0) {
      // 일요일
      cls = "hd-cal-sunday";
    } else if (day.getDay() == 6) {
      // 토요일
      cls = "hd-cal-saturday";
    }
    // else {
    //   // 평일
    //   cls = "hd-cal-weekday";
    // }

    // 날짜별 클래스 적용
    day.setHours(0, 0, 0, 0);
    if (day < this.today) {
      cls += " hd-cal-passed";
    } else if (day.toString() == this.today.toString()) {
      cls += " hd-cal-today";
    }

    this.selected?.setHours(0, 0, 0, 0);
    if (day.toString() == this.selected?.toString()) {
      cls += " hd-cal-selected";
    }

    return cls;
  }

  setSelcted(select: Date) {
    select.setHours(0, 0, 0, 0);
    // if (this.today > select) {
    //   return;
    // }

    this.selected = select;
  }

  // getDisabled(select: Date) {
  //   select.setHours(0, 0, 0, 0);
  //   if (this.today > select) {
  //     return true;
  //   }
  //   return false;
  // }

  prevWeek() {
    this.currentWeekStartDay = this.addDays(this.currentWeekStartDay, -7);
    for (var i = 0; i < 7; i++) {
      this.weekDays[i] = this.addDays(this.currentWeekStartDay, i);
    }
  }

  nextWeek() {
    this.currentWeekStartDay = this.addDays(this.currentWeekStartDay, 7);
    for (var i = 0; i < 7; i++) {
      this.weekDays[i] = this.addDays(this.currentWeekStartDay, i);
    }
  }

  getCurrentMonthString() {
    var targetDay = this.currentWeekStartDay;
    // 선택된 날짜가 있고
    if (this.selected != null) {
      // 표시중인 주가 선택된 날짜 내에 있으면, 그 날짜의 월을 표시
      if (this.weekDays.indexOf(this.selected) > -1) {
        targetDay = this.selected;
      }
    }

    if (this.$i18n.locale == "ko") {
      return `${targetDay.getFullYear()}년 ${targetDay.getMonth() + 1}월`;
    } else if (this.$i18n.locale == "en") {
      return ` ${DATEHANDLER.getMonthNameEn(targetDay.getMonth())} ${targetDay.getFullYear()}`;
    }
  }

  @Watch("selected")
  handleSelectedDateChanged() {
    this.$nextTick(() => {
      this.$emit("change", this.selected);
    });
  }
}
