import { render, staticRenderFns } from "./ItemView.vue?vue&type=template&id=629d6ab0&"
import script from "./ItemView.vue?vue&type=script&lang=ts&"
export * from "./ItemView.vue?vue&type=script&lang=ts&"
import style0 from "./ItemView.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QHeader from 'quasar/src/components/header/QHeader.js';
import QToolbar from 'quasar/src/components/toolbar/QToolbar.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QPullToRefresh from 'quasar/src/components/pull-to-refresh/QPullToRefresh.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';
import QImg from 'quasar/src/components/img/QImg.js';
import QCard from 'quasar/src/components/card/QCard.js';
import QCardSection from 'quasar/src/components/card/QCardSection.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QHeader,QToolbar,QSpace,QPageContainer,QPage,QPullToRefresh,QItem,QItemSection,QSeparator,QItemLabel,QDialog,QImg,QCard,QCardSection,QBtn});qInstall(component, 'directives', {ClosePopup});
