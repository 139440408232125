






















import { Component, Vue } from "vue-property-decorator";

@Component
export default class Error extends Vue {
  goBack() {
    if (history.length && history.state != null) {
      this.$router.go(-1);
    } else {
      this.$router.replace({ name: "Front" });
    }
  }
}
