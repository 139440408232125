import axios from "axios";
// import MockAdapter from "axios-mock-adapter";
import Company from "@/models/yellowPage/Company";
import DeptTree from "@/models/yellowPage/DeptTree";
import SearchMemberRequest from "@/models/yellowPage/request/SearchMemberRequest";
import DeptMemberRequest from "@/models/yellowPage/request/DeptMemberRequest";
import PagedMeberList from "@/models/yellowPage/response/PagedMemberList";

export default {
  /**
   * 회사 목록 조회
   * @param langCode 언어코드
   */
  async getCompanyListAsync(langCode: string): Promise<Company[]> {
    const response = await axios.get<Company[]>(`/YellowPage/api/v1/company/${langCode}/`);
    return response.data;
  },
  /**
   * 부서 목록 조회
   * @param companyCode 회사코드
   * @param langCode 언어코드
   */
  async getDeptListAsync(companyCode: string, langCode: string): Promise<DeptTree[]> {
    const response = await axios.get<DeptTree[]>(
      `/YellowPage/api/v1/dept/${companyCode}/${langCode}`
    );
    return response.data;
  },

  /**
   * 부서 멤버 조회
   * @param param 부서 멤버 조회 요청(DeptMemberRequest)
   */
  async getDeptMemberListAsync(param: DeptMemberRequest): Promise<PagedMeberList> {
    const response = await axios.post<PagedMeberList>(`/YellowPage/api/v1/dept/members`, param);
    return response.data;
  },

  /**
   * 멤버 검색
   * @param param 구성원 검색 요청(SearchMemberRequest)
   */
  async searchMembersAsync(param: SearchMemberRequest): Promise<PagedMeberList> {
    const response = await axios.post<PagedMeberList>("/YellowPage/api/v1/search", param);
    return response.data;
  },
};
