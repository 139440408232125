














import { Component, Vue, Watch } from "vue-property-decorator";
import { mapActions } from "vuex";
// component
import MenuNavigator from "./components/MenuNavigator.vue";

@Component({
  components: {
    MenuNavigator,
    ItemList: () => import("./components/ItemList.vue"),
    ItemWrite: () => import("./components/ItemWrite.vue"),
  },
  methods: {
    ...mapActions(["loadConfigs"]),
  },
})
export default class Board extends Vue {
  private mainComponent: string = "";
  private loadConfigs!: () => Promise<any>;
  private isAnonymous: boolean = false;

  mounted() {
    this.setBoardType();
  }

  // 읽기 게시판, 쓰기 게시판 설정
  setBoardType() {
    this.loadConfigs().then((config) => {
      var boardUrl = this.$route.params.boardUrl as string;
      var writeBoards = config.board.writeBoard as any[];
      var anonymousBoards = config.board.anonymousBoard as any[];

      if (
        writeBoards != undefined &&
        writeBoards.length &&
        writeBoards.findIndex((o) => {
          return o.boardUrl == boardUrl;
        }) > -1
      ) {
        this.mainComponent = "ItemWrite";
        this.isAnonymous = (writeBoards.find((o) => {
          return o.boardUrl == boardUrl;
        }).isAnonymous ?? false);
      } else {
        this.mainComponent = "ItemList";
        if (anonymousBoards != undefined &&
          anonymousBoards.length &&
          anonymousBoards.findIndex((o) => {
            return o.boardUrl == boardUrl;
          }) > -1){
            this.isAnonymous = true;
        }
      }
    });
  }

  @Watch("$route")
  handleBoardChanged() {
    this.$nextTick(() => {
      if (this.$route.name == "Board") {
        this.setBoardType();
      }
    });
  }
}
