




















































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ReservationApi from "@/api/reservationApi";
import DATEHANDLER from "@/common/dateHandler";
import KVType from "@/models/common/KVType";
import UserInfo from "@/models/board/UserInfo";
import ReservationBriefResponse from "@/models/reservation/response/ReservationBriefResponse";
import Floor from "@/models/reservation/Floor";

@Component({
  components: {
    HdComboBox: () => import("@/components/HdComboBox.vue"),
    HdWeekCalendar: () => import("@/components/HdWeekCalendar.vue"),
    ReservationResultCard: () => import("./components/ReservationResultCard.vue"),
    HdAlternativeMessage: () => import("@/components/HdAlternativeMessage.vue"),
  },
})
export default class MyReservations extends Vue {
  @Prop() refresh!: number;
  @Prop() categoryId!: string;
  private user = this.$store.getters.getCurrentUser as UserInfo;
  private bldgOptions: KVType[] = [];
  private floorOptions: KVType[] = [];
  private roomOptions: KVType[] = [];

  private date: Date = new Date();
  private building: KVType = {} as KVType;
  private floor: KVType = {} as KVType;
  private room: KVType | null = null;
  //private pageNum: number = 1;
  private reservationList: ReservationBriefResponse[] = [];
  private roomSelecteDisabled: boolean = false;

  mounted() {
    ReservationApi.getBuildings().then((result) => {
      if (result.Success) {
        result.Data.forEach((building) => {
          this.bldgOptions.push({
            key: building.BuildingID.toString(),
            value: building.BuildingName,
          });
        });

        this.building = this.bldgOptions[0];
        this.setFloors();
      } else {
        this.$log.debug(result.Message);
      }
    });
  }

  handleBuildingChanged(option: KVType) {
    this.building = option;
    this.setFloors();
  }

  // 층 목록 세팅
  setFloors() {
    if (this.building == null) return;

    this.floorOptions = [];
    ReservationApi.getBuildingFloors(Number(this.building.key), this.categoryId).then((result) => {
      this.floorOptions.push({ key: "-1", value: this.$t("AllFloors").toString() });
      if (result.Success) {
        result.Data.forEach((floor: Floor) => {
          this.floorOptions.push({
            key: floor.BldgFloorID.toString(),
            value: `${floor.DisplayPosition}`,
          });
        });
      } else {
        this.$log.debug(result.Message);
      }

      this.floor = this.floorOptions[0];

      this.setRooms();
    });
  }

  // 회의실 목록
  setRooms() {
    this.roomOptions = [];
    if (this.building == null) {
      return;
    }
    var floorId: number | null =
      this.floor == null || this.floor.key == "-1" ? null : Number(this.floor.key);

    ReservationApi.getRooms(Number(this.building.key), floorId, this.categoryId)
      .then((result) => {
        if (result.Success) {
          if (result.Data.length) {
            result.Data.forEach((room) => {
              this.roomOptions.push({
                key: room.RoomID.toString(),
                value: room.RoomName,
              });
            });
            this.roomSelecteDisabled = false;
          }
          // 회의실이 없는 경우
          else {
            this.roomOptions.push({
              key: "-1",
              value: this.$t("NoMeetingRoom").toString(),
            });
            this.roomSelecteDisabled = true;
          }
          this.room = this.roomOptions[0];
          this.loadReservationList();
        } else {
          this.$log.debug(result.Message);
        }
      })
      .catch((e) => {
        this.$log.error(e);
        //this.loadingRooms = false;
      });
  }

  handleFloorChanged(option: KVType) {
    this.floor = option;
    this.setRooms();
  }

  // 예약 내역 조회
  loadReservationList() {
    if (this.room == null) {
      return;
    }

    ReservationApi.getRoomReservations(Number(this.room.key), this.date.toDateString(), true).then(
      (result) => {
        this.reservationList = result.Data;

        // NOTE: API에서 리턴값에 사옥명이 빠져있어서 수동으로 추가
        this.reservationList.forEach((item) => {
          item.RoomInfo.BuildingName = this.building.value;
        });
      }
    );
  }

  // 날짜 변경 적용
  handleDateChanged(date: Date) {
    this.date = date;
    this.loadReservationList();
  }

  handleRoomChanged(room: KVType) {
    this.room = room;
    this.loadReservationList();
  }

  getDateAsYYYYMMDD() {
    if (this.date) {
      return DATEHANDLER.dateToStrYYYYMMDD(this.date, this.$i18n.locale);
    }
    return "";
  }

  @Watch("refresh")
  updateList() {
    this.loadReservationList();
  }
}
