


















































import { Component, Vue } from "vue-property-decorator";
import UserInfo from "@/models/board/UserInfo";
import QRCodeApi from "@/api/qrcodeApi";
// component
import HdLoadingMask from "@/components/HdLoadingMask.vue";

@Component({
    components: {
        HdLoadingMask,
    },
})
export default class SeatHMX extends Vue {
    private user: UserInfo = {} as UserInfo;
    private loading: boolean = true;
    private app: any;
    private si: any;
    private rn: any;
    private sn: any;
    private q: any;

    data() {
        return {
            app: '',
            si: '',
            rn: '',
            sn: '',
            q: ''
        };
    }
    mounted() {
        this.user = this.$store.getters.getCurrentUser;
        this.loading = false;

        const urlParams = new URLSearchParams(location.search);        
        this.app = urlParams.get('app');
        this.q = urlParams.get('q');

        if (urlParams.get('si'))
            this.si = urlParams.get('si');
        if (urlParams.get('rn'))
            this.rn = urlParams.get('rn');
        if (urlParams.get('sn'))
            this.sn = urlParams.get('sn');
    }

    async checkInOut_OK()    
    {
        if (this.q == 'i'){
            QRCodeApi.seatCheckIn(this.si, window.btoa(encodeURIComponent(this.user.UserID))).then(
                (result) => {
                    if (result.Success) {
                        console.log(result.Data);
                        alert(result.Data.message);
                        if (result.Data.code == "success")
                            this.$router.push({ name: "Front" });
                        else
                        {
                            try {
                                window.webkit.messageHandlers.h1mobile.postMessage({ command: "seatReservationQR" });
                            } catch (e) {
                            //
                            }

                            try {
                                window.h1mobile.seatReservationQR();
                            } catch (e) {
                            //
                            }
                        }
                    }
            });
        }
        else if (this.q == 'o') {
            QRCodeApi.seatCheckOut(this.rn, window.btoa(encodeURIComponent(this.user.UserID))).then(
                (result) => {
                    if (result.Success) {
                        console.log(result.Data);
                        alert(result.Data.message);
                        if (result.Data.code == "success")
                            this.$router.push({ name: "Front" });
                        else
                        {
                            try {
                                window.webkit.messageHandlers.h1mobile.postMessage({ command: "seatReservationQR" });
                            } catch (e) {
                            //
                            }

                            try {
                                window.h1mobile.seatReservationQR();
                            } catch (e) {
                            //
                            }
                        }                        
                    }
            });
        }
    }

    checkInOut_cancel() {
        this.$router.push({ name: "Front" });
    }
}
