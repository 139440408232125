












































































































































































































































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import DATEHANDLER from "@/common/dateHandler";
import { EventBus, EVENT } from "@/common/eventBus";
import ReservationApi from "@/api/reservationApi";
import YellowPageApi from "@/api/yellowPageApi";
// model
import KVType from "@/models/common/KVType";
import ReservationInfo from "./models/ReservationInfo";
import UserInfo from "@/models/board/UserInfo";
import Floor from "@/models/reservation/Floor";
import Room from "@/models/reservation/Room";
import SearchMemberRequest from "@/models/yellowPage/request/SearchMemberRequest";
import MeetingRoomReservationType from "@/models/reservation/MeetingRoomReservationType";
import MakeReservationRequest from "@/models/reservation/request/MakeReservationRequest";
// component
import HdLoadingMask from "@/components/HdLoadingMask.vue";
import HdComboBox from "@/components/HdComboBox.vue";
import HdWeekCalendar from "@/components/HdWeekCalendar.vue";
import User from "@/models/yellowPage/User";
import TimeSelector from "./components/TimeSelector.vue";

@Component({
  components: {
    HdLoadingMask,
    HdComboBox,
    HdWeekCalendar,
    TimeSelector,
    HdConfirm: () => import("@/components/HdConfirm.vue"),
    ReservationCompleted: () => import("./components/ReservationCompleted.vue"),
    HdDatePicker: () => import("@/components/HdDatepicker.vue"),
  },
})
export default class Front extends Vue {
  @Prop() initInfo!: ReservationInfo;
  @Prop({ default: false }) isEdit!: boolean;
  @Prop() categoryId!: string;
  private header: string = "";
  private user = this.$store.getters.getCurrentUser as UserInfo;

  // Select 선택 옵션들..
  private bldgOptions: KVType[] = [];
  private floorOptions: KVType[] = [];
  private roomOptions: KVType[] = [];
  private roomList: Room[] = [];
  private referrerSearchResults: User[] = [];
  private attendantSearchResults: User[] = [];
  // 선택 불가 시간(현재시간 이전 + 회의실을 선택한 경우 해당 회의실이 예약된 시간)
  private unavailable: number[] = [];
  // 예약 정보
  private date: Date = new Date();
  private startTime: number | null = null;
  private endTime: number | null = null;
  private building: KVType | null = null;
  private floor: KVType | null = null;
  private room: KVType | null = null;
  private title: string = "";
  private referrerList: User[] = [];
  private attendantList: User[] = [];
  private description: string = "";

  // 처음 화면에 들어왔을 때 다른 항목을 먼저 세팅한 뒤에 시간을 세팅해야 초기화 되지 않음
  private initialized: boolean = false;
  // 시간 테이블 리셋을 위한 변수.
  private resetTimeSelection: number = 0;
  // 회의실이 없는 경우 선택 불가
  private roomSelectDisabled: boolean = false;
  private reservationCompleted: boolean = false;
  private loading: boolean = false;
  private validating: boolean = false;
  // private datePickerDialog: boolean = false;
  // 닫기 확인창
  private closeConfirmDialog: boolean = false;
  // 예약 결과
  private reservationResult: ReservationInfo = {} as ReservationInfo;
  // 날짜 선택창
  private datePickerDialog: boolean = false;

  // 검색중 다시 검색 방지용
  private memberSearching: string = "";
  // 실제로 사용하지는 않지만 q-select때문에 지정한 변수임
  private selectedMember: string = "";

  created() {
    if (this.initInfo != undefined) {
      this.date = this.initInfo.date;
      this.startTime = this.initInfo.startTime;
      this.endTime = this.initInfo.endTime;
      this.building = this.initInfo.bldgOption;
      this.floor = this.initInfo.floorOption;
      this.room = this.initInfo.room;
    }

    this.header = this.isEdit
      ? this.$t("ModifyReservation").toString()
      : this.$t("MakeReservation").toString();

    if (this.isEdit) {
      this.title = this.initInfo.title;
      this.description = this.initInfo.description;
      this.referrerList = this.initInfo.referrerList;
      this.attendantList = this.initInfo.attendantList;
    }
  }

  mounted() {
    // this.setBuildingOptions();
    this.initialize();
  }

  async initialize() {
    // 1. 사옥 설정
    await this.setBuildingOptions();
    // 2. 세팅된 사옥의 층 목록을 세팅한다.
    await this.setFloors();
    // 3. 선택된 사옥과 층의 회의실 목록을 세팅한다.
    await this.setRooms();

    // this.startTime = this.initInfo.startTime;
    // this.endTime = this.initInfo.endTime;
    this.loading = false;
    this.$nextTick(() => {
      if (this.initInfo != undefined) {
        this.initialized = true;
      }
    });
    //
  }

  // 사옥 설정
  async setBuildingOptions() {
    return await ReservationApi.getBuildings()
      .then((result) => {
        if (result.Success) {
          this.bldgOptions = [];
          result.Data.forEach((building) => {
            this.bldgOptions.push({
              key: building.BuildingID.toString(),
              value: building.BuildingName,
            });
          });

          //this.setFloors();
        } else {
          this.$log.debug(result.Message);
        }
      })
      .catch((e) => {
        this.$log.error(e);
      });
  }

  // 층 목록 세팅
  async setFloors() {
    if (this.building == null) {
      return;
    }
    this.floorOptions = [];

    await ReservationApi.getBuildingFloors(Number(this.building.key), this.categoryId).then(
      (result) => {
        this.floorOptions.push({ key: "-1", value: this.$t("AllFloors").toString() });
        if (result.Success) {
          result.Data.forEach((floor: Floor) => {
            this.floorOptions.push({
              key: floor.BldgFloorID.toString(),
              value: `${floor.DisplayPosition}`,
            });
          });
        } else {
          this.$log.debug(result.Message);
        }

        // 처음에는 파라미터로 넘어온 값이 있으면 세팅해주고, 초기화 이후에는 첫 번째 층으로 셋
        if (this.initialized) {
          this.floor = this.floorOptions[0];
        }

        //this.setRooms();
      }
    );
  }

  // 회의실 목록
  async setRooms() {
    this.roomOptions = [];
    if (this.building == null) {
      return;
    }

    var floorId: string | null = this.floor?.key ?? null;
    if (floorId == null || floorId == "-1") {
      floorId = null;
    }

    // var floorId: number | null =
    //   this.floor == null || this.floor.key == "-1" ? null : Number(this.floor.key);

    await ReservationApi.getRooms(
      Number(this.building.key),
      floorId != null ? Number(floorId) : null,
      this.categoryId
    )
      .then(async (result) => {
        if (result.Success) {
          if (result.Data.length) {
            this.roomList = result.Data;
            result.Data.forEach((room) => {
              this.roomOptions.push({
                key: room.RoomID.toString(),
                value: room.RoomName,
              });
            });
            this.roomSelectDisabled = false;
          }
          // 회의실이 없는 경우
          else {
            this.roomOptions.push({
              key: "-1",
              value: this.$t("NoMeetingRoom").toString(),
            });
            this.roomSelectDisabled = true;
          }

          // 처음에는 파라미터로 넘어온 값이 있으면 세팅해주고, 초기화 이후에는 첫 번째 회의실로 셋
          if (this.initialized || this.room == null) {
            this.room = this.roomOptions[0];
          }
        } else {
          this.$log.debug(result.Message);
        }
      })
      .catch((e) => {
        this.$log.error(e);
      });

    await this.setUnavailableHours();
  }

  // 선택된 시간 초기화
  resetSelectedTime() {
    this.startTime = null;
    this.endTime = null;
    this.resetTimeSelection++;
  }

  // 날짜 변경 적용
  async handleDateChanged(date: Date, closeDialog: boolean) {
    this.date = date;
    if (closeDialog) {
      this.datePickerDialog = false;
      await this.setUnavailableHours();
      this.resetSelectedTime();
    }
  }

  // 시간 변경 적용
  handleTimeChanged(startTime: number | null, endTime: number | null) {
    this.startTime = startTime;
    this.endTime = endTime;
  }

  // 사옥 변경
  handleBuildingChanged(option: KVType) {
    this.building = option;

    this.setFloors().then(this.setRooms);
  }

  changeFloor(option: KVType) {
    this.floor = option;
    this.setRooms();
  }

  async changeRoom(option: KVType) {
    this.room = option;
    await this.setUnavailableHours();
    this.resetSelectedTime();
  }

  // 예약할 수 없는 시간 설정
  async setUnavailableHours() {
    // if (this.initialized) {
    //   this.resetSelectedTime();
    // }

    var today = new Date();
    this.unavailable = [];

    // 회의실이 없을 때
    if (this.room == null || this.room == undefined || this.room?.key == "-1") {
      for (let i = 7; i <= 21; i++) {
        this.unavailable.push(i);
      }
      //this.initialized = true;
      return;
    }

    // 오늘일 때
    if (today.toDateString() == this.date.toDateString()) {
      for (let i = 7; i <= today.getHours(); i++) {
        this.unavailable.push(i);
      }
    }
    // 오늘 이전일 때
    else if (today > this.date) {
      for (let i = 7; i <= 21; i++) {
        this.unavailable.push(i);
      }
    }

    await ReservationApi.getRoomReservations(
      Number(this.room.key),
      this.date.toDateString(),
      false
    ).then((result) => {
      if (result.Success) {
        result.Data.forEach((reservation) => {
          // 예약을 수정할 경우, 기존 예약 시간 선택 가능
          if (this.isEdit && this.initInfo.reservationId == reservation.RoomInfo.ReservationID) {
            return;
          }
          const startTime = new Date(reservation.RoomInfo.ResStartDatetime);
          const endTime = new Date(reservation.RoomInfo.ResEndDatetime);

          // 정각에 끝나면 예약 가능
          const to = endTime.getMinutes() == 0 ? endTime.getHours() - 1 : endTime.getHours();

          for (let i = startTime.getHours(); i <= to; i++) {
            this.unavailable.push(i);
          }
        });
      }
    });
  }

  addMember(type: "referrer" | "attendant", member: User) {
    var target = type == "referrer" ? this.referrerList : this.attendantList;
    target.push(member);
    this.$nextTick(() => {
      this.selectedMember = "";
    });
  }

  // 추가한 멤버 삭제
  removeMember(type: "referrer" | "attendant", memberId: string) {
    var target = type == "referrer" ? this.referrerList : this.attendantList;
    let i = target.findIndex((o) => {
      return o.UserID == memberId;
    });
    target.splice(i, 1);
  }

  isSelectedMember(type: "referrer" | "attendant", memberId: string) {
    var target = type == "referrer" ? this.referrerList : this.attendantList;
    let i = target.findIndex((o) => {
      return o.UserID == memberId;
    });
    if (i > -1) {
      return true;
    }
    return false;
  }

  // 참석자 검색
  searchattendant(val: string, update: Function, abort: Function) {
    val = val.trim();

    if (val.length < 2) {
      abort();
      return;
    }

    if (this.memberSearching == val) {
      return;
    }

    this.attendantSearchResults = [];
    this.searchMember(val).then((result) => {
      update(() => {
        this.attendantSearchResults = result;
        this.memberSearching = "";
      });
    });
  }

  // 참조자 검색
  searchReferrer(val: string, update: Function, abort: Function) {
    val = val.trim();

    if (val.length < 2) {
      abort();
      return;
    }

    if (this.memberSearching == val) {
      return;
    }

    this.referrerSearchResults = [];
    this.searchMember(val).then((result) => {
      update(() => {
        this.referrerSearchResults = result;
      });
    });
  }

  // 멤버 검색
  async searchMember(keyword: string): Promise<User[]> {
    var searchOption: SearchMemberRequest = {
      CompanyCode: this.user.ActivatedTenantId,
      PageNumber: 1,
      PageSize: 30,
      SearchType: "NAME",
      SearchKeyword: keyword,
      LangCode: this.user.UserLangCode,
    };

    return await YellowPageApi.searchMembersAsync(searchOption)
      .then((result) => {
        return result.Items;
      })
      .catch(() => {
        return [];
      });
  }

  // validation & 저장
  validateAndSubmit() {
    this.validating = true;
    // 제목 검사
    if (this.title.trim().length == 0) {
      let field = this.$refs.title as HTMLInputElement;
      field.focus();
      field.scrollIntoView({ behavior: "smooth", block: "center" });
      return;
    }

    // 회의실 검사
    if (this.room == null || this.room.key == "-1") {
      (this.$refs.room as HTMLElement).scrollIntoView({ behavior: "smooth", block: "center" });
      return;
    }

    // 시간 검사
    if (this.startTime == null || this.endTime == null) {
      (this.$refs.time as HTMLElement).scrollIntoView({ behavior: "smooth", block: "center" });
      return;
      //field.focus();
    }

    // 건물,층은 자동 선택됨..
    if (this.building == null) {
      return;
    }

    if (this.floor == null || this.floor.key == "-1") {
      var roomId = Number(this.room.key);
      var room = this.roomList.find((o) => {
        return o.RoomID == roomId;
      }) as Room;

      this.floor = {
        key: room.BldgFloorID.toString(),
        value: `${room.DisplayPosition}`,
      };
    }

    var startDateTime: Date = new Date(this.date);
    var endDateTime: Date = new Date(this.date);

    startDateTime.setHours(this.startTime, 0, 0, 0);
    endDateTime.setHours(this.endTime, 0, 0, 0);
    startDateTime = DATEHANDLER.convertUTCDateToLocalDate(startDateTime);
    endDateTime = DATEHANDLER.convertUTCDateToLocalDate(endDateTime);

    var referrers = this.referrerList.map(function (referrer) {
      return referrer.UserID;
    });

    var attendants = this.attendantList.map(function (attendant) {
      return attendant.UserID;
    });

    var reservation: MeetingRoomReservationType = {
      ReservationID: this.initInfo.reservationId,
      ReservationDatetime: DATEHANDLER.convertUTCDateToLocalDate(this.date).toISOString(),
      ResStartDatetime: startDateTime.toISOString(),
      ResEndDatetime: endDateTime.toISOString(),
      RoomID: Number(this.room.key),
      BuildingID: Number(this.building.key),
      BldgFloorID: this.floor ? Number(this.floor.key) : -1,
      Title: this.title,
      Memo: this.description,
      ResEmpNo: this.user.UserID,
      ResUserName: this.user.UserName,
      ResUserEmail: this.user.EmailAddress,
      ResUserHp: this.user.MobileTel,
      CreatedDatetime: DATEHANDLER.convertUTCDateToLocalDate(new Date()).toISOString(),
      UpdatedDatetime: null,
      ProcessState: null,
      CategoryID: this.categoryId,
    } as MeetingRoomReservationType;

    var reservationRequest: MakeReservationRequest = {
      Reservation: reservation,
      AttendantIDs: attendants,
      CcIDs: referrers,
    };

    this.loading = true;
    if (this.isEdit) {
      // 수정
      this.updateReservation(reservationRequest);
    } else {
      // 등록
      this.createReservation(reservationRequest);
    }
  }

  // 회의실 예약 수정
  updateReservation(request: MakeReservationRequest) {
    ReservationApi.updateReservation(request)
      .then((result) => {
        if (result.Success) {
          this.reservationResult = {
            reservationId: this.initInfo.reservationId,
            date: this.date,
            startTime: this.startTime,
            endTime: this.endTime,
            bldgOption: this.building,
            floorOption: this.floor,
            room: this.room,
            title: this.title,
            referrerList: this.referrerList,
            attendantList: this.attendantList,
            description: this.description,
            processState: null,
          };

          this.loading = false;
          this.reservationCompleted = true;
          EventBus.$emit(EVENT.REFRESH_RESERVATIONS);
        } else {
          this.handleReservationFailure();
          this.$log.debug(result.Message);
        }
      })
      .catch(() => {
        this.handleReservationFailure();
      });
  }

  // 회의실 예약
  createReservation(request: MakeReservationRequest) {
    ReservationApi.makeReservation(request)
      .then((result) => {
        if (result.Success) {
          this.reservationResult = {
            reservationId: null,
            date: this.date,
            startTime: this.startTime,
            endTime: this.endTime,
            bldgOption: this.building,
            floorOption: this.floor,
            room: this.room,
            title: this.title,
            referrerList: this.referrerList,
            attendantList: this.attendantList,
            description: this.description,
            processState: null,
          };

          this.loading = false;
          this.reservationCompleted = true;
          EventBus.$emit(EVENT.REFRESH_RESERVATIONS);
        } else {
          this.handleReservationFailure();
        }
      })
      .catch(() => {
        this.handleReservationFailure();
      });
  }

  handleReservationFailure() {
    this.$q.notify({
      message: this.$t("ReservationFailedMessage").toString(),
      group: false,
      color: "white",
      textColor: "primary",
      timeout: 500,
    });
    this.initialize();
  }

  // 닫기 버튼 클릭
  handleClickClose() {
    if (!this.reservationCompleted && !this.isEdit) {
      this.closeConfirmDialog = true;
    } else {
      var refreshPage = true;
      this.$emit("close", refreshPage);
    }
  }

  // 닫기 확인 액션
  handleDecision(keepPage: boolean) {
    if (keepPage) {
      return;
    } else {
      this.$emit("close");
    }
  }

  // 내용 입력창에 포커스
  setFocus() {
    (this.$el.querySelector("textarea") as HTMLTextAreaElement).focus();
  }

  getDateAsYYYYMMDD() {
    return DATEHANDLER.dateToStrYYYYMMDD(this.date, this.$i18n.locale);
  }
}
