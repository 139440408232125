var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"hd-apv-line-card",class:_vm.currentStepStateClass()},[_c('div',{staticClass:"col"},[_c('q-card',{staticClass:"hd-apv-line-card__content",attrs:{"flat":""}},[_c('div',{class:_vm.getApvTypeClass()},[_c('div',{staticClass:"hd-apv-line-card__state"},[_c('span',{staticClass:"badge-lg"},[_vm._v(_vm._s(_vm.lineStepOrder))]),_c('span',{staticClass:"hd-apv-line-card__state__label"},[_vm._v(_vm._s(_vm.lineApvType))])])]),_vm._l((_vm.groupUsers),function(lineUser,index){return _c('div',{key:lineUser.lineStepID},[_c('q-card-section',[_c('div',{staticClass:"full-width row justify-between"},[_c('div',[_c('span',{staticClass:"col hd-text-ml-bold",style:(lineUser.isSelectedDelegateUser && lineUser.delegateUserID.length
                    ? 'opacity: 0.5'
                    : ''),domProps:{"innerHTML":_vm._s(_vm.getLineUserDisplayName(lineUser))}}),_c('span',{staticClass:"hd-text-ml",staticStyle:{"line-height":"21px"},style:(lineUser.isSelectedDelegateUser && lineUser.delegateUserID.length
                    ? 'opacity: 0.5'
                    : '')},[_vm._v(" "+_vm._s(lineUser.lineUserTitle))])]),_c('div',{staticStyle:{"display":"flex","height":"21px"}},[(_vm.statusString(lineUser).length)?_c('span',{staticClass:"badge color08 hd-apv-line-card__badge"},[_c('span',[_vm._v(" "+_vm._s(_vm.statusString(lineUser)))])]):_vm._e(),(lineUser.isSelectedDelegateUser && lineUser.delegateUserID.length)?_c('span',{staticClass:"badge color09 hd-apv-line-card__badge",staticStyle:{"margin-left":"4px"}},[_c('span',[_vm._v("대결")])]):_vm._e()])]),_c('div',{style:(lineUser.isSelectedDelegateUser && lineUser.delegateUserID.length
                ? 'opacity: 0.5'
                : '')},[_c('span',{staticClass:"hd-text-nm hd-text-primary"},[_vm._v(" "+_vm._s(lineUser.lineDeptName)+" ")])]),(lineUser.isSelectedDelegateUser && lineUser.delegateUserID.length)?_c('div',{staticStyle:{"padding-top":"6px"}},[_c('div',{staticStyle:{"display":"flex"}},[_c('i',{staticClass:"hd-icon-instead",staticStyle:{"align-self":"baseline","margin-right":"4px"}}),_c('div',{staticClass:"col"},[_c('div',[_c('span',{staticClass:"hd-text-ml-bold"},[_vm._v(" "+_vm._s(lineUser.delegateUserName)+" ")])]),_c('div',[_c('span',{staticClass:"hd-text-nm hd-text-primary"},[_vm._v(" "+_vm._s(lineUser.delegateDeptName)+" ")])]),(lineUser.isApproved)?_c('div',{staticClass:"hd-caption-teriary",staticStyle:{"line-height":"28px"},attrs:{"caption":""},domProps:{"innerHTML":_vm._s(
                    lineUser.isApproved ? _vm.getDateAsYYYYMMDDHHmm(lineUser.apvDateTime) : '&nbsp;'
                  )}}):_vm._e()])])]):_vm._e(),(
              lineUser.isApproved &&
              !(lineUser.isSelectedDelegateUser && lineUser.delegateUserID.length)
            )?_c('div',{staticClass:"hd-caption-teriary",staticStyle:{"line-height":"28px"},attrs:{"caption":""},domProps:{"innerHTML":_vm._s(lineUser.isApproved ? _vm.getDateAsYYYYMMDDHHmm(lineUser.apvDateTime) : '&nbsp;')}}):_vm._e()]),(lineUser.comment.length)?_c('q-card-section',{staticClass:"hd-apv-line-card__comment"},[_c('div',{staticClass:"hd-text-sm"},[_vm._v(_vm._s(lineUser.comment))])]):_vm._e(),_c('apv-modification-opinion-item',{attrs:{"opinionString":lineUser.modificationOpinionsJson,"hasApprovalAuth":_vm.hasApprovalAuth}}),(_vm.groupUsers.length - 1 > index)?_c('div',{staticClass:"hd-line-separator-light30",staticStyle:{"margin-right":"16px","margin-left":"16px"}}):_vm._e()],1)})],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }