import { render, staticRenderFns } from "./MemberList.vue?vue&type=template&id=31c8baf0&scoped=true&"
import script from "./MemberList.vue?vue&type=script&lang=ts&"
export * from "./MemberList.vue?vue&type=script&lang=ts&"
import style0 from "./MemberList.vue?vue&type=style&index=0&id=31c8baf0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "31c8baf0",
  null
  
)

export default component.exports
import QScrollObserver from 'quasar/src/components/scroll-observer/QScrollObserver.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QAvatar from 'quasar/src/components/avatar/QAvatar.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import QDialog from 'quasar/src/components/dialog/QDialog.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QScrollObserver,QList,QItem,QItemSection,QAvatar,QItemLabel,QSpinnerDots,QDialog});qInstall(component, 'directives', {Ripple});
