


































































import { Component, Vue } from "vue-property-decorator";
import AuthApi from "@/api/authApi";
import MobileUserInfo from "@/models/auth/MobileUserInfo";
// Components
import HdLoadingMask from "@/components/HdLoadingMask.vue";

const SAVE_ID = "SAVE_ID";
const USER_ID = "SAVED_USERID";
const url = window.location.host;
const protocol = window.location.protocol;

@Component({
  components: {
    HdLoadingMask,
  },
})
export default class Login extends Vue {
  private userId: string = "";
  private password: string = "";
  private saveId: boolean = false;

  private wait: boolean = true;

  mounted() {
    try {
      if (this.$route.query.ReturnUrl.toString().toLowerCase().indexOf("qrcode") > 0)
      {
        var url = encodeURIComponent(btoa(`${window.location.origin}${this.$route.query.ReturnUrl}`));
        window.location.replace(`h1mobile://openURL?url=${url}`);

        setTimeout(() => {
          window.close();
        }, 10000);
      }
    }
    catch {
      
    }
    
    // Teams 앱을 위해 조건 추가
    if (this.$route.query.frm != undefined
      && this.$route.query.to != undefined
      && this.$store.getters.getCurrentUser != undefined) //frm 파라미터 와 $store 값이 존재하는지 검증
    {
      //frm 값이 teams 인 경우
      if (this.$route.query.frm.toString().toLowerCase() == "teams"
        && this.$store.getters.getCurrentUser.UserID != undefined)
      {
        //UserID 값이 존재하는 경우 페이지 이동
        //AuthApi.checkCurrentState(this.$store.getters.getCurrentUser.UserID.trim()).then((result) => {
        AuthApi.checkCurrentState(null).then((result) => {
          if (result.Success) {
            this.passthroughLogin(this.$route.query.to as string); 
          }
          else {
            this.$store.dispatch("logout").then(() => {
              // 앱에서 로그인 화면에 안 보이게 하기 위한 장치
              this.wait = false;
            });
          }
        });
      }
    } else {
      // NOTE: ios에서 인증처리가 제대로 되지 않아서 로그인 페이지에 id, pw를 직접 받아서 인증처리후 to로 redirect해주는 로직이다.
      if (this.$route.query.id != undefined && this.$route.query.password != undefined) {
        AuthApi.userLoginAsync(
          this.$route.query.id as string,
          this.$route.query.password as string
        ).then((result) => {
          if (result.Success) {
            AuthApi.checkCurrentState(null).then((result) => {
              this.$log.debug("[Login] processLogin");
              if (result.Success) {
                this.handleLoginSuccess(result.Data, this.$route.query.to as string);
              }
            });
            return;
          }
        });
        return;
      }
      // NOTE: 폼 인증 만료로 자동으로 웹 로그인 페이지로 리디렉션 되는데, 그럼 앱에서 웹 로그인 화면이 뜨게되므로
      // 웹 로그인 화면에서 사용자 정보가 남아있으면 로그아웃 이벤트를 다시 발생하도록 코드를 추가함
      // 자세한 내용은 store\index.ts의 checkSessionUser확인
      if (this.$store.getters.getCurrentUser) {
        this.$store.dispatch("logout").then(() => {
          // 앱에서 로그인 화면에 안 보이게 하기 위한 장치
          this.wait = false;
        });
        return;
      }
      this.saveId = Boolean(this.$cookies.get(SAVE_ID) ?? "false");
      if (this.saveId) {
        this.userId = String(this.$cookies.get(USER_ID) ?? "");
      }

      this.wait = false;
    }
  }

  processLogin() {
    if (!this.userId || !this.userId.trim().length) {
      (this.$refs.iptId as HTMLInputElement).focus();
      return;
    }

    if (!this.password || !this.password.trim().length) {
      (this.$refs.iptPwd as HTMLInputElement).focus();
      return;
    }

    if (process.env.NODE_ENV === "production") {
      AuthApi.userLoginAsync(
        encodeURIComponent(this.userId.trim()),
        encodeURIComponent(this.password.trim())
      ).then((result) => {
        if (result.Success) {
          AuthApi.checkCurrentState(null).then((result) => {
            this.$log.debug("[Login] processLogin");
            if (result.Success) {              
              if (this.$route.query.ReturnUrl != undefined) {
                if (this.$route.query.ReturnUrl.indexOf("qrcode") > 0) {
                  window.location.href = `${location.origin}${this.$route.query.ReturnUrl}`;
                  return;
                }
              }
              
              if (this.$route.query.to != undefined)
                this.handleLoginSuccess(result.Data, this.$route.query.to as string);
              else
                this.handleLoginSuccess(result.Data);
            }
          });
        } else {
          this.notifyLoginFailed();
          this.$log.debug(`[Login] loginFailed: ${result.Message}`);
        }
      });
    }
    // NOTE: 개발환경에서만 사용!
    else {
      AuthApi.userAuthenticationAsync(this.userId.trim()).then((result) => {
        if (result.Success) {
          if (this.$route.query.to != undefined)
            this.handleLoginSuccess(result.Data, this.$route.query.to as string);
          else
            this.handleLoginSuccess(result.Data);
        } else {
          this.notifyLoginFailed();
          this.$log.debug(`[Login] loginFailed: ${result.Message}`);
        }
      });
    }
  }

  notifyLoginFailed() {
    this.$q.notify({
      message: "아이디 또는 비밀번호가 잘못되었습니다.",
      group: false,
      color: "white",
      textColor: "primary",
      position: "top",
      timeout: 500,
    });
  }

  handleLoginSuccess(data: MobileUserInfo, to: string = "Download") {
    // ID 저장 쿠키 설정
    this.$cookies.set(SAVE_ID, this.saveId);
    if (this.saveId) {
      this.$cookies.set(USER_ID, this.userId);
    } else {
      this.$cookies.remove(USER_ID);
      this.$cookies.remove(SAVE_ID);
    }
    this.$store.dispatch("login", data).then(() => {
      this.$router.push({ path: to }).catch(() => {
        //
      });
    });
  }

  passthroughLogin(to: string) {
    this.$router.push({ path: to }).catch(() => {
      //
    });
  }
}
