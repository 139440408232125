













import { Component, Vue, Prop } from "vue-property-decorator";
import MealInfo from "@/models/cafeteriaMenu/MealInfo";

@Component
export default class MenuItem extends Vue {
  @Prop() mealInfo!: MealInfo;
  private menu: string = "";

  mounted() {
    this.menu = this.mealInfo.Menu.split(",").join("<br/>");
  }
}
