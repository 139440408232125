


























































































import { Component, Vue, Watch } from "vue-property-decorator";
import { mapActions } from "vuex";
import ApprovalApi from "@/api/approvalApi";
import MenuHandler from "@/common/menuHandler";
import { EventBus, EVENT } from "@/common/eventBus";
// Models
import ApprovalUser from "@/models/approval/ApprovalUser";
import LineInfo from "@/models/approval/LineInfo";
import ConntentInfo from "@/models/approval/ContentInfo";
import UserInfo from "@/models/board/UserInfo";
import Attachment from "@/models/approval/Attachment";
import Reference from "@/models/approval/Reference";
import { ApvAdvancedSearchListType } from "@/models/approval/enums/ApvEnums";
// Components
import HdLoadingMask from "@/components/HdLoadingMask.vue";

type Tabs = "summary" | "line" | "content" | "state" | "attach";

@Component({
  components: {
    HdLoadingMask,
    ApvLine: () => import("./components/ApvLine.vue"),
    ApvSummary: () => import("./components/ApvSummary.vue"),
    ApvContent: () => import("./components/ApvContent.vue"),
    ApvAttachment: () => import("./components/ApvAttachment.vue"),
    ApvReceivers: () => import("./components/ApvReceivers.vue"),
    OriginalDocumentPopup: () => import("./popups/OriginalDocumentPopup.vue"),
  },
  methods: {
    ...mapActions(["loadConfigs"]),
  },
})
export default class ApvDetail extends Vue {
  private loadConfigs!: () => Promise<any>;
  private apvId = Number(this.$route.params.apvId);
  private user: UserInfo = {} as UserInfo;
  private apvUser: ApprovalUser = {} as ApprovalUser;
  private lineInfo: LineInfo = {} as LineInfo;
  private contentInfo: ConntentInfo = {} as ConntentInfo;
  private option: Tabs = "summary";
  private optionTab = [
    { value: "summary", name: this.$t("ApvMobile_Summary") },
    { value: "line", name: this.$t("ApvMobile_ApvLine") },
    { value: "content", name: this.$t("ApvMobile_Content") },
    { value: "receivers", name: this.$t("ApvMobile_Receivers") },
    { value: "attach", name: this.$t("ApvMobile_Attachments") },
  ];
  private loading = true;
  private attachs: Attachment[] = [];
  private references: Reference[] = [];
  // 결재 상태 변경시 화면 업데이트를 위한 변수
  private refresh: number = 0;

  // 원문보기
  private viewOriginalDocumentDialog: boolean = false;
  private titleMargin: string = "";

  created() {
    EventBus.$on(EVENT.APPROVAL_REFRESH_DETAIL, () => {
      this.handleRefresh();
    });
  }

  mounted() {
    this.loadApprovalDetail().then(this.adjustTitlePadding);
  }

  async loadApprovalDetail() {
    if (this.$route.query.tab) {
      this.option = this.$route.query.tab as Tabs;
    }

    var userLang = this.$i18n.locale;
    var sessionUser = this.$store.getters.getCurrentUser;
    if (sessionUser != undefined) {
      this.user = sessionUser;

      await ApprovalApi.getDocumentAsync(this.apvId, userLang, this.user.UserID)
        .then((result) => {
          this.apvUser = result.data[0];

          this.lineInfo = result.data[1];
          this.contentInfo = result.data[2];

          this.attachs = result.data[3];
          this.references = result.data[4];

          this.loading = false;
          this.refresh++;
        })
        .catch((e) => {
          this.$log.debug("[ApvDetail]", e);
          this.loading = false;
        });
    }
  }

  async goBack() {
    if (history.length && history.state != null) {
      this.$router.go(-1);
    } else {
      var config = await this.loadConfigs();
      var apvMenu = await MenuHandler.findMenuByMenuId(config.approval.menuId);
      if (apvMenu != undefined && apvMenu.Children.length) {
        this.$router.push(apvMenu.Children[0].Url);
      } else {
        this.$router.push({
          name: "ApvList",
          params: { listType: ApvAdvancedSearchListType.WaitingApproval },
        });
      }
    }
  }

  handleRefresh() {
    this.loadApprovalDetail();
  }

  // 제목 길이와 언어코드에 따라 여백을 동적으로 조절한다.
  adjustTitlePadding() {
    const marginLang = this.$i18n.locale == "ko" ? 57 : 85;
    const marginIcon = 24; // 아이콘 여백
    const marginDefault = 16 * 2; // 기본 좌우 여백

    // NOTE: "원문보기"의 다국어에 따라 우측 여백이 변경되어야 하는데 제목이 화면 중앙에 오도록 양쪽 여백을 동일하게 준다.
    var marginSum = marginLang * 2 + marginDefault; // 전체 여백
    var maxWidth = this.$root.$el.clientWidth - marginSum;
    this.titleMargin = `margin-right:${marginLang}px;margin-left:${marginLang}px`;

    // 제목이 길어지면 왼쪽 여백을 최대한 활용한다. 아이콘 사이즈 만큼의 여백만 남김
    var offsetWidth = (this.$refs.title as HTMLDivElement).offsetWidth;
    if (offsetWidth > maxWidth) {
      this.titleMargin = `margin-right:${marginLang}px;margin-left:${marginIcon}px`;
    }
  }

  // 근거문서 조회, 뒤로가기 버튼시
  @Watch("$route")
  handleApvIdChanged() {
    this.$nextTick(() => {
      var newVal = Number(this.$route.params.apvId);
      if (this.apvId != newVal) {
        this.apvId = newVal;
        this.loadApprovalDetail();
        this.option = "summary";
      }
    });
  }

  @Watch("option")
  handleOptionChanged() {
    this.$nextTick(() => {
      this.$router
        .replace({
          query: {
            tab: this.option,
          },
        })
        .catch(() => {
          //
        });
    });
  }
}
