import Vue from "vue";
import Vuex, { ActionContext } from "vuex";
import UserInfo from "@/models/board/UserInfo";
import MobileMenu from "@/models/menu/MobileMenu";
import createPersistedState from "vuex-persistedstate";
import MobileApi from "@/api/mobileApi";
import AuthApi from "@/api/authApi";
import MobileUserInfo from "@/models/auth/MobileUserInfo";
import router from "@/router";

Vue.use(Vuex);

// 만료 시간
//const EXP_TIME = 60 * 60 * 1000; //1시간
const EXP_TIME = 60 * 60 * 24000 * 30; //30일

interface sessionItem<T> {
  value: T;
  timestamp: number;
}

interface State {
  currentUser: UserInfo | undefined;
  menus: sessionItem<MobileMenu[]> | undefined;
  configs: any | undefined;
  lastAccessTime: Date | undefined;
}

function isExpired(timestamp: number): boolean {
  const now = new Date().getTime();

  if (now - timestamp > EXP_TIME) {
    return true;
  }
  return false;
}

export default new Vuex.Store<State>({
  state: {
    currentUser: undefined,
    menus: undefined,
    configs: undefined,
    lastAccessTime: undefined,
  },
  getters: {
    /**
     * 로그인된 사용자 정보
     */
    getCurrentUser(state: State) {
      return state.currentUser;
    },

    /**
     * 모바일 메뉴 전체
     */
    getMenuList(state: State) {
      const menus = state.menus;
      if (menus != undefined) {
        // 만료시간이 지났으면 정보 삭제
        if (isExpired(menus.timestamp)) {
          state.menus = undefined;
          return undefined;
        }
        // 만료시간 이전이면 메뉴 정보를 가져오고 timestamp도 업데이트
        else {
          menus.timestamp = new Date().getTime();

          return menus.value;
        }
      }
    },

    /** 마지막 접속시간 */
    getLastAccessTime(state: State) {
      return state.lastAccessTime;
    },

    /**
     * 모바일 설정
     */
    getConfigs(state: State) {
      const configs = state.configs;
      if (configs != undefined) {
        // 만료시간이 지났으면 정보 삭제
        if (isExpired(configs.timestamp)) {
          state.configs = undefined;
          return undefined;
        }
        // 만료시간 이전이면 메뉴 정보를 가져오고 timestamp도 업데이트
        else {
          configs.timestamp = new Date().getTime();

          return configs.value;
        }
      }
    },
  },
  mutations: {
    setCurrentUser(state: State, user: UserInfo) {
      state.currentUser = user;
    },

    setLastAccessTime(state: State, lastAccessTime: string) {
      if (lastAccessTime != undefined || lastAccessTime != "") {
        if (new Date()) state.lastAccessTime = new Date(lastAccessTime);
      }
    },

    setMenuList(state: State, menus: MobileMenu[]) {
      state.menus = {
        value: menus,
        timestamp: new Date().getTime(),
      };
    },

    setConfigs(state: State, config: any) {
      state.configs = {
        value: config,
        timestamp: new Date().getTime(),
      };
    },

    clearAll(state: State) {
      state.currentUser = undefined;
      state.menus = undefined;
      state.configs = undefined;
      state.lastAccessTime = undefined;
    },
  },
  actions: {
    async loadConfigs({ getters, commit }: ActionContext<State, State>) {
      const config = getters.getConfigs;
      if (config == undefined) {
        const user = getters.getCurrentUser;
        if (user) {
          return MobileApi.getServerConfigsAsync(user.ActivatedTenantId)
            .then((result) => {
              if (result.Success) {
                commit("setConfigs", result.Data);
                return result.Data;
              }
            })
            .catch(() => {
              //log.error("[vuex]", e);
            });
        }
      } else {
        return config;
      }
    },

    async loadMobileMenu({ getters, commit }: ActionContext<State, State>) {
      const menu = getters.getMenuList;
      if (menu == undefined) {
        const user = getters.getCurrentUser;
        if (user) {
          return MobileApi.getMobileMenuListAsync(
            user.ActivatedTenantId,
            user.UserLangCode,
            user.UserID
          )
            .then((data) => {
              commit("setMenuList", data);
              return data;
            })
            .catch(() => {
              //this.$log.log(e);
              return undefined;
            });
        }
      } else {
        return menu;
      }
    },

    async login({ dispatch, commit }: ActionContext<State, State>, data: MobileUserInfo) {
      // 캐시 남아있는거 먼저 삭제
      commit("clearAll");

      commit("setCurrentUser", data.UserInfo);
      commit("setLastAccessTime", data.LastAccessTime);

      await dispatch("loadConfigs");
      await dispatch("loadMobileMenu");
    },

    async logout({ commit }: ActionContext<State, State>) {
      return await AuthApi.userLogoutAsync().then(() => {
        commit("clearAll");

        try {
          window.webkit.messageHandlers.h1mobile.postMessage({ command: "logout" });
          return;
        } catch (e) {
          //
        }

        try {
          window.h1mobile.logout();
          return;
        } catch (e) {
          //
        }

        router.replace({ name: "Login" });
      });
    },

    // 만료시간이 지났으면 로그아웃처리
    // NOTE: 처음에는 클라이언트단에서 로그인 후 일정 시간동안 사용하지 않으면 자동 로그아웃 하도록 처리했으나,
    // 모바일 앱 종료시 웹 세션이 자동으로 날아가지 않아서 다시 켰을 때 기존 정보가 남아있는 경우가 있다.
    // 이때, 정보는 있는데 유효시간이 만료되었다고 판단하여 로그아웃 이벤트가 발생하고,
    // 앱 입장에서는 방금 로그인 한 정보를 저장하기 전에 로그아웃이 발생해서 다시 로그인 화면으로 이동하는 일이 발생한다.
    // 때문에 서버에 폼 인증에 만료시간을 설정하고, 쿠키 유효 여부를 다시 확인해주도록 추가함.
    // 문제는 폼 인증 만료로 자동으로 웹 로그인 페이지로 리디렉션 되는데, 그럼 앱에서 웹 로그인 화면이 뜨게되므로
    // 웹 로그인 화면에서 세션 정보가 남아있으면 로그아웃 이벤트를 다시 발생하도록 코드를 추가함 (Login.vue)
    async checkSessionUser({ state, dispatch }: ActionContext<State, State>) {
      const userState = state.currentUser;
      // 앱을 통해 로그인한 경우에는 이전 로그인 사용자와 새로 로그인한 사용자가 다를 수 있으므로 매번 확인이 필요하다.
      const logingStatus = await AuthApi.checkCurrentState(userState?.UserID ?? null);
      if (!logingStatus.Success) {
        await dispatch("logout");
        return;
      }

      if (userState == undefined || logingStatus.Data != null) {
        // 이전 로그인 사용자가 없거나, 이전 로그인 사용자와 새로 로그인한 사용자가 다른경우 정보 업데이트
        await dispatch("login", logingStatus.Data);
        // NOTE: 위에서 설명한 이유로 서버에서만 만료여부 확인하고, 앱 내에서 별도로 만료시간 체크하지 않음
      } else {
        return;
      }
    },
  },
  modules: {},
  //페이지 새로고침에 정보가 사라지지 않도록 vuex-persistedstate 플러그인을 사용한다.
  plugins: [createPersistedState()],
});
