





















































































































import { Component, Vue, Prop } from "vue-property-decorator";
// Apis
import ApprovalApi from "@/api/approvalApi";

enum POPUP_STATE {
  SUCCESS = 1,
  FAILURE = 2,
  CLOSE = 3,
}

@Component({
  components: {
    HdLoadingMask: () => import("@/components/HdLoadingMask.vue"),
  },
})
export default class LineApprovalPopup extends Vue {
  @Prop() apvId!: number;
  @Prop() rejectCode!: string;
  @Prop() actionName!: string;
  @Prop() rejectDic!: { hold: boolean; reject: boolean; rejectOne: boolean };
  @Prop({ default: "reject" }) rejectDefault!: "hold" | "reject" | "rejectOne";

  // 반려, 불합의 등 결재 반려 타입 여부
  private isReject: boolean = false;
  private rejectType: "hold" | "reject" | "rejectOne" = this.rejectDefault;

  private comment: string = "";
  private userId: string = "";

  private validating: boolean = false;
  private submittingDecision: boolean = false;

  mounted() {
    var sessionUser = this.$store.getters.getCurrentUser;
    if (sessionUser) {
      this.userId = sessionUser.UserID;
    }

    // 결재 액션 분류
    // 반려 등..
    if (this.rejectCode == "1" || this.rejectCode == "2" || this.rejectCode == "9") {
      this.isReject = true;
    }
    // 승인 등
    else {
      this.isReject = false;
    }
  }

  processLineUserDeceision() {
    this.validating = true;
    // 반려일 경우에는 의견 입력이 필수
    if (this.isReject && !this.comment.length) {
      (this.$el.querySelector("textarea") as HTMLTextAreaElement).focus();
      return;
    }

    var selectedRejectCode = this.rejectCode;
    // 반려일 경우에는 선택한 타입에 맞게 RejectCode를 세팅해준다.
    if (this.isReject) {
      switch (this.rejectType) {
        case "reject": // 기안반려
          selectedRejectCode = "2";
          break;
        case "hold": // 보류
          selectedRejectCode = "3";
          break;
        default:
          // 한단계 반려
          selectedRejectCode = "1";
          break;
      }
    }

    this.submittingDecision = true;
    ApprovalApi.setApprovalAsync(this.apvId, this.userId, selectedRejectCode, this.comment)
      .then((result) => {
        this.submittingDecision = false;
        if (result.success) {
          this.$emit("closePopup", POPUP_STATE.SUCCESS);
        } else {
          this.submittingDecision = false;
          this.$emit("closePopup", POPUP_STATE.FAILURE);
        }
      })
      .catch((ex) => {
        this.$log.debug(ex);
        this.submittingDecision = false;
        this.$emit("closePopup", POPUP_STATE.FAILURE);
      });
  }

  cancel() {
    this.$emit("closePopup", POPUP_STATE.CLOSE);
  }

  // 내용 입력창에 포커스
  setFocus() {
    (this.$el.querySelector("textarea") as HTMLTextAreaElement).focus();
  }
}
