import { render, staticRenderFns } from "./EmptyRoom.vue?vue&type=template&id=8b14bd52&"
import script from "./EmptyRoom.vue?vue&type=script&lang=ts&"
export * from "./EmptyRoom.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QSpinner from 'quasar/src/components/spinner/QSpinner.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import QBar from 'quasar/src/components/bar/QBar.js';
import QPageSticky from 'quasar/src/components/page-sticky/QPageSticky.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSpinner,QSeparator,QBar,QPageSticky,QBtn});qInstall(component, 'directives', {Ripple});
