




import { Component, Vue, Watch } from "vue-property-decorator";

@Component({
  components: {
    Login: () => import("./views/Login.vue"),
    Main: () => import("./views/Main.vue"),
    Download: () => import("./views/Download.vue"),
  },
})
export default class App extends Vue {
  private mainComponent: string = "";
  created() {
    // NOTE: custom Icons
    const myIcons: { [key: string]: string } = {
      vertical: `img:${require("@/styles/icons/icon_10-vertical_short_fit.svg")}`,
      moveTo: `img:${require("@/styles/icons/icon_24-arrow_right_small_light_fit_r.svg")}`,
      arrowBottomBig: `img:${require("@/styles/icons/icon_arrow_bottom_big.svg")}`,
      arrowBottomSmall: `img:${require("@/styles/icons/icon_24-arrow_bottom_small_fit.svg")}`,
    };

    this.$q.iconMapFn = (iconName) => {
      const icon = myIcons[iconName];
      if (icon !== void 0) {
        return { icon: icon };
      }
    };
  }

  moveToMainPage() {
    if (this.$route.name == "App" || this.$route.name == "Login") {
      this.$router.replace("/front").catch(() => {
        return;
      });
    }
  }

  @Watch("$route")
  manageMainComponent() {
    this.$nextTick(() => {
      if (this.$route.name == "Login") {
        this.mainComponent = "Login";
      } else if (this.$route.name == "Download") {
        this.mainComponent = "Download";
      } else {
        this.mainComponent = "Main";
        if (this.$route.name == "App") {
          this.$router.replace("/front");
        }
      }
    });
  }
}
