

















import { Component, Vue, Prop } from "vue-property-decorator";
// model
import ReservationInfo from "../models/ReservationInfo";
// component
import ReservationDetail from "./ReservationDetail.vue";

@Component({
  components: {
    ReservationDetail,
  },
})
export default class ReservationCompleted extends Vue {
  @Prop() resInfo!: ReservationInfo;
  private type = this.resInfo.reservationId
    ? this.$t("ReservationModificationCompleted")
    : this.$t("ReservationApplicationCompleted");

  mounted() {
    this.$nextTick(() => {
      (this.$refs.top as HTMLElement).scrollIntoView({ behavior: "smooth", block: "end" });
    });
  }
}
