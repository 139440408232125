import Vue from "vue";

import "axios";
import VueCookies from "vue-cookies-ts";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./quasar";
import i18n from "@/libs/lang";
import "@/libs/logger";
// setimmediate: winston 사용시 필요
//import "setimmediate";

Vue.config.productionTip = false;

// 다운로드 페이지에서 아이디 저장 기능에 쓰임
// 옵션은 기본값 사용. expireTimes = 1d , path=/
Vue.use(VueCookies);

// 푸시알림 수신 이벤트
Vue.prototype.$refresh = function (message: string, url: string) {
  // NOTE: 모바일에서 푸시 수신하면 별도 클릭하지 않아도 즉시 js를 실행해줄거라는 가정으로 아래처럼 toast를 추가했으나
  // 실제로는 앱 실행중에도 푸시 알림을 클릭해야 js가 실핻되고있다. 그럼 toast를 한 번 더 클릭하게 하는 것은 불필요하다.
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  // const notify = require("quasar").Notify;
  // notify.create({
  //   group: false,
  //   // type: "info",
  //   position: "top",
  //   color: "white",
  //   textColor: "black",
  //   message: `<div onclick='location.href="${url}"'> ${message} <div>`,
  //   html: true,
  //   timeout: 2500,
  //   closeBtn: "닫기",
  // });

  // NOTE: 그래서 그냥 알림 클릭할 때 URL 이동시켜주게 처리
  location.href = url;
};

// 기본 프로필 이미지
Vue.prototype.$defaultImage = function (e: Event) {
  (e.target as HTMLImageElement).src = require("@/styles/icons/icon_profile.svg");
};

// 모바일 앱으로 이벤트 전달을 위해 사용
declare global {
  interface Window {
    h1mobile: any; // android
    webkit: any; // ios
    vm: Vue; // 외부에서 js 호출
  }
}

window.vm = new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App),
});
window.vm.$mount("#app");
