











































import { Component, Vue, Watch } from "vue-property-decorator";
import { mapActions } from "vuex";
//import * as ApprovalApi from "@/api/approvalApi";
import MenuHandler from "@/common/menuHandler";

// const IDX_WAITING = 0;
// const IDX_PROGRESS = 1;
// const IDX_COMPLETE = 2;
// onst IDX_REJECT = 3;
// const IDX_RECEIVE = 4;

const RECIPTE_PREFIX = "Receipt";

@Component({
  methods: {
    ...mapActions(["loadConfigs"]),
  },
})
export default class Approval extends Vue {
  private loadConfigs!: () => Promise<any>;
  private waitingCount: number = 0;
  private inProgressCount: number = 0;
  private tabs: { key: string; label: string; count: number }[] = [];
  private currrentTab: string = "";

  async mounted() {
    var config = await this.loadConfigs();
    var apvRootMenu = await MenuHandler.findMenuByMenuId(config.approval.menuId);

    if (apvRootMenu != undefined) {
      apvRootMenu.Children.forEach((child) => {
        // 경로의 끝부분을 key로 가져온다
        var pathArr = child.Url.split("/");
        var key = pathArr[pathArr.length - 1];
        this.tabs.push({
          key: key,
          label: child.ShortName,
          count: 0,
        });
      });

      var path = this.$route.path.split("/");
      var listType = path[path.length - 1];
      if (listType && listType != this.currrentTab) {
        // NOTE: 수신문서함의 하위 문서함은 실제 메뉴로 존재하지 않기 때문에, 현재 경로로 부모 메뉴를 찾을 수 없다.
        // 따라서, 현재 메뉴를 찾을 수 없는 경우 수신 문서함으로 간주한다.
        if (listType.startsWith(RECIPTE_PREFIX)) {
          this.currrentTab = "ReceiptCircularCc";
        } else if (this.tabs.findIndex((o) => o.key == listType) == -1) {
          this.currrentTab = this.tabs[0].key;
        } else {
          this.currrentTab = listType;
        }
      }
      // NOTE: 상단 카운트 표시 숨김
      // this.setCount();
    }
  }

  // setCount() {
  //   var user = this.$store.getters.getCurrentUser;
  //   if (user != undefined) {
  //     ApprovalApi.getDocumentCountAsync(user.UserID)
  //       .then((result) => {
  //         result.forEach((value) => {
  //           switch (value.Category) {
  //             case "Waiting":
  //               this.tabs[IDX_WAITING].count = value.Count;
  //               break;
  //             case "InProgress":
  //               this.tabs[IDX_PROGRESS].count = value.Count;
  //               break;
  //           }
  //         });
  //       })
  //       .catch(() => {
  //         //
  //       });
  //   }
  // }

  @Watch("currrentTab")
  handleSwipe(newVal: string) {
    this.$nextTick(() => {
      if (this.$route.params.listType != newVal) {
        if (
          this.$route.params.listType.startsWith(RECIPTE_PREFIX) &&
          newVal.startsWith(RECIPTE_PREFIX)
        ) {
          return;
        }
        this.$router.replace({ name: "ApvList", params: { listType: newVal } });
      }
    });
  }
}
