


































import { Component, Vue } from "vue-property-decorator";
import MobileMenu from "@/models/menu/MobileMenu";
import HdSelectBox from "@/components/HdSelectBox.vue";
import MenuHandler from "@/common/menuHandler";

@Component({
  components: {
    HdSelectBox,
  },
})
export default class MenuNavigator extends Vue {
  private menuId: string = "";
  private rootMenu: MobileMenu = {} as MobileMenu; // menuLevel2에 해당.
  private menus: MobileMenu[] = [];
  private selected = -1;

  mounted() {
    MenuHandler.getCurrentMenu().then((currentMenu) => {
      this.menuId = currentMenu?.MenuId ?? "";
    });

    MenuHandler.getParentMenuByLevel(2).then((parent) => {
      if (parent) {
        this.rootMenu = parent;
        this.menus = this.rootMenu.Children;

        // NOTE: nextTick에 두어야 해당 탭에 포커스됨
        this.$nextTick(() => {
          this.selected = this.rootMenu.Children.findIndex((o) => o.MenuId == this.menuId);
        });
      }
    });
  }

  toItemList() {
    this.$nextTick(() => {
      this.$router
        .replace({
          path: this.menus[this.selected].Url,
        })
        .catch((e) => {
          this.$log.debug(e);
          return;
        });
    });
  }

  selectMenu() {
    this.toItemList();
  }

  goBack() {
    if (history.length && history.state != null) {
      this.$router.go(-1);
    } else {
      this.$router.replace({ name: "Front" });
    }
  }
}
