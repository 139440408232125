




































































































import { Component, Vue, Prop } from "vue-property-decorator";
import DATEHANDLER from "@/common/dateHandler";
import ApprovalLineUser from "@/models/approval/ApprovalLineUser";
import * as ApvEnum from "@/models/approval/enums/ApvEnums";
import { ApvTypeCode, ProcessStepCurrentStausCode } from "@/models/approval/enums/ApvEnums";

@Component({
  components: {
    ApvModificationOpinionItem: () => import("./ApvModificationOpinionItem.vue"),
  },
})
export default class ApvLineUserCard extends Vue {
  @Prop() lineUser!: ApprovalLineUser;
  @Prop() lineStatus!: ApvEnum.LineStatusCode;
  @Prop() lineType!: "drafter" | "reviewer" | "approver";
  @Prop() hasApprovalAuth!: boolean;

  private isDelegated: boolean = false;

  mounted() {
    if (this.lineUser.isSelectedDelegateUser && this.lineUser.delegateUserID.length) {
      this.isDelegated = true;
    }
  }

  isPassedStep() {
    return (
      this.lineUser.isApproved ||
      this.lineUser.currentStepStateCode == ApvEnum.CurrentStepStateCode.PassedStep ||
      this.lineUser.currentStatus == "AS.009"
    );
  }

  getDateAsYYYYMMDDHHmm(date: string): string {
    return DATEHANDLER.dateAsYYYYMMDDHHmm(date, this.$i18n.locale);
  }

  // 결재 사용자가 각각 개인|부서|그룹인 경우에 대한 표시 방법
  getLineUserDisplayName(): string {
    switch (this.lineUser.lineStepType) {
      case ApvEnum.LineStepType.User:
        return `${this.lineUser.lineUserName.length ? this.lineUser.lineUserName : "&nbsp"}`;
      case ApvEnum.LineStepType.Dept:
        return `${this.lineUser.lineDeptName ? this.lineUser.lineDeptName : "&nbsp"}`;
      case ApvEnum.LineStepType.ApvRoleGroup:
        return `${this.lineUser.lineRoleGroupName ? this.lineUser.lineRoleGroupName : "&nbsp"}`;
    }
  }

  currentStepStateClass(): string {
    // 기안중 일 때
    if (this.lineType == "drafter" && this.lineStatus == ApvEnum.LineStatusCode.LS_001) {
      return "hd-apv-line-card__current";
    }

    var state = this.lineUser.currentStepStateCode;
    switch (state) {
      case ApvEnum.CurrentStepStateCode.PassedStep:
        return "hd-apv-line-card__passed";
      case ApvEnum.CurrentStepStateCode.CurrentStep:
        return "hd-apv-line-card__current";
      case ApvEnum.CurrentStepStateCode.ModifiableStep:
        return "hd-apv-line-card__modifiable";
      default:
        return "";
    }
  }

  getApvTypeClass(): string {
    return `hd-apv-line-card__${this.lineUser.lineApvTypeCode.toString().replace(".", "_")}`;
  }

  isEmptyLine(): boolean {
    switch (this.lineUser.lineStepType) {
      case ApvEnum.LineStepType.User:
        return this.lineUser.lineUserID == undefined || this.lineUser.lineUserID == "";
      case ApvEnum.LineStepType.ApvRoleGroup:
        return this.lineUser.lineRoleGroupID == undefined || this.lineUser.lineRoleGroupID == "";
      case ApvEnum.LineStepType.Dept:
        return this.lineUser.lineDeptID == undefined || this.lineUser.lineDeptID == "";
    }
  }

  statusString() {
    var user = this.lineUser;

    // NOTE: 전자결재 프로젝트의 Hyundai_apv\30_Component\Mik.Apv.Ext.HyundaiGroup\LineExtension\ApvLineRenderBase.cs에서 GetApvStatusText()를 참고해 작성함.
    var apvStatusText = "";
    switch (user.lineApvTypeCode) {
      case ApvTypeCode.ATY_001:
        apvStatusText = user.isApproved ? this.$t("LineApvStatusLabel_Approved").toString() : ""; // this.$t("LineApvStatusLabel_BeforeApproved").toString(); // 결재 : 미결
        break;
      case ApvTypeCode.ATY_003:
        apvStatusText = user.isApproved
          ? this.$t("LineApvStatusLabel_ArbitraryDecision").toString()
          : ""; //this.$t("LineApvStatusLabel_BeforeApproved").toString(); // 전결 : 미결
        break;
      case ApvTypeCode.ATY_004:
      case ApvTypeCode.ATY_005:
        apvStatusText = user.isApproved
          ? user.currentStatus == ProcessStepCurrentStausCode.AS_013
            ? this.$t("LineApvStatusLabel_OtherCommentCooperated").toString()
            : this.$t("LineApvStatusLabel_Cooperated").toString() // 협조(다른의견) : 협조
          : ""; // this.$t("LineApvStatusLabel_BeforeCooperated").toString(); // 미협조
        break;
      case ApvTypeCode.ATY_006:
        apvStatusText = user.isApproved ? this.$t("LineApvStatusLabel_Review").toString() : ""; //this.$t("LineApvStatusLabel_BeforeReview").toString(); // 검토 : 미검토
        break;
      case ApvTypeCode.ATY_007:
        apvStatusText = this.$t("LineApvStatusLabel_AfterRead").toString(); // 후열
        break;
      case ApvTypeCode.ATY_008:
        apvStatusText = user.isApproved ? this.$t("LineApvStatusLabel_Draft").toString() : ""; // 기안
        break;
      case ApvTypeCode.ATY_009:
        apvStatusText = user.isApproved ? this.$t("LineApvStatusLabel_Approved").toString() : ""; // 결재 : 미접수
        break;
      case ApvTypeCode.ATY_012:
        apvStatusText = user.isApproved
          ? user.currentStatus == ProcessStepCurrentStausCode.AS_013
            ? this.$t("LineApvStatusLabel_DisAgreement").toString()
            : this.$t("LineApvStatusLabel_Agreement").toString() // 불합의 : 합의
          : ""; // 미합의
        break;
    }

    if (
      user.currentStatus == ProcessStepCurrentStausCode.AS_003 ||
      user.currentStatus == ProcessStepCurrentStausCode.AS_004 ||
      user.currentStatus == ProcessStepCurrentStausCode.AS_011
    ) {
      apvStatusText = this.$t("LineApvStatusLabel_Reject").toString(); // 반려
    } else if (user.currentStatus == ProcessStepCurrentStausCode.AS_010) {
      apvStatusText = this.$t("LineApvStatusLabel_Cancel").toString(); // 결재취소
    }

    // 기안중
    if (this.lineType == "drafter" && this.lineStatus == ApvEnum.LineStatusCode.LS_001) {
      apvStatusText = this.$t("LineStatus_LS_001").toString();
    }

    return apvStatusText.length ? `(${apvStatusText})` : "";
  }
}
