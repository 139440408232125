





































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ReservationApi from "@/api/reservationApi";
import DATEHANDLER from "@/common/dateHandler";
import KVType from "@/models/common/KVType";
import UserInfo from "@/models/board/UserInfo";
import MyReservationListRequest from "@/models/reservation/request/MyReservationListRequest";
import MeetingRoomReservationType from "@/models/reservation/MeetingRoomReservationType";
//components
import HdComboBox from "@/components/HdComboBox.vue";
import HdWeekCalendar from "@/components/HdWeekCalendar.vue";

@Component({
  components: {
    HdComboBox,
    HdWeekCalendar,
    ReservationResultCard: () => import("./components/ReservationResultCard.vue"),
    HdAlternativeMessage: () => import("@/components/HdAlternativeMessage.vue"),
  },
})
export default class MyReservations extends Vue {
  @Prop() refresh!: number;
  private user = this.$store.getters.getCurrentUser as UserInfo;
  private bldgOptions: KVType[] = [];
  private floorOptions: KVType[] = [];
  private searchTypes: KVType[] = [
    {
      // 나의 예약
      key: "User",
      value: this.$t("ReservationTypeMy").toString(),
    },
    {
      // 참석자인 예약
      key: "Attendant",
      value: this.$t("ReservationTypeAsAnAttandent").toString(),
    },
    {
      // 참조자인 예약
      key: "Cc",
      value: this.$t("ReservationTypeAsAReferrer").toString(),
    },
  ];
  private processTypes: KVType[] = [
    {
      // 전체
      key: "All",
      value: this.$t("ReservationProcessTypeAll").toString(),
    },
    {
      // 접수 완료
      key: "B",
      value: this.$t("ReservationProcessTypeB").toString(),
    },
    {
      // 접수중
      key: "A",
      value: this.$t("ReservationProcessTypeA").toString(),
    },
    {
      // 접수불가
      key: "C",
      value: this.$t("ReservationProcessTypeC").toString(),
    },
  ];

  private date: Date | null = null;
  private building: KVType = {} as KVType;
  private floor: KVType = {} as KVType;
  private searchType: "User" | "Attendant" | "Cc" = "User";
  private processType: "All" | "A" | "B" | "C" = "All";
  private pageNum: number = 1;
  // [날짜][예약]
  private reservationList: Map<string, MeetingRoomReservationType[]> = new Map<
    string,
    MeetingRoomReservationType[]
  >();
  private reservationCount: number = 0;

  mounted() {
    this.bldgOptions = [];
    this.bldgOptions.push({
      key: "-999",
      value: this.$t("All").toString(),
    });

    ReservationApi.getBuildings().then((result) => {
      if (result.Success) {
        result.Data.forEach((building) => {
          this.bldgOptions.push({
            key: building.BuildingID.toString(),
            value: building.BuildingName,
          });
        });

        this.building = this.bldgOptions[0];
        this.loadMyReservationList();
      } else {
        this.$log.debug(result.Message);
      }
    });
  }

  handleBuildingChanged(option: KVType) {
    this.building = option;
    this.loadMyReservationList();
  }

  loadMyReservationList() {
    // 날짜가 지정되지 않은 경우는 오늘 이후 전체 검색
    var fromDate = new Date();
    var toDate = new Date();
    toDate.setFullYear(9999);

    // 날짜가 지정되면 해당 날짜만 검색
    if (this.date != null) {
      fromDate = new Date(this.date);
      toDate = new Date(this.date);
      toDate.setDate(fromDate.getDate() + 1);
    }

    fromDate.setHours(0, 0, 0, 0);
    toDate.setHours(0, 0, 0, 0);
    var request: MyReservationListRequest = {
      BuildingID: Number(this.building.key),
      FromDate: fromDate,
      ToDate: toDate,
      SearchType: this.searchType,
      ProcessType: this.processType,
      PageNum: this.pageNum,
      PageSize: 15,
    };

    ReservationApi.getMyReservationList(request).then((result) => {
      if (result.Success) {
        this.reservationCount = result.Data.length;
        this.reservationList = new Map<string, MeetingRoomReservationType[]>();
        result.Data.forEach((item) => {
          var date = DATEHANDLER.strToYYYYMMDD(item.ReservationDatetime, this.$i18n.locale);
          var list = this.reservationList.get(date);
          if (list != undefined) {
            list.push(item);
          } else {
            this.reservationList.set(date, [item]);
          }
        });

        this.$forceUpdate();
      } else {
        this.reservationCount = 0;
        this.$log.debug(result.Message);
      }
    });
  }

  // 날짜 변경 적용
  handleDateChanged(date: Date) {
    this.date = date;
    this.loadMyReservationList();
  }

  // 예약 타입 변경 적용
  handleSearchTypeChanged(type: KVType) {
    this.searchType = type.key as "User" | "Attendant" | "Cc";
    this.loadMyReservationList();
  }

  handleProcessTypeChanged(type: KVType) {
    this.processType = type.key as "All" | "A" | "B" | "C";
    this.loadMyReservationList();
  }

  // 날짜 string formatting
  getDateStringFormat(input: string) {
    var locale = this.$i18n.locale;
    // yyyy-MM-dd => 요일 + 오늘
    var date = new Date(input);

    var datestr = DATEHANDLER.dateToStrYYYYMMDD(date, locale);

    const day_ko = ["일", "월", "화", "수", "목", "금", "토"];
    const day_en = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    var day = [];

    if (locale == "en") {
      day = day_en;
    } else {
      day = day_ko;
    }
    var weekday = day[date.getDay()];

    var today = "";
    if (date.toDateString() == new Date().toDateString()) {
      today = locale == "en" ? " (today)" : " (오늘)";
    }

    return locale == "en" ? ` ${weekday} ${datestr}${today}` : `${datestr} ${weekday}${today}`;
  }

  getDateAsYYYYMMDD() {
    if (this.date) {
      return DATEHANDLER.dateToStrYYYYMMDD(this.date, this.$i18n.locale);
    }
    return "";
  }

  @Watch("refresh")
  updateList() {
    this.loadMyReservationList();
  }
}
