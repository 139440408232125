/**
 *    @param Normal 일반적인 결재칸
 *    @param ForceTeamLeader 팀장자동지정 (코드에서)
 *    @param Disable 사용하지 않는 결재칸 (사선)
 *    @param Hide 사용하지 않는 결재칸 (사선)
 */
export enum LineUseType {
  /** 일반적인 결재칸 */
  Normal = 1,
  /** 팀장자동지정 (코드에서) */
  ForceTeamLeader = 2,
  /** 사용하지 않는 결재칸 (사선) */
  Disable = 3,
  /** 사용하지 않는 결재칸 (사선) */
  Hide = 4,
}

/** 알람 정보
 *    @param None
 *    @param Important bitmask 1
 *    @param SMS bitmask 2
 *    @param Mail bitmask 4
 *    @param Messenger bitmask 8
 */
export enum ApvAlarmFlag {
  None = 0,
  Important = 1 << 0,
  SMS = 1 << 1,
  Mail = 1 << 2,
  Messenger = 1 << 3,
}

/** 연동 결재용 결재 진행 상태 값
 *    @param Empty 미설정
 *    @param Draft 기안
 *    @param Progress 검토,협조,1단계 반려(기안자까지 넘어가지 않는 반려인 경우만 해당. 한단계 반려라도 기안자에게 넘어가는 경우는 Reject 코드값으로 처리함)
 *    @param Reject 반려 (수신문서 기안부서 반려 이거나, 문서가 기안자에게 반려되는 경우)
 *    @param Discard 폐기
 *    @param Complete 승인
 *    @param Delete 삭제
 *    @param RejectedDraft 반려 후 재기안
 *    @param Cancel 취소
 *    @param Recall 철회(회수)
 */
export enum ApprovalIFStatus {
  /** 미설정 */
  Empty = -1,
  /** 기안 */
  Draft = 0,
  /** 검토,협조,1단계 반려(기안자까지 넘어가지 않는 반려인 경우만 해당. 한단계 반려라도 기안자에게 넘어가는 경우는 Reject 코드값으로 처리함) */
  Progress,
  /** 반려 (수신문서 기안부서 반려 이거나, 문서가 기안자에게 반려되는 경우) */
  Reject,
  /** 폐기 */
  Discard,
  /** 승인 */
  Complete,
  /** 삭제 */
  Delete,
  /** 반려 후 재기안 */
  RejectedDraft,
  /** 취소 */
  Cancel,
  /** 철회(회수) */
  Recall,
}

/** 결재 유형 */
export enum ApvTypeCode {
  /** 해당없음 */
  EMPTY,
  /** 결재 */
  ATY_001 = "ATY.001",
  /** X 대결 : 대결은 다른 유형과 중복되는 값이고, ApvTypeCode로는 존재하지 않음   */
  ATY_002 = "ATY.002",
  /** 전결 */
  ATY_003 = "ATY.003",
  /** 순차협조 */
  ATY_004 = "ATY.004",
  /** 병렬협조 */
  ATY_005 = "ATY.005",
  /** 검토 */
  ATY_006 = "ATY.006",
  /** 후열 */
  ATY_007 = "ATY.007",
  /** 기안 */
  ATY_008 = "ATY.008",
  /** 담당 */
  ATY_009 = "ATY.009",
  /** 부서합의 */
  ATY_010 = "ATY.010",
  /** 담당그룹결재 */
  ATY_011 = "ATY.011",
  /** 합의 */
  ATY_012 = "ATY.012",
  /** 협조 */
  ATY_013 = "ATY.013",
}

/// 결재선 이름 유형
export enum ApvTitleCode {
  /** 해당없음   */
  EMPTY = "",
  /** 기안 */
  AT_001 = "AT.001",
  /** 검토*/
  AT_002 = "AT.002",
  /** 승인*/
  AT_003 = "AT.003",
  /** 담당*/
  AT_004 = "AT.004",
  /** 병렬협조*/
  AT_005 = "AT.005",
  /** 순차협조*/
  AT_006 = "AT.006",
  /** 합의*/
  AT_007 = "AT.007",
}

/** 결재 진행 상태 유형.
 *    @param EMPTY 해당없음
 *    @param LS_001 기안중
 *    @param LS_002 검토중
 *    @param LS_003 승인중
 *    @param LS_004 반려
 *    @param LS_005 회수
 *    @param LS_006 완료
 *    @param LS_007 보류
 *    @param LS_008 협조중
 *    @param LS_009 담당처리중
 *    @param LS_010 폐기
 *    @param LS_011 결재취소
 *    @param LS_012 수신문서기안부서반려
 *    @param LS_013 자가 승인
 *    @param LS_014 시스템 결재취소
 *    @param LS_015 합의중
 */
export enum LineStatusCode {
  /** 해당없음 */
  EMPTY,
  /** 기안중 */
  LS_001,
  /** 검토중 */
  LS_002,
  /** 승인중 */
  LS_003,
  /** 반려 */
  LS_004,
  /** 회수 */
  LS_005,
  /** 완료 */
  LS_006,
  /** 보류 */
  LS_007,
  /** 협조중 */
  LS_008,
  /** 담당처리중 */
  LS_009,
  /** 폐기 */
  LS_010,
  /** 결재취소 */
  LS_011,
  /** 수신문서기안부서반려 */
  LS_012,
  /** 자가 승인 */
  LS_013,
  /** 시스템 결재취소 */
  LS_014,
  /** 합의중 */
  LS_015,
}

/**  결재선 스텝 변경 가능 상태 유형 */
export enum CurrentStepStateCode {
  /**  현재 진행중 스텝 */
  CurrentStep = 0,
  /**  통과된 스텝 */
  PassedStep = 1,
  /**  수정가능 스텝 */
  ModifiableStep = 2,
}

export enum LineStepType {
  /**  사용자 */
  User = 0,
  /**  결재자그룹 */
  ApvRoleGroup = 1,
  /**  부서 */
  Dept = 2,
}

export enum ProcessStepCurrentStausCode {
  /** 해당 사항 없음 */
  EMPTY = "",
  /** 대기 */
  AS_001 = "AS.001",
  /** 결재 */
  AS_002 = "AS.002",
  /** 반려 */
  AS_003 = "AS.003",
  /** 한단계 반려 */
  AS_004 = "AS.004",
  /** 병렬협조진행 */
  AS_005 = "AS.005",
  /** 보류 */
  AS_006 = "AS.006",
  /** 철회 */
  AS_007 = "AS.007",
  /** 전결 진행 */
  AS_008 = "AS.008",
  /** 후열 */
  AS_009 = "AS.009",
  /** 이전 결재자의 결재 취소 */
  AS_010 = "AS.010",
  /** 수신 문서 기안 부서 반려 */
  AS_011 = "AS.011",
  /** 동의 */
  AS_012 = "AS.012",
  /** 다른 의견 */
  AS_013 = "AS.013",
  /** 담당자변경 */
  AS_014 = "AS.014",
  /** 자동결재 */
  AS_015 = "AS.015",
  /** 시스템 결재취소 */
  AS_016 = "AS.016",
}

/** Interface 처리 상태 코드 */
export enum InterfaceStatusType {
  /** 상태없음 */
  Empty = 0,
  /** 연동 중 오류 발생 */
  Invalid_Error = 1,
  /** 연동 중 timeout 발생 */
  Invalid_Timeout = 2,
}

export const RejectCode = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "a", "d"];

/** 결재 문서 타입 */
export enum ApvAdvancedSearchListType {
  /** 결재할 문서함 */
  WaitingApproval = "WaitingApproval",
  /** 진행 문서함 */
  InProgress = "InProgress",
  /** 임시문서함 */
  TempDocument = "TempDocument",
  /** 완결 문서함 */
  ComletedDocument = "ComletedDocument",
  /** 반려 문서함 */
  RejectedDocument = "RejectedDocument",
  /** 회람 문서함 */
  ReceiptCircular = "ReceiptCircular",
  /** 부서 수신함 */
  ReceiptDept = "ReceiptDept",
  /** 사본(참조) 수신함 */
  ReceiptCc = "ReceiptCc",
  /** 개인 수신함 */
  ReceiptTo = "ReceiptTo",
  /** 개인 수신함 (FormID) */
  ReceiptTo_FormID = "ReceiptTo_FormID",
  /** 회람 + 참조(사본) 수신함 */
  ReceiptCircularCc = "ReceiptCircularCc",
  /** 부서 완료 문서함 */
  DeptDocument = "DeptDocument",
  /** HD 결재할 */
  ListDocForApproval = "ListDocForApproval",
  /** HD 결재한 */
  ListDocForApproved = "ListDocForApproved",
}
