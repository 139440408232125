

















































import { Component, Vue, Prop } from "vue-property-decorator";
import HdSelectBox from "@/components/HdSelectBox.vue";
import KVType from "@/models/common/KVType";

@Component({
  components: {
    HdSelectBox,
  },
})
export default class HdSearchInput extends Vue {
  /* 필터 사용할 경우만 필요 (start) */
  @Prop() initSearchType!: KVType;
  @Prop() searchTypeOptions!: KVType[];
  @Prop({ default: "검색어를 입력하세요" }) placeholder!: string;
  private searchType: KVType | null = null;
  /* 필터 사용할 경우만 필요 (end) */

  mounted() {
    if (this.searchTypeOptions != undefined) {
      this.searchType = this.initSearchType ?? this.searchTypeOptions[0];
    }
  }

  private searchKeyword: string = "";

  private searchTypeDialog: boolean = false;

  searchItem() {
    this.$emit("search", this.searchKeyword, this.searchType);
  }

  handleOptionChange(option: KVType) {
    this.searchType = option;
  }
}
