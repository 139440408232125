





































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { mapActions } from "vuex";
import YellowPageApi from "@/api/yellowPageApi";
// model
import DeptTree from "@/models/yellowPage/DeptTree";
import User from "@/models/yellowPage/User";
import SearchMemberRequest from "@/models/yellowPage/request/SearchMemberRequest";
import KVType from "@/models/common/KVType";
// components
import MemberList from "./MemberList.vue";
import scroll from 'quasar/src/utils/scroll.js';;
import HdLoadingMask from "@/components/HdLoadingMask.vue";

@Component({
  components: {
    HdLoadingMask,
    MemberList,
    HdAlternativeMessage: () => import("@/components/HdAlternativeMessage.vue"),
  },
  methods: {
    ...mapActions(["loadConfigs"]),
  },
})
export default class MemberSearch extends Vue {
  private loadConfigs!: () => Promise<any>;
  private companyCode = "";
  private currentDept: DeptTree = {} as DeptTree;
  private langCode = this.$i18n.locale;
  // 멤버
  private searchResult: User[] = [];
  private memberLoading = true;
  private hasMoreMember = true;
  private totalCount = 0;
  // 검색 관련
  private searchKeyword: string = "";
  private pageNumber = 1;
  private pageSize = 30;
  private readonly searchOptions: KVType[] = [
    { key: "ALL", value: this.$t("All").toString() },
    { key: "NAME", value: this.$t("Name").toString() },
    { key: "TEL", value: this.$t("PhoneNumber").toString() },
    { key: "DEPT", value: this.$t("Department").toString() },
  ];
  // { key: "JOB", value: "업무" },
  private searchOption: string = "ALL";
  private searchArea: "first" | "results" | "no-result" = "first";

  private loading = false;

  mounted() {
    this.companyCode = this.$route.params.companyCode;
  }

  goBack() {
    this.$router.push({ name: "DeptView", params: { companyCode: this.companyCode } });
  }

  handleLoadMore() {
    if (this.memberLoading) return;
    if (this.hasMoreMember) {
      this.pageNumber = this.pageNumber + 1;
      this.searchMember(true);
    }
  }

  // 구성원 검색
  async searchMember(loadMore: boolean = false): Promise<void> {
    if (!this.searchKeyword.trim().length) {
      return;
    }

    var photoUrl = (await this.loadConfigs()).resource.photoUrl;

    this.memberLoading = true;

    if (!loadMore) {
      this.pageNumber = 1;
      this.searchResult = [];
      var item = scroll.getScrollTarget(this.$root.$el);
      scroll.setScrollPosition(item, 0);
    }

    var request: SearchMemberRequest = {
      CompanyCode: this.companyCode,
      SearchType: this.searchOption,
      PageNumber: this.pageNumber,
      PageSize: this.pageSize,
      SearchKeyword: this.searchKeyword,
      LangCode: this.langCode,
    };

    YellowPageApi.searchMembersAsync(request).then((result) => {
      result.Items.forEach((user) => {
        user.PhotoURL = photoUrl.replace("{0}", user.UserID);
      });

      if (loadMore) {
        this.searchResult = this.searchResult.concat(result.Items);
      } else {
        this.searchResult = result.Items;
      }
      this.hasMoreMember = result.HasMore;
      this.totalCount = result.TotalCount;
      if (this.searchResult.length > 0) {
        this.searchArea = "results";
      } else {
        this.searchArea = "no-result";
      }
      this.memberLoading = false;
    });
  }

  @Watch("searchOption")
  handleSearchOptionChanged(): void {
    this.searchMember();
  }
}
