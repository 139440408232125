export default {
  /**
   * 파일 크기 숫자를 표기형식으로 변환
   * @param byte 파일크기
   */
  byteToStr(byte: number): string {
    const unit = ["B", "KB", "MB", "GB", "TB"];
    let size = byte;
    let index = 0;
    while (size > 1024 && index < unit.length - 1) {
      index++;
      size /= 1024;
    }

    return `${size.toFixed(1)} ${unit[index]}`;
  },

  /**
   * HTML 문자를 escape 처리
   * @param text 원문
   */
  escapeHtml(text: string): string {
    const map: Map<string, string> = new Map([
      ['"', "&quot;"],
      ["&", "&amp;"],
      ["<", "&lt;"],
      [">", "&gt;"],
    ]);

    return String(text).replace(/["&<>]/g, (s: string) => map.get(s)!);
  },

  /**
   * 파일명으로 파일 아이콘 클래스를 리턴한다.
   * @param fileName 파일명
   */
  getIconClassFromFileName(fileName: string): string {
    const extension = fileName.split(".").pop();

    switch (extension?.toLowerCase()) {
      case "pdf":
        return "hd-icon-pdf";
      case "doc":
      case "docx":
      case "dot":
      case "dotx":
      case "docm":
      case "docb":
      case "dotm":
        return "hd-icon-docx";
      case "xls":
      case "xlt":
      case "xlm":
      case "xlsx":
      case "xlsm":
      case "xltx":
      case "xltm":
      case "xlsb":
      case "xla":
      case "xlam":
      case "xll":
      case "xlw":
        return "hd-icon-xlsx";
      case "ppt":
      case "pot":
      case "pps":
      case "pptx":
      case "pptm":
      case "potx":
      case "potm":
      case "ppam":
      case "ppsx":
      case "ppsm":
      case "sldx":
      case "sldm":
        return "hd-icon-pptx";
      case "bmp":
      case "jpg":
      case "jpeg":
      case "gif":
      case "png":
      case "svg":
      case "tiff":
      case "psd":
      case "tga":
      case "ai":
      case "jpe":
      case "jfif":
      case "jp2":
      case "j2c":
      case "pcx":
      case "taga":
      case "tif":
        return "hd-icon-img";
      // case "zip":
      // case "apk":
      // case "rar":
      // case "7z":
      // case "tar":
      // case "alz":
      // case "ace":
      // case "arc":
      // case "arj":
      // case "b64":
      // case "bh":
      // case "bhx":
      // case "bin":
      // case "bz2":
      // case "cab":
      // case "ear":
      // case "enc":
      // case "gz":
      // case "ha":
      // case "hqx":
      // case "ice":
      // case "jar":
      // case "lha":
      // case "img":
      // case "lzh":
      // case "mim":
      // case "pak":
      // case "sit":
      // case "tgz":
      // case "uue":
      // case "war":
      // case "xxe":
      // case "z":
      // case "zoo":
      //   return "hd-icon-zip";
      case "mp4":
      case "m4v":
      case "avi":
      case "wmv":
      case "mwa":
      case "asf":
      case "mpg":
      case "mpeg":
      case "ts":
      case "mkv":
      case "mov":
      case "3gp":
      case "3g2":
      case "webm":
      case "flv":
      case "ogg":
      case "ps":
      case "mts":
      case "tp":
      case "m2ts":
      case "tod":
      case "skm":
      case "k3g":
      case "lmp4":
      case "rm":
        return "hd-icon-video";
      default:
        return "hd-icon-file";
    }
  },

  /**
   * URL 앞 부분은 현재 페이지의 도메인으로 바꿔서 리턴.
   * NOTE: 포탈에서 데이터를 가져오다보면 URL등에 포탈 도메인이 박혀있는 경우가 있는데,
   * 모바일과 도메인이 다르다보니 cross origin으로 block되어 제대로 표시되지 않는 경우가 있다.
   * 이를 방지하기 위해 URL을 현재 페이지의 도메인으로 바꿔줌
   * @param origin source url
   */
  convertToCurrentDomainUrl(origin: string) {
    return origin.replace(/^(([^:/?#]+):)?(\/\/([^/?#]*))/, window.location.origin);
  },
};
