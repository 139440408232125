































































import { Component, Vue } from "vue-property-decorator";
import { mapActions } from "vuex";
import BoardApi from "@/api/boardApi";
import MenuHandler from "@/common/menuHandler";
import DATEHANDLER from "@/common/dateHandler";
// component
import HdLoadingMask from "@/components/HdLoadingMask.vue";
import CrawledData from "@/models/board/CrawledData";
import MobileMenu from "@/models/menu/MobileMenu";

@Component({
  components: {
    HdLoadingMask,
    HdAlternativeMessage: () => import("@/components/HdAlternativeMessage.vue"),
  },
  methods: {
    ...mapActions(["loadConfigs"]),
  },
})
export default class ItemList extends Vue {
  private loadConfigs!: () => Promise<any>;
  private listItems: CrawledData[] = [];
  private title: string = "What's New";
  private loading = true;

  async mounted() {
    var frontConfig = (await this.loadConfigs()).front;
    var boardType = frontConfig.board.type;
    this.title = frontConfig.board.title;

    /** 크롤링 데이터를 사용하는 경우 */
    if (boardType == "crawling") {
      var crawlingId = frontConfig.board.source;
      this.loading = true;
      return await BoardApi.getCrawlingData(crawlingId)
        .then(async (result) => {
          if (result.Success) {
            var user = this.$store.getters.getCurrentUser;
            this.listItems = [];

            for (let i = 0; i < result.Data.length; i++) {
              var item = result.Data[i];

              var mobileMenu = await MenuHandler.findMenuByUrl(`Board/${item.BoardUrl}`);
              if (mobileMenu != undefined) {
                // NOTE: 무벡스 전용. 조회된 게시글에 대한 사용자의 게시판 권한을 확인하게 함
                if (user.ActivatedTenantId.toUpperCase() == "HMX" && item.GubunValue.length) {
                  var menus = await MenuHandler.findAllMenuByUrl(`Board/${item.BoardUrl}`);
                  var filter = this.isAvailableItem(menus, item);
                  if (filter == false) {
                    continue;
                  } else if (typeof filter === "string") {
                    item.FieldInfo = filter;
                  }
                }

                this.listItems.push(item);
              }
            }
          }
          this.loading = false;
        })
        .catch((e) => {
          this.$log.error(e);
          this.loading = false;
        });
    } else {
      this.loading = false;
    }
  }

  // NOTE: 무벡스 전용. 조회된 게시글에 대한 사용자의 권한을 확인하게 함
  isAvailableItem(menus: MobileMenu[], item: CrawledData): boolean | string {
    var params: any = {};
    // 동일 URL 메뉴가 여러개인 경우 최대 권한으로 체크. 현실적으로는 fieldInfo 값으로 확인
    for (var i = 0; i < menus.length; i++) {
      var menu = menus[i];
      // 필터가 걸리지 않은 메뉴가 하나라도 있는 사용자는 더이상 검사하지 않음
      if (menu.Url.split("?").length == 1) {
        return true;
      } else {
        menu.Url.replace(/[?&]+([^=&]+)=([^&]*)/gi, (str: string, key: string, value: string) => {
          if (value == undefined || value == "undefined") return "";
          if (params[key] == undefined) {
            params[key] = [];
          }
          params[key].push(value);
          return "";
        });
      }
    }

    var values = item.GubunValue?.split("\n");
    if (values != undefined && params.fieldInfo != undefined) {
      for (var j = 0; j < params.fieldInfo.length; j++) {
        var target = params.fieldInfo[j].split(/\||%7C/gi);
        if (target.length > 1 && values.indexOf(target[1]) > -1) {
          return params.fieldInfo[j];
        }
      }
      // 구분값도 있고, fieldFilter도 있는데 일치하는 정보가 없으면 조회 불가
      return false;
    }
    return true;
  }

  getCreated(date: string): string {
    return DATEHANDLER.strToYYYYMMDD(date, this.$i18n.locale);
  }

  moveTo(item: CrawledData) {
    var query: any = {
      boardId: item.BoardId,
      itemId: item.ItemId,
    };

    if (item.FieldInfo != null || item.FieldInfo != undefined) {
      query.fieldInfo = item.FieldInfo;
    }

    this.$router.push({
      name: `ItemView`,
      params: { boardUrl: item.BoardUrl },
      query: query,
    });
  }

  goBack() {
    if (history.length && history.state != null) {
      this.$router.go(-1);
    } else {
      this.$router.push({
        name: "Front",
      });
    }
  }
}
