























































































import { Component, Vue, Prop } from "vue-property-decorator";
import DATEHANDLER from "@/common/dateHandler";
// models
import * as ApvEnumString from "@/models/approval/enums/ApvEnumString";
import LineUserContainer from "@/models/approval/LineUserContainer";
import LineInfo from "@/models/approval/LineInfo";
import { ApvTypeCode, LineStepType } from "@/models/approval/enums/ApvEnums";
import ApprovalLineUser from "@/models/approval/ApprovalLineUser";
import Attachment from "@/models/approval/Attachment";
import * as ApvEnum from "@/models/approval/enums/ApvEnums";
// components
import ApvLineUserCard from "./ApvLineUserCard.vue";

@Component({
  components: {
    ApvLineUserCard,
    ApvLineGroupCard: () => import("./ApvLineGroupCard.vue"),
  },
})
export default class ApvLine extends Vue {
  @Prop() lineInfo!: LineInfo;
  @Prop() apvId!: number;
  @Prop() userId!: string;

  private lineUserList: LineUserContainer[] = [];

  private reviewerList: Array<ApprovalLineUser[]> = [[]];

  private hasCooperator: boolean = false;
  private hasAgreement: boolean = false;

  mounted() {
    if (this.lineInfo) {
      this.lineUserList = this.lineInfo.apvLineUserContainerList;

      this.lineUserList.forEach((value, index) => {
        this.reviewerList[index] = this.getReviewerList(value.reviewerList);
        if (!this.hasCooperator) {
          this.hasCooperator =
            this.reviewerList[index].findIndex(
              (o) => o.lineApvTypeCode == ApvEnum.ApvTypeCode.ATY_013
            ) > -1
              ? true
              : false;
        }

        if (!this.hasAgreement) {
          this.hasAgreement =
            this.reviewerList[index].findIndex(
              (o) => o.lineApvTypeCode == ApvEnum.ApvTypeCode.ATY_012
            ) > -1
              ? true
              : false;
        }
      });
    }
  }
  getDateAsYYYYMMDDHHmm(date: string): string {
    return DATEHANDLER.dateAsYYYYMMDDHHmm(date, this.$i18n.locale);
  }

  // 검토자 목록에서 빈 항목을 제외
  getReviewerList(reviewerList: ApprovalLineUser[]) {
    return reviewerList
      .filter((o) => {
        switch (o.lineStepType) {
          case LineStepType.User:
            return o.lineUserID != undefined && o.lineUserID != "" ? true : false;
          case LineStepType.ApvRoleGroup:
            return o.lineRoleGroupID != undefined && o.lineRoleGroupID != "" ? true : false;
          case LineStepType.Dept:
            return o.lineDeptID != undefined && o.lineDeptID != "" ? true : false;
        }
      })
      .reverse();
  }

  getLineStatus() {
    return ApvEnumString.lineStatusToString(this.lineInfo.lineStatusCode);
  }

  downloadAttachment(attach: Attachment) {
    location.href = `/SmartTalk/Attach/Response/DownLoad.ashx?id=${attach.attachID}`;
  }

  isReviewer(reviewer: ApprovalLineUser) {
    return (
      reviewer.lineApvTypeCode != ApvTypeCode.ATY_012 &&
      reviewer.lineApvTypeCode != ApvTypeCode.ATY_013
    );
  }

  isCooperator(reviewer: ApprovalLineUser) {
    return reviewer.lineApvTypeCode == ApvTypeCode.ATY_013;
  }

  getAgreementGroupUsers(listIndex: number) {
    var groupIndex = -1;
    var userList = this.reviewerList[listIndex];
    var groupUserList: any[][] = [];
    for (var i = 0; i < userList.length; i++) {
      if (userList[i].lineApvTypeCode == ApvTypeCode.ATY_012) {
        // 첫 번째 멤버가 아닐 때
        if (i > 0 && userList[i - 1].lineApvTypeCode == ApvTypeCode.ATY_012) {
          groupUserList[groupIndex].push(userList[i]);
        }
        // 첫 번째 멤버일 때
        else {
          groupIndex++;
          groupUserList[groupIndex] = [];
          groupUserList[groupIndex].push(userList[i]);
        }
      }
    }
    return groupUserList;
  }
}
