











































import { Component, Vue, Prop } from "vue-property-decorator";
import DATEHANDLER from "@/common/dateHandler";
import ApprovalApi from "@/api/approvalApi";
import ModificationOpinion from "@/models/approval/ModificationOpinion";
import { EventBus, EVENT } from "@/common/eventBus";
@Component({
  components: {
    HdConfirm: () => import("@/components/HdConfirm.vue"),
  },
})
export default class ApvModificationOpinionItem extends Vue {
  @Prop() opinionString!: string;
  @Prop() hasApprovalAuth!: boolean;
  private modificationOpinions: ModificationOpinion[] = [];
  private cancelConfirmDialog: boolean = false;

  mounted() {
    if (this.opinionString.length) {
      this.modificationOpinions = JSON.parse(this.opinionString);
    }
  }

  getModificationRequestStatusClass(request: ModificationOpinion) {
    switch (request.RequestState) {
      case "Request":
        return "hd-apv-line-card__modiOpinion__requested";
      case "Canceled":
        return "hd-apv-line-card__modiOpinion__canceled";
      case "Completed":
        return "hd-apv-line-card__modiOpinion__completed";
    }
  }

  // 수정요청 취소
  handleCancelDecision(confirm: boolean) {
    if (confirm) {
      var requestID = this.modificationOpinions.find((o) => o.RequestState == "Request")?.RequestID;
      if (requestID) {
        ApprovalApi.CancelModificationRequest(Number(this.$route.params.apvId), requestID).then(
          (result) => {
            if (result.Success) {
              EventBus.$emit(EVENT.APPROVAL_REFRESH_DETAIL);
            } else {
              this.$q.notify({
                message: this.$t("ApvMobile_ErrorWhileCancelingTheRequest").toString(),
                group: false,
                color: "white",
                textColor: "primary",
                position: "top",
                timeout: 500,
              });
            }
          }
        );
      }
    }
  }

  getMotificationRequestStatusLang(request: ModificationOpinion) {
    switch (request.RequestState) {
      case "Request":
        return this.$t("ModificationRequest_State_Request").toString();
      case "Canceled":
        return this.$t("ModificationRequest_State_Canceled").toString();
      case "Completed":
        return this.$t("ModificationRequest_State_Completed").toString();
    }
  }

  getDateAsYYYYMMDDHHmm(date: string): string {
    return DATEHANDLER.dateAsYYYYMMDDHHmm(date, this.$i18n.locale);
  }
}
