import axios from "axios";
import BoardResponse from "@/models/board/response/BoardResponse";
import BoardItemResponse from "@/models/board/response/BoardItemResponse";
import ItemSearchOption from "@/models/board/ItemSearchOption";
import CommentListResponse from "@/models/board/response/CommentListResponse";
import RecommendResponse from "@/models/board/response/RecommendResponse";
import * as CommentRequest from "@/models/board/request/CommentRequest";
import ApiResult from "@/models/ApiResult";
import CrawledData from "@/models/board/CrawledData";
import BoardInsertRequest from "@/models/board/request/BoardInsertRequest";
import PasswordEncryptionKey from "@/models/board/request/PasswordEncryptionKey";

type AspxResponse = {
  d: RecommendResponse;
};

export default {
  /**
   * 게시글 목록을 반환한다.
   * @param boardUrl 게시판 URL
   * @param searchOption 검색 옵션
   */
  async getBoardItemListAsync(
    boardUrl: string,
    searchOption?: ItemSearchOption
  ): Promise<BoardResponse> {
    const response = await axios.post<BoardResponse>(
      `/Portal/api/v1/Board/ItemsByUrl?url=${boardUrl}`,
      searchOption
    );

    return response.data;
  },

  /**
   * 게시글 정보를 반환한다.
   * @param boardId 게시판 ID
   * @param itemId  게시글 ID
   * @param getFields 필드 조회 여부
   * @param searchType 검색 타입["" | "Title" | "Author" | "Summary"]. 이전글/다음글 검색에 사용
   * @param searchKeyword 검색어. 이전글/다음글 검색에 사용
   * @param fieldInfo 필드 필터 정보. 이전글/다음글 검색에 사용
   */
  async getBoardItemAsync(
    boardId: string,
    itemId: string,
    getFields: boolean = false,
    searchType: string = "",
    searchKeyword: string = "",
    fieldInfo: string = ""
  ): Promise<ApiResult<BoardItemResponse>> {
    const response = await axios.get<ApiResult<BoardItemResponse>>(
      `/Portal/api/v1/Board/Item?boardId=${boardId}&itemId=${itemId}&getFeilds=${getFields}&searchType=${searchType}&searchKeyword=${searchKeyword}&fieldInfo=${fieldInfo}`
    );

    return response.data;
  },

  /**
   * 게시글의 댓글 목록을 반환한다.
   * @param boardId 게시판 ID
   * @param itemId 게시글 ID
   * @param page 페이지 번호
   * @param size 페이지 사이즈
   */
  async getCommentListAsync(
    boardId: string,
    itemId: string,
    page: number,
    size: number
  ): Promise<CommentListResponse> {
    const response = await axios.get<CommentListResponse>(
      `/Portal/api/v1/Board/Comments?BoardId=${boardId}&ItemId=${itemId}&pageNum=${page}&pageSize=${size}`,
      { withCredentials: true }
    );

    return response.data;
  },

  /**
   * 댓글을 등록한다.
   * @param request 댓글 등록 요청
   */
  async insertCommentAsync(request: CommentRequest.Insert): Promise<void> {
    const response = await axios.post<void>(`/Portal/Board/Controls/MakeComment.ashx`, {
      WorkType: "CommentInsert",
      ListID: request.ListID,
      ItemID: request.ItemID,
      Content: request.Content,
      ParentCommentID: request.ParentCommentID,
      IsAnonymousUserMode: request.IsAnonymousUserMode,
      DisplayName: request.DisplayName,
      TabType: "",
    });
    return response.data;
  },

  /**
   * 댓글을 수정한다.
   * @param request 댓글 수정 요청
   */
  async updateCommentAsync(request: CommentRequest.Update): Promise<void> {
    const response = await axios.post<void>(`/Portal/Board/Controls/MakeComment.ashx`, {
      WorkType: "CommentUpdate",
      ListID: request.ListID,
      ItemID: request.ItemID,
      CommentID: request.commentID,
      Content: request.Content,
      TabType: "",
    });
    return response.data;
  },

  /**
   * 댓글을 삭제한다.
   * @param request 댓글 삭제 요청
   */
  async deleteCommentAsync(request: CommentRequest.Delete): Promise<void> {
    const response = await axios.post<void>(`/Portal/Board/Controls/MakeComment.ashx`, {
      WorkType: "CommentDelete",
      ListID: request.ListID,
      ItemID: request.ItemID,
      CommentID: request.CommentID,
      TabType: "",
    });
    return response.data;
  },

  /**
   * 게시글 추천 / 추천 취소
   * @param listId 게시판 ID
   * @param itemId 게시글 ID
   */
  async toggleRecommendAsync(boardId: string, itemId: string): Promise<RecommendResponse> {
    const response = await axios.post<AspxResponse>(
      `/Portal/IF/BoardIF/BoardResponse.aspx/SetRecommendCount`,
      {
        listId: boardId,
        itemId: itemId,
      }
    );
    return response.data.d;
  },

  /**
   * 크롤링 데이터를 가져온다
   * @param crawlingId 게시판 크롤링 ID
   */
  async getCrawlingData(crawlingId: string): Promise<ApiResult<CrawledData[]>> {
    const response = await axios.get<ApiResult<CrawledData[]>>(
      `/Portal/api/v1/CrawlingController/GetCrawlingData?crawlingId=${crawlingId}`
    );
    return response.data;
  },

  /**
   * 스크랩 추가/제거
   * @param crawlingId 게시판 크롤링 ID
   */
  async toggleScrap(boardId: string, itemId: string, setScrap: boolean): Promise<boolean> {
    const response = await axios.post<boolean>(
      `/Portal/api/v1/Scrap/SetScrap?boardId=${boardId}&itemId=${itemId}&setScrap=${setScrap}`
    );
    return response.data;
  },

  /**
   * 게시글 작성
   */
  async writeItem(request: BoardInsertRequest): Promise<ApiResult<boolean>> {
    const response = await axios.post<ApiResult<boolean>>(
      `/Portal/api/v1/Board/InsertItem`,
      request
    );

    return response.data;
  },

  /**
   * Preview 파일 존재여부 확인
   * @param fileId 파일 ID
   * @param itemId 게시글 ID
   * @param boardId 게시판 ID
   */
  async checkPreview(fileId: string, itemId: string, boardId: string): Promise<any> {
    const response = await axios.post<any>(
      `/Portal/Board/Controls/Attachment/PreviewCheckHandler.ashx?f=${fileId}&i=${itemId}&b=${boardId}`
    );

    return response.data;
  },

  /**
   * 게시판 본문 필드
   * @param boardId 게시판 ID
   * @param itemId 게시글 ID
   */
  async GetItemFieldHtml(boardId: string, itemId: string): Promise<string> {
    const response = await axios.get<string>(
      `/Portal/Board/Pages/Content/FieldWithoutContent.aspx?BoardId=${boardId}&ItemId=${itemId}`
    );

    return response.data;
  },

  /**
   * 익명 게시글/댓글 RSA 공개키 조회
   */
  async GetPasswordEncryptionKey(): Promise<PasswordEncryptionKey> {
    const response = await axios.get<PasswordEncryptionKey>(
      `/Portal/api/v1/Board/GetPasswordEncryptionKey`
    );

    return response.data;
  }
};
