



































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { mapActions } from "vuex";
import BoardApi from "@/api/boardApi";
import BoardInsertRequest from "@/models/board/request/BoardInsertRequest";
import { Buffer } from "buffer";
const NodeRSA = require("node-rsa");

@Component({
  components: {
    HdConfirm: () => import("@/components/HdConfirm.vue"),
  },
  methods: {
    ...mapActions(["loadConfigs"]),
  },
})
export default class ItemWrite extends Vue {
  private loadConfigs!: () => Promise<any>;
  private boardUrl: string = "";
  private guideTitle: string = "";
  private guideMessage: string = "";
  private isAnonymous: boolean = false;

  private title: string = "";
  private content: string = "";
  private validating: boolean = false;

  private ConfirmDialog: boolean = false;
  private CompletionGuideDialog: boolean = false;

  private modules: string = '';
  private exponent: string = '';

  mounted() {
    this.init();
  }

  init() {
    this.clearFields();
    this.loadConfigs().then((config) => {
      this.boardUrl = this.$route.params.boardUrl;
      var currentBoardConfig = (config.board.writeBoard as any[]).find((o) => {
        return o.boardUrl == this.boardUrl;
      });
      this.guideTitle = currentBoardConfig?.guideTitle ?? "";
      this.guideMessage = currentBoardConfig?.guideMessage ?? "";
      this.isAnonymous = currentBoardConfig?.isAnonymous ?? false;

      if (this.isAnonymous) {
        BoardApi.GetPasswordEncryptionKey().then(result => {
          if (result.Success) {
            this.modules = result.Modules;
            this.exponent = result.Exponent;
          }
        });
      }
    });
  }

  // 내용 삭제
  clearFields() {
    this.title = "";
    this.content = "";
  }

  // 저장 버튼 클릭
  handleSubmit() {
    if (!this.title || !this.title.length) {
      (this.$refs.title as HTMLInputElement).focus();
      return;
    }

    this.ConfirmDialog = true;
  }

  // 저장 확인
  handleDecision(save: boolean) {
    if (save) {
      var splitted = this.content.split("\n");
      var refinedContent = "";

      var template = "<p style='line-height: 180%;'>{0}</p>";
      splitted.forEach((item) => {
        var content = item.trim().length ? item.trim() : "<br/>";
        refinedContent += template.replace("{0}", content);
      });

      var request: BoardInsertRequest = {
        BoardUrl: this.boardUrl,
        Title: this.title,
        Contents: refinedContent,
        AnonymousName: this.isAnonymous ? "익명" : "",
        AnonymousPassword: this.isAnonymous ? this.generatePassword("0000") : ""
      };

      BoardApi.writeItem(request).then(() => {
        this.CompletionGuideDialog = true;
      });
    }
  }

  // 저장 완료 확인
  handleCompletionGuideConfirm() {
    this.$router.push({ name: "Menu" });
  }

  // 내용 입력창에 포커스
  setFocus() {
    (this.$el.querySelector("textarea") as HTMLTextAreaElement).focus();
  }

  generatePassword(originTxt: string) {
    var publicKey = new NodeRSA();
    var getPem = require('rsa-pem-from-mod-exp');
    var publicKeyPem = getPem(this.modules, this.exponent);
    publicKey.importKey(publicKeyPem, 'pkcs1-public');

    return publicKey.encrypt(originTxt, 'base64').toString();
  }

  @Watch("$route")
  handleBoardUrlChanged(): void {
    this.$nextTick(() => {
      this.init();
    });
  }
}
