import { render, staticRenderFns } from "./ApvList.vue?vue&type=template&id=79ab6a55&scoped=true&"
import script from "./ApvList.vue?vue&type=script&lang=ts&"
export * from "./ApvList.vue?vue&type=script&lang=ts&"
import style0 from "./ApvList.vue?vue&type=style&index=0&id=79ab6a55&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79ab6a55",
  null
  
)

export default component.exports
import QSlideTransition from 'quasar/src/components/slide-transition/QSlideTransition.js';
import QInfiniteScroll from 'quasar/src/components/infinite-scroll/QInfiniteScroll.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QItemLabel from 'quasar/src/components/item/QItemLabel.js';
import QSpinnerDots from 'quasar/src/components/spinner/QSpinnerDots.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QSlideTransition,QInfiniteScroll,QList,QItem,QItemSection,QItemLabel,QSpinnerDots});
