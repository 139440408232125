




























































































































import { Component, Vue } from "vue-property-decorator";
import CafeteriaMenuApi from "@/api/cafeteriaMenuApi";
// models
import DATEHANDLER from "@/common/dateHandler";
import KVType from "@/models/common/KVType";
import MealInfo from "@/models/cafeteriaMenu/MealInfo";
// component
import HdComboBox from "@/components/HdComboBox.vue";
import HdWeekCalendar from "@/components/HdWeekCalendar.vue";
import MenuItem from "./components/MenuItem.vue";
import HdLoadingMask from "@/components/HdLoadingMask.vue";

@Component({
  components: {
    HdLoadingMask,
    HdComboBox,
    HdWeekCalendar,
    MenuItem,
    HdAlternativeMessage: () => import("@/components/HdAlternativeMessage.vue"),
  },
})
export default class CafeteriaMenu extends Vue {
  private date: Date = new Date();
  private restaurantOptions: KVType[] = [];
  private restaurant: KVType = {} as KVType;
  private breakfast: MealInfo[] = [];
  private lunch: MealInfo[] = [];
  private dinner: MealInfo[] = [];
  private convenienceMeal: MealInfo[] = [];
  private midnightSnack: MealInfo[] = [];
  private loading: boolean = false;
  private restaurantDisabled: boolean = false;

  mounted() {
    this.loadRestaurants().then(this.loadMenus);
  }

  async loadRestaurants() {
    return await CafeteriaMenuApi.getRestaurantsAsync().then((result) => {
      if (result.Success && result.Data.length) {
        this.restaurantOptions = [];
        result.Data.forEach((restaurant) => {
          if (restaurant.DeletedYN != "Y") {
            this.restaurantOptions.push({
              key: restaurant.RId,
              value: restaurant.RestaurantName,
            });
          }
        });

        this.restaurantDisabled = false;
        this.restaurant = this.restaurantOptions[0];
      } else {
        this.restaurantOptions = [];
        this.restaurantOptions.push({
          key: "-1",
          value: this.$t("WeeklyMenu_NoRestaurantMessage").toString(),
        });
        this.restaurantDisabled = true;
        this.restaurant = this.restaurantOptions[0];
      }
    });
  }

  async loadMenus() {
    if (
      this.restaurant == undefined ||
      this.restaurant.key == undefined ||
      this.restaurant.key == "-1"
    ) {
      return;
    }

    this.loading = true;
    return await CafeteriaMenuApi.getMenusAsync(
      this.restaurant.key,
      DATEHANDLER.dateToStrYYYYMMDD(this.date)
    )
      .then((result) => {
        if (result.Success) {
          this.breakfast = [];
          this.lunch = [];
          this.dinner = [];
          this.midnightSnack = [];
          this.convenienceMeal = [];

          result.Data.forEach((menu) => {
            if (menu.Menu.length) {
              if (menu.CourseValue.startsWith("조식")) {
                this.breakfast.push(menu);
              } else if (menu.CourseValue.startsWith("중식")) {
                this.lunch.push(menu);
              } else if (menu.CourseValue.startsWith("간편식")) {
                this.convenienceMeal.push(menu);
              } else if (menu.CourseValue.startsWith("석식")) {
                this.dinner.push(menu);
              } else if (menu.CourseValue.startsWith("야식")) {
                this.midnightSnack.push(menu);
              } else {
                this.$log.debug("unknown", menu);
              }
            }
          });
        }
        this.loading = false;
      })
      .catch((e) => {
        this.loading = false;
        this.$log.error(e);
      });
  }

  goBack() {
    if (history.length && history.state != null) {
      this.$router.go(-1);
    } else {
      this.$router.replace({ name: "Front" });
    }
  }

  handleRestaurantChanged(option: KVType) {
    this.restaurant = option;
    this.loadMenus();
  }

  // 날짜 변경 적용
  handleDateChanged(date: Date) {
    this.date = date;
    this.loadMenus();
  }

  calculateMessageHeight() {
    if (!this.$refs.top) {
      return;
    }
    var fullHeight = `calc(${document.getElementsByTagName("main")[0].style.minHeight} - ${
      (this.$refs.top as HTMLDivElement).clientHeight
    }px)`;
    return fullHeight;
  }
}
