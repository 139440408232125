import { render, staticRenderFns } from "./Error.vue?vue&type=template&id=3fa411dc&scoped=true&"
import script from "./Error.vue?vue&type=script&lang=ts&"
export * from "./Error.vue?vue&type=script&lang=ts&"
import style0 from "./Error.vue?vue&type=style&index=0&id=3fa411dc&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3fa411dc",
  null
  
)

export default component.exports
import QPageContainer from 'quasar/src/components/page/QPageContainer.js';
import QPage from 'quasar/src/components/page/QPage.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QPageContainer,QPage,QBtn});
