






















import { Component, Vue } from "vue-property-decorator";
// component
import HdLoadingMask from "@/components/HdLoadingMask.vue";
import ApvOriginal from "../components/ApvOriginal.vue";

@Component({
  components: {
    HdLoadingMask,
    ApvOriginal,
  },
})
export default class OriginalDocumentPopup extends Vue {
  private apvId: string = this.$route.params.apvId;
  private loading: boolean = true;

  handleClickClose() {
    this.$emit("close");
  }
}
