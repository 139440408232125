

























































































































import { Component, Vue, Watch } from "vue-property-decorator";
import DATEHANDLER from "@/common/dateHandler";
import KVType from "@/models/common/KVType";

type PeriodOption = "oneMonth" | "sixMonth" | "oneYear" | "custom";

@Component({
  components: {
    HdSelectBox: () => import("@/components/HdSelectBox.vue"),
  },
})
export default class ApvSearchField extends Vue {
  private periodOptions = [
    { label: this.$t("ApvMobile_OneMonth"), value: "oneMonth" },
    { label: this.$t("ApvMobile_SixMonth"), value: "sixMonth" },
    { label: this.$t("ApvMobile_OneYear"), value: "oneYear" },
    { label: this.$t("ApvMobile_CustomPeriod"), value: "custom" },
  ];
  private searchTypeOptions = [
    { key: "Title", value: this.$t("ApvMobile_Subject").toString() },
    { key: "FormName", value: this.$t("ApvMobile_Form").toString() },
    { key: "UserName", value: this.$t("ApvMobile_Drater").toString() },
  ];
  // 기본값 1년
  private period: PeriodOption = (this.$route.query.period ?? "oneYear") as PeriodOption;
  private searchType: KVType = this.searchTypeOptions[0];
  private searchKeyword: string = (this.$route.query.searchKeyword as string) ?? "";
  private fromDate: string = (this.$route.query.startDate as string) ?? "";
  private toDate: string = (this.$route.query.endDate as string) ?? "";

  // 한국어 달력 표시
  private daysKo = ["일", "월", "화", "수", "목", "금", "토"];
  private monthsKo = [
    "1월",
    "2월",
    "3월",
    "4월",
    "5월",
    "6월",
    "7월",
    "8월",
    "9월",
    "10월",
    "11월",
    "12월",
  ];
  private localeKo = {
    /* starting with Sunday */
    days: this.daysKo,
    daysShort: this.daysKo,
    months: this.monthsKo,
    monthsShort: this.monthsKo,
    firstDayOfWeek: 0,
  };

  private fromDateDialog: boolean = false;
  private toDateDialog: boolean = false;
  private searchTypeDialog: boolean = false;

  mounted() {
    var searchType = this.$route.query.searchType;
    if (searchType != undefined) {
      this.searchType =
        this.searchTypeOptions.find((o) => o.key == searchType) ?? this.searchTypeOptions[0];
    }

    if (this.period != "custom") {
      this.setPeriodDate();
    }

    this.$nextTick(() => {
      (this.$refs.searchInput as HTMLInputElement).focus();
    });
  }

  setPeriodDate() {
    var tempDate = new Date();

    switch (this.period) {
      case "oneMonth":
        tempDate.setMonth(tempDate.getMonth() - 1);
        break;
      case "sixMonth":
        tempDate.setMonth(tempDate.getMonth() - 6);
        break;
      case "oneYear":
        tempDate.setMonth(tempDate.getMonth() - 12);
        break;
      case "custom":
        return;
    }

    this.fromDate = DATEHANDLER.dateToStrYYYYMMDD(tempDate);
    this.toDate = DATEHANDLER.dateToStrYYYYMMDD(new Date());
  }

  handleOptionChange(option: KVType) {
    this.searchType = option;
  }

  handleSearch() {
    this.$router
      .replace({
        query: {
          isSearch: "true",
          startDate: this.fromDate,
          endDate: this.toDate,
          searchType: this.searchType.key,
          searchKeyword: this.searchKeyword,
          period: this.period,
        },
      })
      .catch(() => {
        //
      });
    this.$emit("search", this.fromDate, this.toDate, this.searchType.key, this.searchKeyword);
  }

  @Watch("period")
  handeChangePeriodOtion() {
    this.setPeriodDate();
  }

  fromDateOptions(date: string) {
    date = date.replace(/\//gi, "-");

    var today = DATEHANDLER.dateToStrYYYYMMDD(new Date());
    return date <= today && date <= this.toDate;
  }

  toDateOptions(date: string) {
    date = date.replace(/\//gi, "-");

    var today = DATEHANDLER.dateToStrYYYYMMDD(new Date());
    return date <= today && date >= this.fromDate;
  }
}
