
























































































import { Component, Vue, Prop } from "vue-property-decorator";
import DATEHANDLER from "@/common/dateHandler";
import ApprovalApi from "@/api/approvalApi";
// models
import Document from "@/models/approval/Document";
import { ApvAdvancedSearchListType } from "@/models/approval/enums/ApvEnums";
import UserInfo from "@/models/board/UserInfo";
import DocumentSearchRequest from "@/models/approval/request/DocumentSearchRequest";
import KVType from "@/models/common/KVType";
// component
import HdLoadingMask from "@/components/HdLoadingMask.vue";

@Component({
  components: {
    HdLoadingMask,
    ApvSearchField: () => import("./components/ApvSearchField.vue"),
    HdComboBox: () => import("@/components/HdComboBox.vue"),
    HdAlternativeMessage: () => import("@/components/HdAlternativeMessage.vue"),
  },
})
export default class ApvList extends Vue {
  @Prop() listType!: ApvAdvancedSearchListType;
  private documents: Document[] = [];
  private totalCount: number = 0;
  private loading: boolean = true;

  // private openSearchField: boolean = false;
  private openSearchField: boolean = Boolean(this.$route.query.isSearch) ?? false;

  // 검색조건
  private page: number = 1;
  private size: number = 15;
  private searchType: "Title" | "FormName" | "UserName" | null =
    (this.$route.query.searchType as "Title" | "FormName" | "UserName") ?? null;
  private searchKeyword: string = (this.$route.query.searchKeyword as string) ?? "";
  private startDate: string | null = (this.$route.query.startDate as string) ?? null;
  private endDate: string | null = (this.$route.query.endDate as string) ?? null;

  private boxOptions: KVType[] = [];
  private boxOption: string = "";

  mounted(): void {
    this.loadDocumentList(false);

    switch (this.listType) {
      case ApvAdvancedSearchListType.ReceiptCircularCc:
      case ApvAdvancedSearchListType.ReceiptTo:
        this.boxOptions = [
          {
            key: ApvAdvancedSearchListType.ReceiptCircularCc,
            value: this.$t("ApvMobile_ReceiptCircularCc").toString(),
          },
          {
            key: ApvAdvancedSearchListType.ReceiptTo,
            value: this.$t("ApvMobile_ReceiptTo").toString(),
          },
        ];
    }
  }

  getDateAsYYYYMMDDHHmm(str: string): string {
    return DATEHANDLER.dateAsYYYYMMDDHHmm(str, this.$i18n.locale);
  }

  onLoadMenu(index: number, done: Function): void {
    let currentCount = this.page * this.size;
    if (this.totalCount <= currentCount) {
      done();
    } else {
      this.loadDocumentList(true).then(() => {
        done();
      });
    }
  }

  viewDocument(doc: Document) {
    this.$router.push({
      name: "ApvView",
      params: { apvId: doc.ApvID.toString() },
    });
  }

  // NOTE: doc.ApvLineStatus 코드값이 있는게 아니라 다국어 처리된 문자열로 전송되므로 하드코딩함..
  // APV2의 tb_CodeLang에서 CodeType LineStatus
  getClassState(doc: Document) {
    switch (doc.ApvLineStatus.trim().toLowerCase()) {
      case "기안중":
      case "draft":
      case "검토중":
      case "review":
      case "승인중":
      case "progress":
      case "협조중":
      case "cooperation":
      case "담당처리중":
      case "charge progress":
      case "합의중":
      case "agreement":
        return "badge color10";
      case "반려":
      case "reject":
      case "철회":
      case "withdraw":
      case "보류":
      case "postpone":
      case "폐기":
      case "discard":
      case "결재취소":
      case "canceled":
      case "수신문서기안부서반려":
      case "reject approver":
      case "시스템 결재취소":
        return "badge color11";
      case "완료":
      case "complete":
      case "자가 승인":
      case "self approver":
        return "badge color12";
      default:
        return "badge color02";
    }
  }

  // 결재 문서 목록 불러오기
  async loadDocumentList(loadMore: boolean): Promise<void> {
    if (loadMore) {
      this.page = this.page + 1;
    } else {
      this.page = 1;
    }

    var user = this.$store.getters.getCurrentUser as UserInfo;
    if (user) {
      var request: DocumentSearchRequest = {
        ListType: this.listType,
        TenantID: user.ActivatedTenantId,
        UserID: user.UserID,
        LangCode: user.UserLangCode,
        DeptID: user.DeptID,
        Size: this.size,
        Page: this.page,
        SearchType: this.searchType,
        SearchKeyword: this.searchKeyword,
        StartDate: this.startDate,
        EndDate: this.endDate,
      };

      if (!loadMore) {
        this.documents = [];
      }
      return await ApprovalApi.getDocumentListAsync(request)
        .then((result) => {
          if (result.Success) {
            this.totalCount = result.Data.TotalCount;
            if (loadMore && this.documents?.length) {
              this.documents = this.documents.concat(result.Data.Items);
            } else {
              this.documents = result.Data.Items;
            }
          } else {
            this.$log.error(result);
          }
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
          this.$log.debug("[ApvList]", e);
        });
    }
  }

  // 문서함 변경(하위 문서함이 있는 경우)
  handleBoxChanged(option: KVType) {
    this.boxOption = option.key;
    this.$router.replace({
      name: "ApvList",
      params: { listType: option.key },
    });
  }

  closeSearchField() {
    this.openSearchField = false;
    this.$router.replace({ path: this.$route.path }).catch(() => {
      //
    });
  }

  // 검색
  handleSearch(
    startDate: string,
    endDate: string,
    searchType: "Title" | "FormName" | "UserName",
    searchKeyword: string
  ) {
    this.startDate = startDate;
    this.endDate = endDate;
    this.searchType = searchType;
    this.searchKeyword = searchKeyword;

    this.loadDocumentList(false);
  }

  // 검색결과 강조
  hilightText(text: string, type: "TITLE" | "FORMNAME" | "USERNAME"): string {
    // 입력 키워드 없음
    if (this.searchKeyword == "" || this.searchKeyword == undefined) return text;

    // 검색 타입 확인
    if (this.searchType != null && type != this.searchType.toUpperCase()) return text;

    var keyword = this.searchKeyword;
    if (!keyword) {
      return text;
    }
    return text.replace(new RegExp(keyword, "gi"), (match) => {
      return "<strong>" + match + "</strong>";
    });
  }

  calculateMessageHeight() {
    if (document.getElementsByTagName("main")[0] != undefined) {
      // refs는 화면이 모두 준비된 뒤에 사용가능해서, 최초 렌더링 시점에 undefiend가 되므로 대신 getElementById를 사용한다.
      var fullHeight = `calc(${document.getElementsByTagName("main")[0].style.minHeight} - ${
        document.getElementById("apv-list-top")?.clientHeight
      }px)`;

      return fullHeight;
    }
  }
}
