























































































import { Component, Vue, Watch } from "vue-property-decorator";
import { mapActions } from "vuex";
import { EventBus, EVENT } from "@/common/eventBus";
import ReservationInfo from "./models/ReservationInfo";
import MenuHandler from "@/common/menuHandler";

@Component({
  components: {
    HdMakeReservation: () => import("./MakeReservation.vue"),
    ReservationInfoView: () => import("./ReservationInfoView.vue"),
  },
  methods: {
    ...mapActions(["loadConfigs"]),
  },
})
export default class Reservation extends Vue {
  private loadConfigs!: () => Promise<any>;
  private menuName: string = this.$t("MeetingRoomReservation").toString();
  // 회의실 예약 화면 팝업
  private makeReservationDialog: boolean = false;
  // 회의실 예약 정보 팝업
  private openInformationPopup: boolean = false;
  private reservationId: number | null = null;

  // 선택한 정보들
  private resInfo: ReservationInfo = {} as ReservationInfo;
  private isEdit: boolean = false;
  private tabs: any[] = [];
  private currrentTab: string = "emptyRoom";
  private refresh: number = 0;
  private categoryId: string = "";

  created() {
    EventBus.$on(
      EVENT.OPEN_MAKE_RESERVATION,
      (resInfo: ReservationInfo, isEdit: boolean = false) => {
        this.resInfo = resInfo;
        this.makeReservationDialog = true;
        this.isEdit = isEdit;
      }
    );

    EventBus.$on(EVENT.REFRESH_RESERVATIONS, () => {
      this.refresh++;
    });
  }

  mounted() {
    // NOTE: 현대아산에서 회의실 대신 접견실이라는 용어를 사용하기 때문에, 정해진 이름 대신 메뉴 이름을 따라가도록 수정
    MenuHandler.getNthParentMenu(1).then((parent) => {
      this.menuName = parent?.ShortName ?? this.menuName;
      if (parent) {
        parent.Children.forEach((child) => {
          // 경로의 끝부분을 key로 가져온다
          var pathArr = child.Url.split("/");
          var key = pathArr[pathArr.length - 1];
          this.tabs.push({
            key: key,
            label: child.ShortName,
          });
        });
        var path = this.$route.path.split("/");
        this.currrentTab = path[path.length - 1];
      }
    });

    // 예약ID가 파라미터로 들어온 경우 정보 팝업을 띄워준다.
    if (this.$route.query.reservationId) {
      this.reservationId = Number(this.$route.query.reservationId);
      this.openInformationPopup = true;
    }

    // 회의실 예약 설정 적용
    this.loadConfigs().then((config) => {
      this.categoryId = config.reservation.meetingRoomCategory;
      this.refresh++;
    });
  }

  goBack() {
    if (history.length && history.state != null) {
      this.$router.go(-1);
    } else {
      this.$router.replace({ name: "Front" });
    }
  }

  handleMakeReservationClosed(refresh: boolean) {
    if (refresh) {
      this.refresh++;
    }
    this.makeReservationDialog = false;
  }

  handleInfoViewClosed(isDeleted: boolean) {
    if (isDeleted) {
      this.refresh++;
    }

    // NOTE: 화면 새로고침 시 다시 정보 팝업이 뜨지 않도록 queryparam을 없앤다.
    this.$router.replace({ query: undefined });

    this.openInformationPopup = false;
  }

  @Watch("currrentTab")
  handleSwipe() {
    this.$nextTick(() => {
      if (this.currrentTab != this.$route.path) {
        this.$router.replace(this.currrentTab).catch(() => {
          //
        });
      }
    });
  }
}
