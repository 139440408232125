import axios from "axios";
import ApiResult from "@/models/ApiResult";
import MobileUserInfo from "@/models/auth/MobileUserInfo";

export default {
  /**
   * 로그인 상태 확인
   * @param currentUserId 앱 사용중인 사용자 ID
   */
  async checkCurrentState(currentUserId: string | null): Promise<ApiResult<MobileUserInfo>> {
    const response = await axios.get<ApiResult<MobileUserInfo>>(
      `/Mobile/api/v1/auth/loginStatus?userID=${currentUserId}`
    );
    return response.data;
  },

  /**
   * 로그인(개발환경). 포탈 인증 정보 생성
   * @param userId 사용자 ID
   */
  async userAuthenticationAsync(userId: string): Promise<ApiResult<MobileUserInfo>> {
    const response = await axios.post<ApiResult<MobileUserInfo>>(
      `/Mobile/api/v1/auth/authentication/${userId}`
    );
    return response.data;
  },

  /**
   * 로그인. 포탈 인증 정보 생성
   * @param userId 사용자 ID
   * @param password 사용자 ID
   */
  async userLoginAsync(userId: string, password: string): Promise<ApiResult<MobileUserInfo>> {
    const timeZoneOffset = Math.floor(-(new Date().getTimezoneOffset() / 60));
    const response = await axios.get<ApiResult<MobileUserInfo>>(
      `/Mobile/api/v1/auth/login?userId=${userId}&password=${password}&timezone=${timeZoneOffset}`
    );
    return response.data;
  },

  /**
   * 로그아웃
   */
  async userLogoutAsync(): Promise<ApiResult<void>> {
    const response = await axios.get<ApiResult<void>>(`/Mobile/api/v1/auth/logout`);

    return response.data;
  },
};
