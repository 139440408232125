const MONTH_NAMES = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default {
  leftpad(val: number, resultLength = 2, leftpadChar = "0"): string {
    return (String(leftpadChar).repeat(resultLength) + String(val)).slice(String(val).length);
  },

  /**
   * 문자열을 YYYY-MM-DD로 변경
   * @param dateStr 입력 문자열
   */
  strToYYYYMMDD(dateStr: string, locale: string = "ko"): string {
    if (dateStr == undefined) {
      return "";
    }

    // NOTE: yyyy-MM-dd HH:mm:ss형태이면 iphone에서 변환이 안되므로 yyyy-MM-ddTHH:mm:ssZ형태로 nomalize해줌
    if (dateStr.split(" ")) {
      dateStr = dateStr.split(" ").join("T");
    }
    const date = new Date(dateStr);

    return this.dateToStrYYYYMMDD(date, locale);
  },

  /**
   * Date를 YYYY-MM-DD로 변경
   */
  dateToStrYYYYMMDD(date: Date, locale: string = "ko"): string {
    if (locale == "en") {
      return (
        this.getMonthNameEn(date.getMonth()) +
        " " +
        this.leftpad(date.getDate(), 2) +
        " " +
        date.getFullYear()
      );
    } else {
      return (
        date.getFullYear() +
        "-" +
        this.leftpad(date.getMonth() + 1, 2) +
        "-" +
        this.leftpad(date.getDate(), 2)
      );
    }
  },

  /**
   * Date를 다국어에 맞게 변경(한글: 2021년 05월 24일, 영어: MAY 24 2021)
   */
  dateToStringMultiLang(date: Date, langCode: string): string {
    switch (langCode) {
      case "ko":
        return `${date.getFullYear()}년 ${this.leftpad(date.getMonth() + 1, 2)}월 ${this.leftpad(
          date.getDate(),
          2
        )}일`;
      case "en": {
        return ` ${this.getMonthNameEn(date.getMonth())} ${this.leftpad(
          date.getDate(),
          2
        )} ${date.getFullYear()}`;
      }
      default:
        return this.dateToStrYYYYMMDD(date, langCode);
    }
  },

  /**
   * 영어로 '월'이름 가져오기
   */
  getMonthNameEn(month: number): string {
    return MONTH_NAMES[month];
  },

  /**
   * string Date를 YYYY-MM-DD HH:mm으로 변경
   */
  dateAsYYYYMMDDHHmm(dateStr: string, locale: string = "ko"): string {
    if (dateStr == undefined) {
      return "";
    }

    const date = new Date(dateStr);

    return (
      this.dateToStrYYYYMMDD(date, locale) +
      " " +
      this.leftpad(date.getHours(), 2) +
      ":" +
      this.leftpad(date.getMinutes(), 2)
    );
  },

  /**
   * string Date를 YYYY-MM-DD HH:mm:ss으로 변경
   */
  dateAsYYYYMMDDHHmmss(dateStr: string): string {
    if (dateStr == undefined) {
      return "";
    }

    const date = new Date(dateStr);

    return (
      this.dateToStrYYYYMMDD(date) +
      " " +
      this.leftpad(date.getHours(), 2) +
      ":" +
      this.leftpad(date.getMinutes(), 2) +
      ":" +
      this.leftpad(date.getSeconds(), 2)
    );
  },

  /**
   * UTC Date를 lacale Date로 변경
   * @param date UTD Date
   */
  convertUTCDateToLocalDate(date: Date) {
    const newDate = new Date(date);
    const offset = date.getTimezoneOffset() / 60;
    const hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate;
  },
};
