
































































































import { Component, Vue, Prop } from "vue-property-decorator";
import DATEHANDLER from "@/common/dateHandler";
// models
import LineInfo from "@/models/approval/LineInfo";
import * as ApvEnumString from "@/models/approval/enums/ApvEnumString";

@Component
export default class ApvSummary extends Vue {
  @Prop() lineInfo!: LineInfo;
  @Prop() refresh!: number;

  getDateYYYYMMDDHHmm(date: string): string {
    return DATEHANDLER.dateAsYYYYMMDDHHmm(date, this.$i18n.locale);
  }

  getApvStateString(): string {
    return ApvEnumString.lineStatusToString(this.lineInfo.lineStatusCode);
  }

  getAccessString(access: string): string {
    return ApvEnumString.accessToString(access);
  }
}
