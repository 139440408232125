

















































import { Component, Vue, Prop } from "vue-property-decorator";

const EVENT_DECISITION = "decision";

@Component
export default class HdConfirm extends Vue {
  @Prop({ default: "" }) title!: string;
  @Prop() message!: string;
  @Prop() confirm!: string;
  @Prop() cancel!: string;
  @Prop({ default: true }) useCancel!: boolean;
  @Prop({ default: "100%" }) width!: string;

  handleCancel() {
    this.$emit(EVENT_DECISITION, false);
  }

  handleConfirm() {
    this.$emit(EVENT_DECISITION, true);
  }
}
