import store from "@/store";
import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "App",
    component: () => import(/* webpackChunkName: "app" */ "../App.vue"),
    children: [
      {
        path: "/login",
        name: "Login",
        component: () => import(/* webpackChunkName: "login" */ "../views/Login.vue"),
        children: [],
      },
      {
        path: "/main",
        name: "Main",
        component: () => import(/* webpackChunkName: "main" */ "../views/Main.vue"),
        children: [],
      },
      {
        path: "/download",
        name: "Download",
        component: () => import(/* webpackChunkName: "download" */ "../views/Download.vue"),
        children: [],
      },
    ],
  },
  {
    path: "/front",
    name: "Front",
    component: () => import(/* webpackChunkName: "main" */ "../views/front/Index.vue"),
  },
  {
    path: "/front/crawledList",
    name: "CrawledList",
    component: () => import(/* webpackChunkName: "main" */ "../views/front/CrawledList.vue"),
  },
  {
    path: "/notification",
    name: "Notification",
    component: () => import(/* webpackChunkName: "main" */ "@/views/notification/Index.vue"),
  },
  // 전자결재
  {
    path: "/approval",
    name: "Approval",
    component: () => import(/* webpackChunkName: "main" */ "../views/approval/Index.vue"),
    children: [
      {
        path: "list/:listType",
        name: "ApvList",
        props: true,
        component: () => import(/* webpackChunkName: "main" */ "../views/approval/ApvList.vue"),
      },
    ],
  },
  {
    path: "/approval/view/:apvId",
    name: "ApvView",
    component: () => import(/* webpackChunkName: "main" */ "../views/approval/ApvDetail.vue"),
  },
  // 게시판
  {
    path: "/board/:boardUrl",
    name: "Board",
    component: () => import(/* webpackChunkName: "main" */ "../views/board/Index.vue"),
  },
  {
    path: "/board/:boardUrl/itemView",
    name: "ItemView",
    component: () => import(/* webpackChunkName: "main" */ "../views/board/ItemView.vue"),
  },
  // 주소록
  {
    path: "/yellowpage",
    name: "YellowPage",
    component: () => import(/* webpackChunkName: "main" */ "../views/yellowPage/Index.vue"),
    children: [
      {
        path: ":companyCode",
        component: () => import(/* webpackChunkName: "main" */ "../views/yellowPage/Index.vue"),
        children: [
          {
            path: "search",
            name: "MemberSearch",
            component: () =>
              import(/* webpackChunkName: "main" */ "../views/yellowPage/MemberSearch.vue"),
          },
          {
            // '*'는 0 이상의 부서 Depth를 의미
            path: "dept/:depts*",
            name: "DeptView",
            component: () =>
              import(/* webpackChunkName: "main" */ "../views/yellowPage/DeptView.vue"),
          },
        ],
      },
    ],
  },
  // 전체메뉴
  {
    path: "/menu",
    name: "Menu",
    component: () => import(/* webpackChunkName: "main" */ "../views/menu/Index.vue"),
  },
  {
    path: "/menu/favorites",
    name: "Favorites",
    component: () => import(/* webpackChunkName: "main" */ "../views/menu/FavoriteList.vue"),
  },
  // 회의실 예약
  {
    path: "/reservation",
    name: "Reservation",
    component: () => import(/* webpackChunkName: "main" */ "../views/reservation/Index.vue"),
    children: [
      {
        path: "emptyRoom",
        name: "EmptyRoom",
        props: true,
        component: () =>
          import(/* webpackChunkName: "main" */ "../views/reservation/EmptyRoom.vue"),
      },
      {
        path: "myReservations",
        name: "MyReservations",
        props: true,
        component: () =>
          import(/* webpackChunkName: "main" */ "../views/reservation/MyReservations.vue"),
      },
      {
        path: "allReservations",
        name: "AllReservations",
        props: true,
        component: () =>
          import(/* webpackChunkName: "main" */ "../views/reservation/AllReservations.vue"),
      },
    ],
  },
  // 주간식단
  {
    path: "/cafeteriaMenu",
    name: "CafeteriaMenu",
    component: () => import(/* webpackChunkName: "main" */ "../views/cafeteriaMenu/Index.vue"),
  },
  // QRCode
  {
    path: "/qrcode",
    name: "QRCode",
    component: () => import(/* webpackChunkName: "main" */ "../views/qrcode/Index.vue"),
  },
  // QRCode
  {
    path: "/qrcode/SeatHMX",
    name: "SeatHMX",
    component: () => import(/* webpackChunkName: "main" */ "../views/qrcode/SeatReservation_HMX.vue"),
  },  
  // 정적페이지
  {
    path: "/static",
    name: "StaticPage",
    component: () => import(/* webpackChunkName: "main" */ "../views/static/Index.vue"),
  },
  // 에러페이지(404)
  {
    path: "/:pathMatch(.*)*",
    component: () => import(/* webpackChunkName: "main" */ "../views/static/Error.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  //  base: process.env.BASE_URL,
  base: process.env.NODE_ENV === "production" ? "/Mobile" : "/",
  routes,
});

// Routing request가 있을 때마다 로그인 세션이 만료되었는지 확인
router.beforeEach((to, from, next) => {
  console.log(to);
  console.log(from);
  if (to.name?.toLowerCase() != "login") {    
    store.dispatch("checkSessionUser").then(() => {
      next();
    });
  } else {
    next();
  }
});

// NOTE: webview에서 router 변경내역을 인식하지 못해서 경로 변경이 있을 때마다 알려주도록 처리
router.afterEach((to, from) => {  
  if (to.name == "Login") return;
  if (to.name != from.name) {
    store.dispatch("loadConfigs").then((config) => {
      const fullUrl = `${config.baseUrl}${to.fullPath}`;
      try {
        window.webkit.messageHandlers.h1mobile.postMessage({
          command: "notifyURL",
          url: fullUrl,
        });
      } catch (e) {
        //
      }

      try {
        window.h1mobile.notifyURL(fullUrl);
      } catch (e) {
        //
      }
    });
  }
});

export default router;
