














































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { mapActions } from "vuex";
import DATEHANDLER from "@/common/dateHandler";
import MobileApi from "@/api/mobileApi";
import MenuHandler from "@/common/menuHandler";
import UserInfo from "@/models/board/UserInfo";
import MobileMenu from "@/models/menu/MobileMenu";
// component
import HdLoadingMask from "@/components/HdLoadingMask.vue";
import scroll from 'quasar/src/utils/scroll.js';;

// 즐겨찾기 메뉴 최대 표시 개수
const MAX_FAVORITE = 8;

@Component({
  components: {
    HdLoadingMask,
    HdConfirm: () => import("@/components/HdConfirm.vue"),
  },
  methods: {
    ...mapActions(["loadConfigs", "loadMobileMenu"]),
  },
})
export default class Front extends Vue {
  private loadConfigs!: () => Promise<any>;
  private loadMobileMenu!: () => Promise<any>;
  private user: UserInfo = {} as UserInfo;
  private splitterModel = 30;
  private favoriteSplitterModel = 50;
  private menus: MobileMenu[] = [];
  private favoriteMenus: MobileMenu[] = [];
  private menuLevel1: string = "";
  private lastAccessTime: string = "";

  private favoriteLoading: boolean = true;
  private allMenuLoading: boolean = true;
  private ConfirmDialog: boolean = false;

  private hideFavorites: boolean = true;
  private favoritHeight: number = 200;

  private height: string = "";

  mounted() {
    this.user = this.$store.getters.getCurrentUser;
    this.loadConfigs().then((config) => {
      this.user.PhotoURL1 = config.resource.photoUrl.replace("{0}", this.user.UserID);
    });

    if (this.$store.getters.getLastAccessTime != undefined) {
      this.lastAccessTime = DATEHANDLER.dateAsYYYYMMDDHHmmss(this.$store.getters.getLastAccessTime);
    }

    this.setFavoritMenus();
  }

  setFavoritMenus() {
    this.loadMobileMenu().then((result: MobileMenu[]) => {
      this.menus = result;
      this.allMenuLoading = false;
      this.menuLevel1 = this.$route.query.selected?.toString() ?? this.menus[0]?.MenuId;
      this.height = (this.$refs.main as HTMLElement).style.minHeight;

      MobileApi.getMobileFavoriteMenuListAsync(this.user.UserID).then(async (result) => {
        if (result.Success) {
          for (let i = 0; i < result.Data.length; i++) {
            let item = result.Data[i];
            var menuId = item.MenuId;
            if (menuId == undefined) {
              continue;
            }
            var mobileMenu = await MenuHandler.findMenuByMenuId(menuId);

            if (mobileMenu) {
              var favorite: MobileMenu = {} as MobileMenu;
              Object.assign(favorite, mobileMenu);
              favorite.LongName = item.Name;
              this.favoriteMenus.push(favorite);
              if (this.favoriteMenus.length >= MAX_FAVORITE) {
                break;
              }
            }
          }
        }
        this.favoriteLoading = false;
      });
    });
  }

  // 메뉴 이동
  moveToMenu(menu: MobileMenu): void {
    if (menu.Children != undefined && menu.Children.length) {
      return;
    }

    if (menu.Url?.length) {
      // 동일한 url을 갖는 다른 메뉴가 있으므로(무벡스 공지사항 등), menuId를 함께 보낸다
      this.$router.push({ path: menu.Url, query: { menuId: menu.MenuId } });
    }
  }

  // 즐겨찾기 메뉴 이동
  moveToFavoriteMenu(menu: MobileMenu): void {
    // 즐겨찾기 메뉴가 하위 메뉴를 포함하는 경우, 말단 노드를 찾아 이동시켜준다.
    var target: MobileMenu = {} as MobileMenu;
    Object.assign(target, menu);
    while (target.Children != undefined && target.Children.length) {
      target = target.Children[0];
    }

    if (!target.Url.startsWith("/")) {
      target.Url = `/${target.Url}`;
    }

    this.$router.push({ path: target.Url });
  }

  clickFavoriteHeader() {
    // 확대
    if (this.hideFavorites) {
      let item = scroll.getScrollTarget(this.$root.$el);
      var scrollHeight = scroll.getScrollHeight(item);
      var maxOffset = scrollHeight - (item as Window).outerHeight;
      scroll.setScrollPosition(item, maxOffset, 200);
    }
    // 전체보기 클릭(즐겨찾기가 하나라도 있을 때)
    else if (this.favoriteMenus.length) {
      this.$router.push({ name: "Favorites" });
    }
    // 축소(즐겨찾기가 하나도 없을 때)
    else {
      let item = scroll.getScrollTarget(this.$root.$el);
      scroll.setScrollPosition(item, 0, 200);
    }
  }

  onScroll() {
    if (!(this.$root.$el instanceof Element)) {
      return;
    }

    var item = scroll.getScrollTarget(this.$root.$el);
    var scrollHeight = scroll.getScrollHeight(item);
    var maxOffset = scrollHeight - (item as Window).outerHeight;
    var offset = scroll.getScrollPosition(item);
    if (offset > 0.9 * maxOffset) {
      this.hideFavorites = false;
    } else {
      this.hideFavorites = true;
    }
  }

  // 로그아웃 클릭
  handleLogoutButtonClick() {
    this.ConfirmDialog = true;
  }

  // 닫기 클릭
  goBack() {
    if (history.length && history.state != null) {
      this.$router.go(-1);
    } else {
      this.$router.replace({ name: "Front" });
    }
  }

  // 로그아웃 확인
  handleDecision(logout: boolean) {
    if (logout) {
      this.$store.dispatch("logout");
    }
  }

  // 현재 선택한 메뉴를 query에 넣어서 뒤로가기로 돌아왔을때 선택하게 한다.
  @Watch("menuLevel1")
  onMenuLevel1Changed() {
    this.$nextTick(() => {
      if (this.menuLevel1 != this.$route.query.selected?.toString()) {
        this.$router.replace({ query: { selected: this.menuLevel1 } });
      }
    });
  }
}
