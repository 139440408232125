


















import { Component, Vue, Prop } from "vue-property-decorator";
import { mapActions } from "vuex";

@Component({
  components: {
    HdAlternativeMessage: () => import("@/components/HdAlternativeMessage.vue"),
  },
  methods: {
    ...mapActions(["loadConfigs"]),
  },
})
export default class ApvOriginal extends Vue {
  @Prop() apvId!: string;
  @Prop({ default: false }) displayContentOnly!: boolean;
  private loadConfigs!: () => Promise<any>;
  private imageLoadFailed: boolean = false;

  mounted() {
    this.loadConfigs().then((config) => {
      var webshotUrl = `${config.webshot.url}?url=${config.approval.apvOriginal.url.replace(
        "{0}",
        this.apvId
      )}`;

      if (this.displayContentOnly) {
        webshotUrl += `&captureSelector=${config.approval.apvOriginal.captureSelector}`;
      }

      (this.$refs.originalApvImg as HTMLImageElement).src = webshotUrl;

      (this.$refs.originalApvImg as HTMLImageElement).addEventListener("load", () => {
        this.imageLoadFailed = false;
        this.$emit("load");
      });

      (this.$refs.originalApvImg as HTMLImageElement).addEventListener("error", () => {
        this.imageLoadFailed = true;
        this.$emit("load");
      });
    });
  }
}
