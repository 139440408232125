




























































import { Component, Vue, Prop } from "vue-property-decorator";
import { EventBus, EVENT } from "@/common/eventBus";
import ReservationApi from "@/api/reservationApi";
// model
import ReservationInfo from "./models/ReservationInfo";
// component
import ReservationDetail from "./components/ReservationDetail.vue";
import HdLoadingMask from "@/components/HdLoadingMask.vue";
import UserInfo from "@/models/board/UserInfo";

@Component({
  components: {
    ReservationDetail,
    HdLoadingMask,
    HdConfirm: () => import("@/components/HdConfirm.vue"),
    HdAlternativeMessage: () => import("@/components/HdAlternativeMessage.vue"),
  },
})
export default class ReservationInfoView extends Vue {
  @Prop() reservationId!: number;
  private resInfo: ReservationInfo | null = null;
  private deleteonfirmDialog: boolean = false;
  private editable: boolean = false;
  private deletable: boolean = false;
  // 일치하는 결과 없음
  private noData: boolean = false;

  mounted() {
    ReservationApi.getReservation(this.reservationId).then((result) => {
      if (result.Success) {
        var data = result.Data;
        this.resInfo = {
          reservationId: this.reservationId,
          date: new Date(data.RoomInfo.ReservationDatetime),
          startTime: new Date(data.RoomInfo.ResStartDatetime).getHours(),
          endTime: new Date(data.RoomInfo.ResEndDatetime).getHours(),
          bldgOption: {
            key: data.RoomInfo.BuildingID.toString(),
            value: data.RoomInfo.BuildingName ?? "",
          },
          floorOption: {
            key: data.RoomInfo.BldgFloorID?.toString() ?? "-1",
            value: `${data.RoomInfo.DisplayPosition}층` ?? "",
          },
          room: {
            key: data.RoomInfo.RoomID.toString() ?? "-1",
            value: data.RoomInfo.RoomName ?? "",
          },
          processState: data.RoomInfo.ProcessState,
          title: data.RoomInfo.Title,
          attendantList: data.AttendantList,
          referrerList: data.CcList,
          description: data.RoomInfo.Memo,
        };
        // 본인이 작성자이고 예약 시작 시간 전이면 수정 가능
        var user = this.$store.getters.getCurrentUser as UserInfo;
        var now = new Date();
        if (
          result.Data.RoomInfo.ResEmpNo == user.UserID &&
          new Date(data.RoomInfo.ResStartDatetime) > now
        ) {
          this.editable = true;
          this.deletable = true;
        } else if (
          result.Data.RoomInfo.ResEmpNo == user.UserID &&
          new Date(data.RoomInfo.ResEndDatetime) > now
        ) {
          this.deletable = true;
        }
      } else {
        this.noData = true;
      }
    });
  }

  handleClickClose() {
    this.$emit("close");
  }

  handleDecision(del: boolean) {
    if (del) {
      ReservationApi.deleteReservation(this.reservationId).then((result) => {
        if (result.Success) {
          this.$emit("close", true);
        }
      });
    }
  }

  handleEdit() {
    EventBus.$emit(EVENT.OPEN_MAKE_RESERVATION, this.resInfo, true);
    this.$emit("close", true, this.resInfo);
  }
}
