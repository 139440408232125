



























































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { mapActions } from "vuex";
import BoardApi from "@/api/boardApi";
import MenuHandler from "@/common/menuHandler";
import DATEHANDLER from "@/common/dateHandler";
// model
import ListItem from "@/models/board/ListItem";
import ItemSearchOption from "@/models/board/ItemSearchOption";
import Page from "@/models/common/page";
import KVType from "@/models/common/KVType";
// component
import HdLoadingMask from "@/components/HdLoadingMask.vue";
import MenuNavigator from "./MenuNavigator.vue";

@Component({
  components: {
    MenuNavigator,
    HdLoadingMask,
    HdSelectBox: () => import("@/components/HdSelectBox.vue"),
    HdAlternativeMessage: () => import("@/components/HdAlternativeMessage.vue"),
  },
  methods: {
    ...mapActions(["loadConfigs"]),
  },
})
export default class ItemList extends Vue {
  private loadConfigs!: () => Promise<any>;
  private boardUrl: string = "";
  private menuId: string = "";
  private listItems: ListItem[] = [];
  private loading = true;
  private loadMore = false;
  private page: Page = {} as Page;
  private totalItemCount: number = 0;
  private totalPage: number = 0;
  private isAnonymous: boolean = false;

  private isSearch: boolean = true;
  private searchTypeOptions: KVType[] = [
    { key: "", value: this.$t("Board_All").toString() },
    { key: "Title", value: this.$t("Board_Title").toString() },
    { key: "Author", value: this.$t("Board_Author").toString() },
    { key: "Summary", value: this.$t("Board_Summary").toString() },
  ];
  private searchType = this.searchTypeOptions[0];
  private searchKeyword: string = "";

  private pageNumber: number = 1;
  private pageSize: number = 10;

  private searchTypeDialog = false;

  // 카테고리 필드 표시여부.
  private displayCategory = false;
  private fieldSelectDialog = false;
  private searchFieldAlias = "";
  private fieldSelectOptions: KVType[] = [];
  private fieldOption: KVType | null = null;

  mounted() {
    this.init();
  }

  // 탭만 이동하는 경우가 있으므로 모든 값을 초기화해준다.
  init() {
    this.boardUrl = this.$route.params.boardUrl as string;
    this.menuId = this.$route.query.menuId as string;
    this.isSearch =
      this.$route.query.isSearch == undefined || this.$route.query.isSearch == "true"
        ? true
        : false;
    this.searchKeyword = this.$route.query.searchKeyword as string;

    var searchType = this.$route.query.searchType;
    if (searchType != undefined) {
      this.searchType =
        this.searchTypeOptions.find((o) => o.key == searchType) ?? this.searchTypeOptions[0];
    }
    this.fieldOption = null;
    this.searchFieldAlias = "";

    if (this.menuId == undefined || !this.menuId.length) {
      MenuHandler.findMenuByUrl(`board/${this.$route.params.boardUrl}`).then((menu) => {
        if (menu != undefined) {
          this.menuId = menu.MenuId;
        }
        this.loadBoardItems(false);
      });
    } else {
      //this.setFieldOption();
      this.loadBoardItems(false);
    }
  }

  getCreated(date: string): string {
    return DATEHANDLER.strToYYYYMMDD(date, this.$i18n.locale);
  }

  viewDetail(itemId: ListItem) {
    var fieldInfo = "";

    if (this.searchFieldAlias.length && this.fieldOption) {
      if (this.fieldOption.key != "all") {
        fieldInfo = `${this.searchFieldAlias}|${this.fieldOption?.key}`;
      }
    } else if (this.$route.query.fieldInfo != undefined) {
      var select = (this.$route.query.fieldInfo as string).split("|")[1]?.toLowerCase();
      if (select.length && select != "all") {
        fieldInfo = this.$route.query.fieldInfo as string;
      }
    }

    this.$router.push({
      name: `ItemView`,
      params: { boardUrl: this.boardUrl },
      query: {
        boardId: itemId.boardId,
        itemId: itemId.itemId,
        searchType: this.searchType.key,
        searchKeyword: this.searchKeyword,
        fieldInfo: fieldInfo,
        isAnonymousStr: this.isAnonymous ? "true" : "false"
      },
    });
  }

  handleSearchTypeChange(selected: KVType) {
    this.searchType = selected;
  }

  handleFieldChange(selected: KVType) {
    this.fieldSelectDialog = false;
    this.fieldOption = selected;
    this.searchKeyword = "";
    // this.loadBoardItems(false);
    this.handleSearch();
  }

  /** 검색 */
  handleSearch(): void {
    this.pageNumber = 1;
    this.totalItemCount = 0;

    //this.setFieldOption();
    this.updateQueryParam();
    this.loadBoardItems(false).then();
  }

  updateQueryParam() {
    var query: any = {
      isSearch: this.isSearch,
      searchType: this.searchType.key,
      searchKeyword: this.searchKeyword,
      menuId: this.menuId,
    };

    // URL에 필드정보가 있으면서, 필드 검색도 사용하는 경우 (현대아산 경조사 게시판 등)
    if (this.searchFieldAlias.length) {
      if (this.fieldOption?.key != "all") {
        query.fieldInfo = `${this.searchFieldAlias}|${this.fieldOption?.key}`;
      } else {
        query.fieldInfo = undefined;
      }
    }
    // NOTE: URL에 필드정보가 포함된 경우 적용 (movex에서 게시판 필터가 적용되어있는 경우가 있음)
    else if (this.$route.query.fieldInfo != undefined) {
      var select = (this.$route.query.fieldInfo as string).split("|")[1]?.toLowerCase();
      if (select.length && select != "all") {
        query.fieldInfo = this.$route.query.fieldInfo;
      } else {
        query.fieldInfo = undefined;
      }
    } else {
      query.fieldInfo = undefined;
    }

    this.$router
      .replace({
        query: query,
      })
      .catch(() => {
        //
      });
  }

  // 더보기
  async loadMoreItems(): Promise<void> {
    this.pageNumber++;
    await this.loadBoardItems(true);
  }

  async loadBoardItems(append: boolean = false): Promise<void> {
    this.boardUrl = this.$route.params.boardUrl;
    if (append) {
      this.loadMore = true;
    } else {
      this.loading = true;

      // 처음 한 번만 적용
      // 카테고리 표시 게시판 여부 확인
      var config = await this.loadConfigs();

      if (config.board.displayCategoryBoard) {
        // Movex 메뉴 처리를 위해서는 menuId로 확인해야 함.
        var index = config.board.displayCategoryBoard.findIndex((o: any) => {
          return o.menuId == this.menuId;
        });

        if (index > -1) {
          this.displayCategory = true;
          this.searchFieldAlias = config.board.displayCategoryBoard[index].searchFieldAlias;
          if (this.searchFieldAlias.length) {
            this.isSearch = this.$route.query.isSearch != undefined ? this.isSearch : false;
          }
        }
      }
      //}
      // 익명 게시판 여부 확인
      if (config.board.anonymousBoard) {
        this.isAnonymous = (config.board.anonymousBoard.findIndex((o: any) => {
          return o.boardUrl == this.boardUrl;
        }) > -1);
      }
    }

    var option: ItemSearchOption = {
      page: this.pageNumber,
      size: this.pageSize,
      type: this.searchType.key,
      keyword: this.searchKeyword,
      thumbSize: "MOBILE",
    };

    // NOTE: 전체 필드 검색시 fieldFilter를 null로 본낸다(this.fieldOption.key != "all")
    if (this.searchFieldAlias.length && this.fieldOption) {
      if (this.fieldOption.key != "all") {
        option.fieldFilter = `${this.searchFieldAlias}|${this.fieldOption?.key}`;
      }
    } else if (this.$route.query.fieldInfo != undefined) {
      var select = (this.$route.query.fieldInfo as string).split("|")[1]?.toLowerCase();
      if (select.length && select != "all") {
        option.fieldFilter = this.$route.query.fieldInfo as string;
      }
    }

    await BoardApi.getBoardItemListAsync(this.boardUrl, option).then((result) => {
      if (result.Success) {
        if (append) {
          this.listItems = this.listItems.concat(result.items);
        } else {
          this.listItems = result.items;
        }
        this.page = result.page;
        this.totalItemCount = this.page.TotalCount;
        this.totalPage = Math.ceil(this.page.TotalCount / this.page.Size);

        // 필드 검색을 사용하는 경우 필드 선택 옵션 세팅
        if (!append && this.searchFieldAlias.length && !this.fieldSelectOptions.length) {
          var field = result.choiceFields?.find(
            (o) => o.Alias.toLowerCase() == this.searchFieldAlias.toLowerCase()
          );

          if (field != undefined) {
            var keys = field.ChoiceValue.split("\n");
            var values = field.ChoiceValueLang.split("\n");
            this.fieldSelectOptions = [];

            this.fieldSelectOptions.push({
              key: "all",
              value: this.$t("All").toString(),
            });

            for (var i = 0; i < keys.length; i++) {
              this.fieldSelectOptions.push({
                key: keys[i],
                value: values[i],
              });
            }

            // URL에 필드정보가 있으면 해당 필드가 선택된 것으로 표시
            var fieldInfo = this.$route.query.fieldInfo;
            if (fieldInfo != undefined && this.searchFieldAlias) {
              var select = (fieldInfo as string).split("|")[1]?.toLowerCase();
              if (select.length) {
                this.fieldOption =
                  this.fieldSelectOptions.find((o) => o.key.toLowerCase() == select) ??
                  this.fieldSelectOptions[0];
              }
            } else {
              this.fieldOption = this.fieldSelectOptions[0];
            }
          }
        }
      }
      if (append) {
        this.loadMore = false;
      } else {
        this.loading = false;
      }
    });
  }

  // 검색 필드 토글
  toggleSearchField() {
    this.isSearch = !this.isSearch;

    this.updateQueryParam();
  }

  // 검색결과 강조
  highlightText(text: string, type: string): string {
    // 입력 키워드 없음
    if (this.searchKeyword == "" || this.searchKeyword == undefined) return text;

    // 검색 타입 확인
    if (this.searchType.key != "" && type != this.searchType.key.toLowerCase()) return text;

    var keyword = this.searchKeyword;
    // if (!keyword) {
    //   return text;
    // }
    return text.replace(new RegExp(keyword, "gi"), (match) => {
      return "<strong>" + match + "</strong>";
    });
  }

  // 카테고리 class가져오기
  // NOTE: 포탈 클래스를 사용하다보니 하드코딩하여 뱃지 색에 해당하는 클래스를 가져와서 custumizing함..
  getCategoryClass(item: ListItem, i: number) {
    // classes: badge color01↵badge color02 --> 이런 형태로 옴
    var classes = item.fields[i].ChoiceClassValue.split("\n");
    var values = item.fields[i].ChoiceValue.split("\n");
    var selected = item.fields[i].RealValue;

    var index = values.indexOf(selected);

    // 지정된 클래스가 없는 경우 기본 뱃지 사용
    return classes[index] ? classes[index] : "hd-text-nm";
  }

  // 필드 라벨 가져오기
  getCategoryLabel(item: ListItem, i: number) {
    var field = item.fields[i];

    var keys = field.ChoiceValue.split("\n");
    var selected = field.RealValue?.split("\n");
    let valueLang = item.fields[i].ChoiceValueLang.split("\n");

    var values: string[] = [];
    selected?.forEach((select) => {
      if (select.trim().length) {
        let index = keys.indexOf(select);
        values.push(valueLang[index]);
      }
    });

    return values.join(", ");
  }

  @Watch("$route")
  handleBoardIdChanged(): void {
    this.$nextTick(() => {
      if (
        this.$route.name == "Board" &&
        this.boardUrl != this.$route.params.boardUrl
        //this.searchKeyword != this.$route.query.searchKeyword)
      ) {
        this.init();
      }
    });
  }
}
