/** 결재 액션 버튼 */
export enum ButtonCode {
  /**
   * 전환 버튼 ( 워드컨트롤-에디터컨트롤)
   */
  ChangeToOtherForm,
  /**
   * 배부
   */
  SelectAssignMember,
  /**
   * 자신에게 배부
   */
  AssignToSelf,
  /**
   * 배부 취소
   */
  CancelAssign,
  /**
   * 인쇄
   */
  Print,
  /**
   * 문서 전달
   */
  SendDocument,
  /**
   * 시행문 신청
   */
  ApplyEnforceDoc,
  /**
   * 시행문 미리보기
   */
  PreviewEnforceDoc,
  /**
   * 결재선 버튼
   */
  ApvLine,
  /**
   * 수정
   */
  Modify,
  /**
   * 보류 해제
   */
  StopHold,
  /**
   * 의견 추가
   */
  AddComment,
  /**
   * 결재
   */
  Approve,
  /**
   * 동의
   */
  Agree,
  /**
   * 다른 의견
   */
  Disagree,
  /**
   * 반려
   */
  Reject,
  /**
   * 한단계 반려(버튼분류로는 Reject에 포함되고 별도로 사용되지 않음)
   */
  RejectOneStep,
  /**
   * 보류
   */
  Hold,
  /**
   * 결재 요청
   */
  ApvRequest,
  /**
   * 수신 문서 기안 부서로 반려
   */
  RejectToDraftDept,
  /**
   * 다음 결재 라인으로 부터 기안부서로 반려(접수반려)
   */
  RejectFromNextLineToDraftDept,
  /**
   * 의견 조회
   */
  ViewComment,
  /**
   * 결재 기록 조회
   */
  ViewHistory,
  /**
   * 열람
   */
  AddReadPermission,
  /**
   * 회람(부서원에게 알림 메일 발송)
   */
  SendToDeptMember,
  /**
   * 결재 취소
   */
  ApvCancel,
  /**
   * 후열 진행
   */
  AfterReading,
  /**
   * 내용 확인
   *  워드 컨트롤의 이미지 업데이트
   */
  UpdateWordContentImage,
  /**
   * 삭제
   */
  Delete,
  /**
   * 폐기
   */
  Discard,
  /**
   * 폐기 사유
   */
  DiscardComment,
  /**
   * 임시 저장
   */
  TempSave,
  /**
   * 미리보기
   */
  Preview,
  /**
   * 취소
   */
  CancelDraft,
  /**
   * 닫기
   */
  Close,
  /**
   * Copy등록
   */
  CopyToNewApvDoc,
  /**
   * 담당전환
   */
  ChangeCurrentCoReceipt,
  /**
   * 편집전환
   * (관리자용)
   */
  AdminModeTransformEditMode,
  /**
   * 편집적용
   * (관리자용)
   */
  AdminModeEditApply,
  /**
   * 자가 승인
   */
  SelfApprove,
  /**
   * 내려받기 (PDF)
   * !PDF 변환 관련하여 SKCC As-Is 시스템에서
   * 구매한 라이브러리를 사용
   * (ExpertPDF). 그래서 해당 버튼의 구현은 SKCC에서만 사용됨.
   */
  DownloadPdf,
  /**
   * 전사공지
   * 전자결재 전사공지 관련하여
   * 전자결재 이미지 변환을 SKCC As-Is 시스템에서 사용한 dll 이용
   */
  NotifyCompany,
  /**
   * 시스템 결재취소
   * (관리자용 결재취소(1단계 취소) 버튼)
   */
  CancelBySystem,
  /**
   * 점검완료
   */
  UpdateInterfaceStatus,
  /**
   * 기안철회(회수)
   */
  Recall,
  /**
   * 회람 답변
   */
  CircularComment,

  // 이하는 사이트별 예외 버튼 (확장 로직 IApvButtonExt 구현체에서 사용)

  ExtendedButton01 = 101,
  ExtendedButton02 = 102,
  ExtendedButton03 = 103,
  /**
   * 수정의견
   */
  ExtendedButton04 = 104,
  ExtendedButton05 = 105,
  ExtendedButton06 = 106,
  ExtendedButton07 = 107,
  ExtendedButton08 = 108,
  ExtendedButton09 = 109,
  ExtendedButton10 = 110,
}
