import Vue from "vue";
import VueI18n from "vue-i18n";

// json 파일을 읽어들이기 위한 function
const requireLang = require.context("./resources", true, /\.json$/);

const messages = {};

// json file read
for (const file of requireLang.keys()) {
  const path = file.replace(/(\.\/|\.json$)/g, "").split("/");

  path.reduce((o: any, s: any, i: number) => {
    const tempArr = s.split("_");
    // 파일명에서 앱 및 언어 확인. 파일명에서 맨 뒤가 언어
    const lang = tempArr[tempArr.length - 1];
    if (!o[lang]) {
      o[lang] = {};
    }

    o[lang] = i + 1 === path.length ? Object.assign(o[lang], requireLang(file)) : {};
    return o[lang];
  }, messages);
}

Vue.use(VueI18n);

const i18n = new VueI18n({
  locale: "ko", // 기본 locale
  fallbackLocale: "ko", // locale 설정 실패시 사용할 locale
  messages, // 다국어 메시지
  silentTranslationWarn: true, // 메시지 코드가 없을때 나오는 console 경고 off
});

export default i18n;
