import axios from "axios";
import ServiceResult from "@/models/ServiceResult";
import ApiResult from "@/models/ApiResult";
import DocumentPagedListResponse from "@/models/approval/response/DocumentPagedListResponse";
import DocumentResponse from "@/models/approval/response/DocumentResponse";
import DocumentContentResponse from "@/models/approval/response/DocumentContentResponse";
import { CircularListType } from "@/models/approval/CircularListType";
import LineUserContainer from "@/models/approval/LineUserContainer";
import Comment from "@/models/approval/Comment";
import qs from "qs";
import DocumentSearchRequest from "@/models/approval/request/DocumentSearchRequest";
import ModificationOpinion from "@/models/approval/ModificationOpinion";
import DocumentCount from "@/models/approval/DocumentCount";

export default {
  /**
   * 진행중, 대기중 문서 Count 조회
   * @param userId 사용자 ID
   */
  async getDocumentCountAsync(userId: string): Promise<DocumentCount[]> {
    const response = await axios.get<DocumentCount[]>(
      `/SmartTalk/IF/GetDocumentCountHandler.ashx?UserID=${userId}`
    );
    return response.data;
  },
  // NOTE: 아래 web service 사용시 코드와 SP 변수가 일치하지 않아 오류가 발생함
  // export async function getDocumentCountAsync(userId: string): Promise<ServiceResult<DocumentCount>> {
  //   userId = "portaladmin";
  //   const response = await axios.get<ServiceResult<DocumentCount>>(
  //     //`/Apv/api/Apv/v1/DocumentCount?userId=${userId}`
  //     `/SmartTalk/Public/Approval.asmx/GetDocumentCount?UserID=${userId}`
  //   );
  //   return response.data;
  // }

  /**
   * 결재 문서 목록 조회
   */
  async getDocumentListAsync(
    req: DocumentSearchRequest
  ): Promise<ApiResult<DocumentPagedListResponse>> {
    const response = await axios.post<ApiResult<DocumentPagedListResponse>>(
      `/SmartTalk/api/ApvList/v1/PagedDocuments`,
      req
    );
    return response.data;
  },

  /**
   * 결재 문서 조회
   * @param apvId 결재 ID
   * @param langCode 언어 코드
   * @param userId 사용자 ID
   */
  async getDocumentAsync(
    apvId: number,
    langCode: string,
    userId: string
  ): Promise<DocumentResponse> {
    //userId = "portaladmin";
    const response = await axios.get<DocumentResponse>(
      `/SmartTalk/Public/Approval.asmx/GetApproval?apvID=${apvId}&userID=${userId}&langCode=${langCode}`
    );
    return response.data;
  },

  /**
   * 결재 문서 본문 Html, 결재버튼 조회
   * @param apvId 결재 ID
   * @param langCode 언어 코드
   * @param userId 사용자 ID
   */
  async getDocumentHtmlAsync(
    apvId: number,
    langCode: string,
    userId: string
  ): Promise<DocumentContentResponse> {
    //userId = "portaladmin";
    const response = await axios.get<DocumentContentResponse>(
      //`/Apv/api/Apv/v1/DocumentCount?userId=${ApvId}`
      `/SmartTalk/Public/Approval.asmx/GetApprovalContent?apvID=${apvId}&userID=${userId}&langCode=${langCode}`
    );
    return response.data;
  },

  /**
   * 회람 목록 조회
   * @param apvId 결재 ID
   * @param userId 사용자 ID
   */
  async getCircularListAsync(
    apvId: number,
    userId: string
  ): Promise<ServiceResult<CircularListType[]>> {
    //userId = "portaladmin";
    const response = await axios.get<ServiceResult<CircularListType[]>>(
      `/SmartTalk/Public/Approval.asmx/GetCircularList?apvID=${apvId}&userID=${userId}`
    );
    return response.data;
  },

  /**
   * 대결자 정보 조회
   * @param formId 양식 ID
   * @param formVer 양식 버전
   * @param tenantId 회사 코드
   * @param apvLineUserContainerList 결재선 목록
   */
  async getDelegationInfoAsync(
    formId: number,
    formVer: number,
    tenantId: string,
    apvLineUserContainerList: LineUserContainer[]
  ): Promise<any> {
    const data = {
      orderType: "GetDelegationInfo",
      formId: formId,
      formVer: formVer,
      tenantId: tenantId,
      apvLineUserContainerList: JSON.stringify(apvLineUserContainerList),
    };

    // NOTE: DelegateResponse.ashx가 Request.Params로 데이터를 받기 때문에 body로 보내는게 아니라 qs를 이용해 query string으로 변환해서 전달한다.
    const response = await axios.post<any>(
      `/SmartTalk/Main/Response/DelegateResponse.ashx`,
      qs.stringify(data)
    );
    return response.data;
  },

  /**
   * 결재 결과 전송
   * @param apvId 결재 ID
   * @param userId 사용자 ID
   * @param rejectCode rejectCode
   * @param comment 의견
   */
  async setApprovalAsync(
    apvId: number,
    userId: string,
    rejectCode: string,
    comment: string
  ): Promise<ServiceResult<string>> {
    let method: string = "";
    switch (rejectCode) {
      case "0": // 승인
        method = "SetApproveOK";
        break;
      case "1": // 한 단계 반려
        method = "SetApproveCancel";
        break;
      case "2": // 기안 반려
        method = "SetApproveReject";
        break;
      case "3": // 보류
        method = "SetApproveHold";
        break;
      case "4": // 보류해제
        method = "SetApproveHoldOut";
        break;
      case "7": // 결재 철회
        method = "SetApproveCancel";
        break;
      case "a": // 동의(협조)
        method = "SetApproveAgree";
        break;
      case "d": // 다른의견(협조)
        method = "SetApproveDisagree";
        break;
    }

    const response = await axios.get<ServiceResult<string>>(
      `/SmartTalk/Public/Approval.asmx/${method}?apvID=${apvId}&userID=${userId}&comment=${comment}`
    );
    return response.data;
  },

  /**
   * 의견 목록 조회
   * @param apvId 결재 ID
   * @param userId 사용자 ID
   */
  async getCommentList(apvId: number, userId: string): Promise<ServiceResult<Comment[]>> {
    const response = await axios.get<ServiceResult<Comment[]>>(
      `/SmartTalk/Public/Approval.asmx/GetCommentList?apvID=${apvId}&userID=${userId}`
    );
    return response.data;
  },

  /**
   * 수정요청 목록 조회
   * @param apvId
   * @param tenantId
   * @param langCode
   */

  async GetModificationRequests(
    apvId: number,
    tenantId: string,
    langCode: string
  ): Promise<ApiResult<ModificationOpinion[]>> {
    const response = await axios.get<ApiResult<ModificationOpinion[]>>(
      `/SmartTalk/api/Apv/v1/GetModificationRequests?apvID=${apvId}&tenantID=${tenantId}&langCode=${langCode}`
    );
    return response.data;
  },

  /**
   * 수정요청 등록
   * @param apvId 결재 ID
   * @param opinion 수정 요청
   */
  async InsertApvModificationRequest(apvId: number, opinion: string): Promise<ApiResult<void>> {
    const response = await axios.post<ApiResult<void>>(
      `/SmartTalk/api/Apv/v1/InsertModificationRequest`,
      {
        ApvID: apvId,
        Opinion: opinion,
      }
    );
    return response.data;
  },

  /**
   * 수정요청 취소
   * @param apvId 결재 ID
   * @param requestID 수정 요청 ID
   */
  async CancelModificationRequest(apvId: number, requestID: string): Promise<ApiResult<number>> {
    const response = await axios.get<ApiResult<number>>(
      `/SmartTalk/api/Apv/v1/CancelModificationRequest?apvID=${apvId}&requestID=${requestID}`
    );
    return response.data;
  },
};
