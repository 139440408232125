import Vue from "vue";
/** 전역 이벤트를 발생시키는데 사용한다. */
/** 로그인/로그아웃 관련해서는 vuex로 처리하고 있지만 전자결재, 예약은 EventBus 사용 */
export const EventBus = new Vue();

/** EventBus에서 사용하는 이벤트 이름 */
export const EVENT = {
  OPEN_MAKE_RESERVATION: "openMakeReservation",
  REFRESH_RESERVATIONS: "refreshReservations",

  APPROVAL_REFRESH_DETAIL: "approvalRefreshDetail",
};
