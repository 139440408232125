



























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import YellowPageApi from "@/api/yellowPageApi";
import HdSelectBox from "@/components/HdSelectBox.vue";
// model
import KVType from "@/models/common/KVType";
import UserInfo from "@/models/board/UserInfo";

@Component({
  components: {
    HdSelectBox,
  },
})
export default class YellowPage extends Vue {
  @Prop() companyCode!: string;
  private selectedCompany: KVType = { key: "", value: "" } as KVType;
  private langCode = this.$i18n.locale;
  private companies: KVType[] = [];
  private companySelectDialog = false;

  mounted(): void {
    this.setCompanies();
  }

  // 회사 목록
  async setCompanies(): Promise<void> {
    var code = this.companyCode;
    if (code == undefined) {
      code = (this.$store.getters.getCurrentUser as UserInfo).ActivatedTenantId;
    }

    if (this.companies == undefined || this.companies.length == 0) {
      await YellowPageApi.getCompanyListAsync(this.langCode).then((result) => {
        result.forEach((data) => {
          var company: KVType = {
            key: data.CompanyCode,
            value: data.CompanyName,
          };

          this.companies.push(company);
          if (code == company.key) {
            this.selectedCompany = company;
          }
        });

        if (this.selectedCompany.key == undefined || !this.selectedCompany.key.length) {
          this.selectedCompany = this.companies[0];
        }
      });
    }
  }

  handleCompanyChange(selected: KVType) {
    this.selectedCompany = selected;
  }

  @Watch("selectedCompany")
  handleSelectCompanyChanged(newValue: KVType): void {
    this.$router.push({ name: "DeptView", params: { companyCode: newValue.key } }).catch(() => {
      return;
    });
  }
}
