import store from "@/store";
import MobileMenu from "@/models/menu/MobileMenu";
import router from "@/router";

export default {
  /**
   * menuId의 상위 메뉴 중 입력한 level에 해당하는 메뉴 리턴
   * @param level
   * @param menuId 입력값이 없으면 현재 메뉴 기준
   * @returns
   */
  async getParentMenuByLevel(level: number, menuId: string = ""): Promise<MobileMenu | undefined> {
    if (menuId == "") {
      const currentMenu = await this.getCurrentMenu();
      if (!currentMenu) {
        return undefined;
      }

      if (currentMenu.MenuLevel <= level) {
        // 입력한 메뉴가 이미 해당 레벨 이하일 때
        return currentMenu;
      } else {
        const array = currentMenu.Sort.split(">");
        const targetLevel = Math.min(array.length, level);
        // TODO: 데이터에서 메뉴레벨 0과 1이 동일한 값으로 되어있어 0레벨 무시. 왜인지 확인 필요
        const targetMenuId = array[targetLevel].trim();

        return await this.findMenuByMenuId(targetMenuId);
      }
    }
    return undefined;
  },

  /**
   * 입력한 menuId의 n level 위의 부모 메뉴 리턴
   * @param level
   * @param menuId 입력값이 없으면 현재 메뉴 기준
   * @returns
   */
  async getNthParentMenu(level: number, menuId: string = ""): Promise<MobileMenu | undefined> {
    if (menuId == "") {
      const currentMenu = await this.getCurrentMenu();
      if (!currentMenu) {
        return undefined;
      }

      const array = currentMenu.Sort.split(">");
      const targetLevel = array.length - level; // 현재레벨

      if (targetLevel < 0) {
        return undefined;
      }

      const targetMenuId = array[targetLevel].trim();

      return await this.findMenuByMenuId(targetMenuId);
    }
    return undefined;
  },

  /**
   * 경로 url에서 현재 메뉴를 확인한다.
   */
  async getCurrentMenu(): Promise<MobileMenu | undefined> {
    const url = (router as any).history.current.fullPath;
    return await this.findMenuByUrl(url);
  },

  /**
   * 메뉴 ID로 메뉴 정보를 조회한다.
   * @param menuId 메뉴 ID
   */
  async findMenuByMenuId(menuId: string): Promise<MobileMenu | undefined> {
    const menus = await store.dispatch("loadMobileMenu");
    if (menus == undefined) {
      return undefined;
    }

    if (menuId == undefined || menuId == "undefined") {
      return undefined;
    }

    const dummy: MobileMenu = {} as MobileMenu;
    dummy.Children = menus;
    const result = findMenuByMenuIdRecursive(dummy, menuId);

    return result;
  },

  /**
   * 메뉴 URL로 메뉴 정보를 조회한다.
   * @param url 메뉴 URL
   */
  async findMenuByUrl(url: string): Promise<MobileMenu | undefined> {
    const menus = await store.dispatch("loadMobileMenu");
    if (menus == undefined) {
      return undefined;
    }

    const dummy: MobileMenu = {} as MobileMenu;
    dummy.Children = menus;
    const result = findMenuByUrlRecursive(dummy, url);

    return result;
  },

  /**
   * 메뉴 URL로 메뉴 정보를 조회한다. 동일 URL의 메뉴가 하나 이상인 경우 모두 반환한다.
   * @param url 메뉴 URL
   */
  async findAllMenuByUrl(url: string): Promise<MobileMenu[]> {
    const menus = await store.dispatch("loadMobileMenu");
    if (menus == undefined) {
      return [];
    }

    const dummy: MobileMenu = {} as MobileMenu;
    dummy.Children = menus;

    return findAllMenuByUrlRecursive(dummy, url);
  },
};

const findAllMenuByUrlRecursive = (
  menu: MobileMenu,
  url: string,
  result: MobileMenu[] = []
): MobileMenu[] => {
  // NOTE: 맨 앞에 "/"가 있는 경우 제거, 뒤에 파라미터가 있는 경우 제거
  const menuUrl = menu.Url?.replace(/^\//, "").split("?")[0].trim();
  const targetUrl = url.replace(/^\//, "").split("?")[0].trim();

  if (menuUrl?.toLocaleLowerCase() == targetUrl.toLocaleLowerCase()) {
    result.push(menu);
  }

  if (menu.Children.length == 0) {
    return result;
  }

  menu.Children.forEach((child) => {
    findAllMenuByUrlRecursive(child, targetUrl, result);
  });

  // 문법상으로는 필요하지만 논리적으로 이 위치까지 코드가 실행되지 않는다.
  return result;
};

const findMenuByUrlRecursive = (menu: MobileMenu, url: string): MobileMenu | undefined => {
  // NOTE: 맨 앞에 "/"가 있는 경우 제거, 뒤에 파라미터가 있는 경우 제거
  const menuUrl = menu.Url?.replace(/^\//, "").split("?")[0].trim();
  const targetUrl = url.replace(/^\//, "").split("?")[0].trim();

  if (menuUrl?.toLocaleLowerCase() == targetUrl.toLocaleLowerCase()) {
    return menu;
  }

  if (menu.Children.length == 0) {
    return undefined;
  }

  let found: MobileMenu | undefined = undefined;
  menu.Children.forEach((child) => {
    const result = findMenuByUrlRecursive(child, targetUrl);
    if (result != undefined) {
      found = result;
    }
  });
  return found;
};

const findMenuByMenuIdRecursive = (menu: MobileMenu, menuId: string): MobileMenu | undefined => {
  if (menu?.MenuId?.trim() == menuId?.trim()) {
    return menu;
  }

  if (!menu.Children || menu.Children.length == 0) {
    return undefined;
  }

  let found: MobileMenu | undefined = undefined;
  menu.Children.forEach((child) => {
    const result = findMenuByMenuIdRecursive(child, menuId);
    if (result != undefined) {
      found = result;
    }
  });

  return found;
};
