










































import { Component, Vue } from "vue-property-decorator";
import MenuHandler from "@/common/menuHandler";
import MobileApi from "@/api/mobileApi";
import UserInfo from "@/models/board/UserInfo";
import MobileMenu from "@/models/menu/MobileMenu";
// component
import HdLoadingMask from "@/components/HdLoadingMask.vue";

@Component({
  components: {
    HdLoadingMask,
  },
})
export default class Front extends Vue {
  private user: UserInfo = {} as UserInfo;
  private favoriteMenus: MobileMenu[] = [];
  private loading: boolean = true;

  mounted() {
    this.user = this.$store.getters.getCurrentUser;

    MobileApi.getMobileFavoriteMenuListAsync(this.user.UserID).then(async (result) => {
      if (result.Success) {
        for (let i = 0; i < result.Data.length; i++) {
          let item = result.Data[i];
          var menuId = item.MenuId;
          if (menuId == undefined) {
            continue;
          }
          var mobileMenu = await MenuHandler.findMenuByMenuId(menuId);

          if (mobileMenu) {
            var favorite: MobileMenu = {} as MobileMenu;
            Object.assign(favorite, mobileMenu);
            favorite.LongName = item.Name;
            this.favoriteMenus.push(favorite);
          }
          this.loading = false;
        }
      }
    });
  }

  moveToMenu(menu: MobileMenu): void {
    // 즐겨찾기 메뉴가 하위 메뉴를 포함하는 경우, 말단 노드를 찾아 이동시켜준다.
    var target: MobileMenu = {} as MobileMenu;
    Object.assign(target, menu);
    while (target.Children != undefined && target.Children.length) {
      target = target.Children[0];
    }

    if (!target.Url.startsWith("/")) {
      target.Url = `/${target.Url}`;
    }

    this.$router.push({ path: target.Url });
  }

  goBack() {
    if (history.length && history.state != null) {
      this.$router.go(-1);
    } else {
      this.$router.replace({ name: "Menu" });
    }
  }
}
