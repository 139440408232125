

























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import DATEHANDLER from "@/common/dateHandler";
import KVType from "@/models/common/KVType";

const ITEM_HEIGHT = 44;
const INCREASE_UNIT = 30;

@Component
export default class HdDatePicker extends Vue {
  @Prop({ default: new Date() }) initDate!: Date;
  private dates: KVType[] = [];
  private selectedIndex: number = 0;
  private thumbStyle = {
    backgroundColor: "tranparent",
    width: "0",
    opacity: 0,
  };

  private selectTimerOn: boolean = false;

  mounted() {
    var date = new Date(this.initDate);
    this.dates = [];

    // 초기값 3일 전부터 세팅
    date.setDate(date.getDate() - 3);
    for (var i = 0; i < INCREASE_UNIT; i++) {
      this.dates.push({ key: date.toString(), value: this.getDateString(date) });
      date.setDate(date.getDate() + 1);
    }

    this.selectedIndex = 3;
    //var refinedExact = this.selectedIndex * ITEM_HEIGHT - ITEM_HEIGHT * 2;
    //(this.$refs.target as any).setScrollPosition(refinedExact, 50);
  }

  onScroll(info: any) {
    // NOTE: 높이가 정확히 설정되기 전이면 추가 액션을 하지 않는다.
    // 누락시 스크롤 자동 세팅 중간에 액션이 발생해 이상한 날짜가 찍힘
    var correctHeight = ITEM_HEIGHT * this.dates.length;
    if (info.verticalSize < correctHeight) {
      return;
    }

    var index = Math.round((info.verticalPosition + ITEM_HEIGHT * 2) / ITEM_HEIGHT);
    var exact = index * ITEM_HEIGHT - ITEM_HEIGHT * 2;

    if (index != this.selectedIndex && info.position != exact) {
      // if (Math.abs(this.selectedIndex - index) > 5) {
      //   return;
      // }
      // 거의 끝에 오면 날짜 추가
      if (index > this.dates.length - 4) {
        let date = new Date(this.dates[this.dates.length - 1].key);
        for (let i = 0; i < INCREASE_UNIT; i++) {
          this.dates.push({ key: date.toString(), value: this.getDateString(date) });
          date.setDate(date.getDate() + 1);
        }

        this.selectedIndex = index;
      }
      // 거의 처음에 오면 날짜 추가
      else if (index < 4) {
        let date = new Date(this.dates[0].key);
        date.setDate(date.getDate() - INCREASE_UNIT);
        var earlierDate: KVType[] = [];
        for (let i = 0; i < INCREASE_UNIT; i++) {
          earlierDate.push({ key: date.toString(), value: this.getDateString(date) });
          date.setDate(date.getDate() + 1);
        }
        this.dates = earlierDate.concat(this.dates);

        // 앞쪽 index가 바뀌었으므로 현재 위치 다시 설정
        this.selectedIndex = index + INCREASE_UNIT + 1;
      } else {
        this.selectedIndex = index;
      }

      var refinedExact = this.selectedIndex * ITEM_HEIGHT - ITEM_HEIGHT * 2;
      (this.$refs.target as any).setScrollPosition(refinedExact, 50);
    }
  }

  getDateString(date: Date): string {
    return DATEHANDLER.dateToStringMultiLang(date, this.$i18n.locale);
  }

  selectDate(index: number) {
    if (this.selectedIndex != index) {
      return;
    }
    var date = new Date(this.dates[index].key);
    this.$emit("select", date, true);
  }

  // 날짜가 바뀌면 바로바로 상위 컴포넌트에 적용한다.
  // 다만, 스크롤중이면 너무 많은 이벤트가 발생할 수 있으므로 1초에 한 번만 업데이트 되도록 한다.
  @Watch("selectedIndex")
  handleSelectChanged() {
    if (this.selectTimerOn) {
      return;
    }

    this.selectTimerOn = true;
    setTimeout(() => {
      var date = new Date(this.dates[this.selectedIndex].key);
      this.$emit("select", date, false);
      this.selectTimerOn = false;
    }, 1000);
  }
}
