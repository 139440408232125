
























import { Component, Prop, Vue } from "vue-property-decorator";
// model
import KVType from "@/models/common/KVType";

@Component
export default class HdSelectBox extends Vue {
  @Prop() options!: KVType[];
  @Prop() initOption!: KVType;

  changeOption(option: KVType): void {
    this.$emit("change", option);
  }
}
