import axios from "axios";
import ApiResult from "@/models/ApiResult";
import Building from "@/models/reservation/Building";
import Floor from "@/models/reservation/Floor";
import Room from "@/models/reservation/Room";
import GetAvailableRoomReuest from "@/models/reservation/request/GetAvailableRoomReuest";
import MakeReservationRequest from "@/models/reservation/request/MakeReservationRequest";
import MyReservationListRequest from "@/models/reservation/request/MyReservationListRequest";
import MeetingRoomReservationType from "@/models/reservation/MeetingRoomReservationType";
import ReservationInfoResponse from "@/models/reservation/response/ReservationInfoResponse";
import ReservationBriefResponse from "@/models/reservation/response/ReservationBriefResponse";

export default {
  /**
   * 사옥 목록 조회
   */
  async getBuildings(): Promise<ApiResult<Building[]>> {
    const response = await axios.post<ApiResult<Building[]>>(
      `/Portal/api/v1/Reservation/GetBuildings`
    );

    return response.data;
  },

  /**
   * 사옥에 해당하는 층 목록 조회
   * @param buildingId 사옥 ID
   * @param categoryID 카테고리 ID
   */
  async getBuildingFloors(buildingId: number, categoryID: string): Promise<ApiResult<Floor[]>> {
    const response = await axios.post<ApiResult<Floor[]>>(
      `/Portal/api/v1/Reservation/GetBldgFloor?buildingID=${buildingId}&systemType=MEETING&categoryID=${categoryID}`
    );

    return response.data;
  },

  /**
   * 사옥과 층에 해당하는 회의실 목록 조회
   * @param buildingId 사옥 ID
   * @param floorId 층 ID
   * @param categoryID 카테고리 ID
   */
  async getRooms(
    buildingId: number,
    floorId: number | null,
    categoryID: string
  ): Promise<ApiResult<Room[]>> {
    let floorType: string = "";
    if (floorId == null) {
      // floorType = "&floorType=All";
      floorType = "All";
    }

    const response = await axios.post<ApiResult<Room[]>>(
      `/Portal/api/v1/Reservation/GetRoomList?&buildingID=${buildingId}&floorID=${
        floorId ?? -999
      }&categoryID=${categoryID}&floorType=${floorType}`
    );

    return response.data;
  },

  /**
   * 해당 조건에 예약 가능한 회의실 목록 조회
   */
  async getAvailableRooms(request: GetAvailableRoomReuest): Promise<ApiResult<Room[]>> {
    const response = await axios.post<ApiResult<Room[]>>(
      `/Portal/api/v1/Reservation/GetAvailableRoom`,
      request
    );

    return response.data;
  },

  /**
   * 해당 회의실 / 해당 날짜에 예약된 내역 조회
   * @param roomID 회의실 ID
   * @param date 날짜
   * @param getUserType 회의실 예약에 대한 현재 사용자 타입["User" | "Attendant" | "Cc" | "None"] 조회 여부
   */
  async getRoomReservations(
    roomID: number,
    date: string,
    getUserType: boolean
  ): Promise<ApiResult<ReservationBriefResponse[]>> {
    const response = await axios.post<ApiResult<ReservationBriefResponse[]>>(
      `/Portal/api/v1/Reservation/GetRoomReservations?roomID=${roomID}&date=${date}&getUserType=${getUserType}`
    );

    return response.data;
  },

  /**
   * 회의실 예약
   */
  async makeReservation(request: MakeReservationRequest): Promise<ApiResult<number>> {
    const response = await axios.post<ApiResult<number>>(
      `/Portal/api/v1/Reservation/MakeReservation`,
      request
    );
    return response.data;
  },

  /**
   * 내 예약내역 조회
   */
  async getMyReservationList(
    request: MyReservationListRequest
  ): Promise<ApiResult<MeetingRoomReservationType[]>> {
    const response = await axios.post<ApiResult<MeetingRoomReservationType[]>>(
      `/Portal/api/v1/Reservation/MyReservations`,
      request
    );
    return response.data;
  },

  /**
   * 예약정보 조회
   * @param reservationId 회의실 예약 ID
   */
  async getReservation(reservationId: number): Promise<ApiResult<ReservationInfoResponse>> {
    const response = await axios.post<ApiResult<ReservationInfoResponse>>(
      `/Portal/api/v1/Reservation/GetReservation?reservationID=${reservationId}`
    );
    return response.data;
  },

  /**
   * 예약 삭제
   */
  async deleteReservation(reservationId: number): Promise<ApiResult<boolean>> {
    const response = await axios.post<ApiResult<boolean>>(
      `/Portal/api/v1/Reservation/DeleteReservation?reservationID=${reservationId}`
    );
    return response.data;
  },

  /**
   * 예약 수정
   */
  async updateReservation(request: MakeReservationRequest): Promise<ApiResult<boolean>> {
    const response = await axios.post<ApiResult<boolean>>(
      `/Portal/api/v1/Reservation/UpdateReservation`,
      request
    );
    return response.data;
  },

  /**
   * 마지막 예약
   */
  async getLastReservation(): Promise<ApiResult<MeetingRoomReservationType>> {
    const response = await axios.post<ApiResult<MeetingRoomReservationType>>(
      `/Portal/api/v1/Reservation/LastReservation?systemCode=MEETING`
    );
    return response.data;
  },
};
