






















































import { Component, Vue, Prop } from "vue-property-decorator";
import User from "@/models/yellowPage/User";
import scroll from 'quasar/src/utils/scroll.js';;

@Component({
  components: {
    MemberDetail: () => import("./MemberDetail.vue"),
  },
})
export default class MemberList extends Vue {
  @Prop() members!: User[];
  @Prop() searchOption!: string;
  @Prop() searchKeyword!: string;
  @Prop() loading!: boolean;
  private selectedMember: User = {} as User;
  private detailDialog: boolean = false;

  viewDetail(member: User): void {
    this.selectedMember = member;
    this.detailDialog = true;
  }

  closeDetaildialog(): void {
    this.detailDialog = false;
  }

  // 무한 스크롤 적용
  onScroll() {
    var item = scroll.getScrollTarget(this.$root.$el);
    var scrollHeight = scroll.getScrollHeight(item);
    var maxOffset = scrollHeight - (item as Window).outerHeight;
    var offset = scroll.getScrollPosition(item);

    if (offset > 0.9 * maxOffset) {
      this.$emit("loadMore");
    }
  }

  // 검색결과 강조
  hilightText(text: string, type: string): string {
    // 입력 키워드 없음
    if (this.searchKeyword == "" || this.searchKeyword == undefined) return text;

    // 검색 타입 확인
    if (this.searchOption != "ALL" && type != this.searchOption.toUpperCase()) return text;

    var keyword = this.searchKeyword;
    if (!keyword) {
      return text;
    }
    return text.replace(new RegExp(keyword, "gi"), (match) => {
      return "<strong>" + match + "</strong>";
    });
  }
}
