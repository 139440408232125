import axios from "axios";
import ApiResult from "@/models/ApiResult";
import MessageSearchRequest from "@/models/notification/request/MessageSearchRequest";
import MessageListResponse from "@/models/notification/response/MessageListResponse";

export default {
  /**
   * 알림센터 안 읽은 메시지 카운트 조회
   */
  async getUnreadCountAsync(): Promise<ApiResult<number>> {
    const response = await axios.get<ApiResult<number>>(`/Notification/api/v1/UnreadCount`);

    return response.data;
  },

  /**
   * 알림센터 메시지 목록 조회(검색)
   */
  async getMessageList(request: MessageSearchRequest): Promise<ApiResult<MessageListResponse>> {
    const response = await axios.post<ApiResult<MessageListResponse>>(
      `/Notification/api/v1/List`,
      request
    );

    return response.data;
  },

  /**
   * 알림 읽음 처리
   * @param messageIdxList 읽음처리할 알림 아이디 목록
   */
  async setMessageRead(messageIdxList: number[]): Promise<ApiResult<boolean>> {
    const response = await axios.post<ApiResult<boolean>>(
      `/Notification/api/v1/SetRead`,
      messageIdxList
    );

    return response.data;
  },
};
