


































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import DATEHANDLER from "@/common/dateHandler";
import ApprovalLineUser from "@/models/approval/ApprovalLineUser";
import * as ApvEnum from "@/models/approval/enums/ApvEnums";
import { ApvTypeCode, ProcessStepCurrentStausCode } from "@/models/approval/enums/ApvEnums";

@Component({
  components: {
    ApvModificationOpinionItem: () => import("./ApvModificationOpinionItem.vue"),
  },
})
export default class ApvLineGroupCard extends Vue {
  @Prop() groupUsers!: ApprovalLineUser[];
  @Prop() lineStatus!: ApvEnum.LineStatusCode;
  @Prop() hasApprovalAuth!: boolean;

  private lineApvType: string = "";
  private lineApvTypeCode: string = "";
  private lineStepOrder: string = "";

  private isDelegated: boolean = false;

  mounted() {
    this.lineApvType = this.groupUsers[0].lineApvType;
    this.lineApvTypeCode = this.groupUsers[0].lineApvTypeCode.toString();
    this.lineStepOrder = this.groupUsers[0].lineStepOrder;
  }

  isPassedStep() {
    var notPassed = true;
    // 그룹 내 모든 멤버가 완료해야 passed Step으로 표시
    this.groupUsers.forEach((user) => {
      notPassed = !(
        user.isApproved ||
        user.currentStepStateCode == ApvEnum.CurrentStepStateCode.PassedStep ||
        user.currentStatus == ProcessStepCurrentStausCode.AS_009
      );
      if (notPassed) {
        return false;
      }
    });
  }

  getDateAsYYYYMMDDHHmm(date: string): string {
    return DATEHANDLER.dateAsYYYYMMDDHHmm(date, this.$i18n.locale);
  }

  // 결재 사용자가 각각 개인|부서|그룹인 경우에 대한 표시 방법
  getLineUserDisplayName(lineUser: ApprovalLineUser): string {
    switch (lineUser.lineStepType) {
      case ApvEnum.LineStepType.User:
        return `${lineUser.lineUserName.length ? lineUser.lineUserName : "&nbsp"}`;
      case ApvEnum.LineStepType.Dept:
        return `${lineUser.lineDeptName ? lineUser.lineDeptName : "&nbsp"}`;
      case ApvEnum.LineStepType.ApvRoleGroup:
        return `${lineUser.lineRoleGroupName ? lineUser.lineRoleGroupName : "&nbsp"}`;
    }
  }

  currentStepStateClass(): string {
    if (this.isCurrentStep()) {
      return "hd-apv-line-card__current";
    } else {
      return "hd-apv-line-card__passed";
    }
  }

  isCurrentStep() {
    var isCurrentStep = false;
    // 멤버중 한 명이 진행상태이면 이 그룹 전체가 진행상태인 것
    this.groupUsers.forEach((user) => {
      if (user.currentStepStateCode == ApvEnum.CurrentStepStateCode.CurrentStep) {
        isCurrentStep = true;
      }
    });

    return isCurrentStep;
  }

  getApvTypeClass(): string {
    return `hd-apv-line-card__${this.lineApvTypeCode.replace(".", "_")}`;
  }

  statusString(user: ApprovalLineUser) {
    var apvStatusText = "";
    switch (user.lineApvTypeCode) {
      case ApvTypeCode.ATY_001:
        apvStatusText = user.isApproved ? this.$t("LineApvStatusLabel_Approved").toString() : ""; // this.$t("LineApvStatusLabel_BeforeApproved").toString(); // 결재 : 미결
        break;
      case ApvTypeCode.ATY_003:
        apvStatusText = user.isApproved
          ? this.$t("LineApvStatusLabel_ArbitraryDecision").toString()
          : ""; //this.$t("LineApvStatusLabel_BeforeApproved").toString(); // 전결 : 미결
        break;
      case ApvTypeCode.ATY_004:
      case ApvTypeCode.ATY_005:
        apvStatusText = user.isApproved
          ? user.currentStatus == ProcessStepCurrentStausCode.AS_013
            ? this.$t("LineApvStatusLabel_OtherCommentCooperated").toString()
            : this.$t("LineApvStatusLabel_Cooperated").toString() // 협조(다른의견) : 협조
          : ""; // this.$t("LineApvStatusLabel_BeforeCooperated").toString(); // 미협조
        break;
      case ApvTypeCode.ATY_006:
        apvStatusText = user.isApproved ? this.$t("LineApvStatusLabel_Review").toString() : ""; //this.$t("LineApvStatusLabel_BeforeReview").toString(); // 검토 : 미검토
        break;
      case ApvTypeCode.ATY_007:
        apvStatusText = this.$t("LineApvStatusLabel_AfterRead").toString(); // 후열
        break;
      case ApvTypeCode.ATY_008:
        apvStatusText = user.isApproved ? this.$t("LineApvStatusLabel_Draft").toString() : ""; // 기안
        break;
      case ApvTypeCode.ATY_009:
        apvStatusText = user.isApproved ? this.$t("LineApvStatusLabel_Approved").toString() : ""; // 결재 : 미접수
        break;
      case ApvTypeCode.ATY_012:
        apvStatusText = user.isApproved
          ? user.currentStatus == ProcessStepCurrentStausCode.AS_013
            ? this.$t("LineApvStatusLabel_DisAgreement").toString()
            : this.$t("LineApvStatusLabel_Agreement").toString() // 불합의 : 합의
          : ""; // 미합의
        break;
    }

    if (
      user.currentStatus == ProcessStepCurrentStausCode.AS_003 ||
      user.currentStatus == ProcessStepCurrentStausCode.AS_004 ||
      user.currentStatus == ProcessStepCurrentStausCode.AS_011
    ) {
      apvStatusText = this.$t("LineApvStatusLabel_Reject").toString(); // 반려
      //styleClassName = "apvStatusRejected";
    } else if (user.currentStatus == ProcessStepCurrentStausCode.AS_010) {
      apvStatusText = this.$t("LineApvStatusLabel_Cancel").toString(); // 결재취소
    }

    return apvStatusText;
  }
}
