























































































































import { Component, Vue, Prop } from "vue-property-decorator";
// Apis
import ApprovalApi from "@/api/approvalApi";
import DATEHANDLER from "@/common/dateHandler";
import UserInfo from "@/models/board/UserInfo";
import ModificationOpinion from "@/models/approval/ModificationOpinion";
// Models
@Component({
  components: {
    HdConfirm: () => import("@/components/HdConfirm.vue"),
    HdAlternativeMessage: () => import("@/components/HdAlternativeMessage.vue"),
    HdSpinnerDots: () => import("@/components/HdSpinnerDots.vue"),
  },
})
export default class ModificationOpinionPopup extends Vue {
  // 승인 권한이 있어야 의견 작성/취소가 가능하다.
  @Prop() hasApprovalAuth!: boolean;
  // 의견 작성 가능 여부
  private writable: boolean = false;
  private opinion: string = "";
  private modificationOpinions: ModificationOpinion[] = [];
  private loading: boolean = true;
  private cancelConfirmDialog: boolean = false;

  private stateChange: boolean = false;

  mounted() {
    this.loadModificationRequests();
  }

  // 수정의견 목록 조회
  loadModificationRequests() {
    var sessionUser = this.$store.getters.getCurrentUser as UserInfo;
    this.loading = true;
    ApprovalApi.GetModificationRequests(
      Number(this.$route.params.apvId),
      sessionUser.ActivatedTenantId,
      sessionUser.UserLangCode
    )
      .then((result) => {
        if (result.Success) {
          this.modificationOpinions = result.Data;

          // 승인 권한이 있고, 진행중이 요청이 없어야 작성가능
          if (
            this.hasApprovalAuth &&
            this.modificationOpinions.findIndex((o) => o.RequestState == "Request") == -1
          ) {
            this.writable = true;
          } else {
            this.writable = false;
          }
        }
        this.loading = false;
      })
      .catch((e) => {
        this.$log.error(e);
        this.loading = false;
      });
  }

  getModificationRequestStatusClass(request: ModificationOpinion) {
    switch (request.RequestState) {
      case "Request":
        return "hd-apv-line-card__modiOpinion__requested";
      case "Canceled":
        return "hd-apv-line-card__modiOpinion__canceled";
      case "Completed":
        return "hd-apv-line-card__modiOpinion__completed";
    }
  }

  // 수정요청 취소
  handleCancelDecision(confirm: boolean) {
    if (confirm) {
      var requestID = this.modificationOpinions.find((o) => o.RequestState == "Request")?.RequestID;
      if (requestID) {
        ApprovalApi.CancelModificationRequest(Number(this.$route.params.apvId), requestID).then(
          (result) => {
            if (result.Success) {
              this.opinion = "";
              this.loadModificationRequests();
              this.stateChange = true;
            }
          }
        );
      }
    }
  }

  // 수정 요청 등록
  handleSubmit() {
    if (!this.opinion.length) {
      this.setFocus();
      return;
    }

    ApprovalApi.InsertApvModificationRequest(Number(this.$route.params.apvId), this.opinion).then(
      (result) => {
        if (result.Success) {
          this.opinion = "";
          this.loadModificationRequests();
          this.stateChange = true;
        }
      }
    );
  }

  getMotificationRequestStatusLang(request: ModificationOpinion) {
    switch (request.RequestState) {
      case "Request":
        return this.$t("ModificationRequest_State_Request").toString();
      case "Canceled":
        return this.$t("ModificationRequest_State_Canceled").toString();
      case "Completed":
        return this.$t("ModificationRequest_State_Completed").toString();
    }
  }

  // 취소/닫기 버튼
  handleClose() {
    this.$emit("closePopup", this.stateChange);
  }

  // 내용 입력창에 포커스
  setFocus() {
    (this.$el.querySelector("textarea") as HTMLTextAreaElement).focus();
  }

  getDateAsYYYYMMDDHHmm(date: string): string {
    return DATEHANDLER.dateAsYYYYMMDDHHmm(date, this.$i18n.locale);
  }
}
