














































import { Component, Vue, Prop } from "vue-property-decorator";
import { EventBus, EVENT } from "@/common/eventBus";
import DATEHANDLER from "@/common/dateHandler";
import MeetingRoomReservationType from "@/models/reservation/MeetingRoomReservationType";

@Component({
  components: {
    ReservationInfoView: () => import("../ReservationInfoView.vue"),
  },
})
export default class ReservationResultCard extends Vue {
  @Prop() reservation!: MeetingRoomReservationType;
  @Prop({ default: false }) displayState!: boolean;
  @Prop({ default: false }) displayUserType!: boolean;
  @Prop() userType!: "User" | "Attendant" | "Cc" | "None";

  private openInformationPopup: boolean = false;
  private className: string = "";
  private badgeLable: string = "";

  mounted() {
    if (this.displayState) {
      switch (this.reservation.ProcessState) {
        case "B":
          // 접수 완료
          this.className = "hd-reservation-result__completed";
          this.badgeLable = this.$t("ReservationProcessTypeB").toString();
          break;
        case "A":
          // 접수중
          this.className = "hd-reservation-result__inprogress";
          this.badgeLable = this.$t("ReservationProcessTypeA").toString();
          break;
        case "C":
          // 접수 불가
          this.className = "hd-reservation-result__unavailable";
          this.badgeLable = this.$t("ReservationProcessTypeC").toString();
          break;
        default:
          this.className = "hd-reservation-result__none";
      }
    } else if (this.displayUserType) {
      switch (this.userType) {
        case "User":
          // 내 예약
          this.className = "hd-reservation-result__my";
          this.badgeLable = this.$t("ReservationTypeMy").toString();
          break;
        case "Attendant":
          // 참석자인 예약
          this.className = "hd-reservation-result__attendant";
          this.badgeLable = this.$t("ReservationTypeAttandent").toString();
          break;
        case "Cc":
          // 참조자인 예약
          this.className = "hd-reservation-result__cc";
          this.badgeLable = this.$t("ReservationTypeReferrer").toString();
          break;
        case "None":
        default:
          this.className = "hd-reservation-result__none";
      }
    }
  }

  getTimeBetweenStringFormat() {
    var from = new Date(this.reservation.ResStartDatetime);
    var to = new Date(this.reservation.ResEndDatetime);
    return `${DATEHANDLER.leftpad(from.getHours(), 2)}:${DATEHANDLER.leftpad(
      from.getMinutes(),
      2
    )} ~ ${DATEHANDLER.leftpad(to.getHours(), 2)}:${DATEHANDLER.leftpad(to.getMinutes(), 2)}`;
  }

  handleClose(isDeleted: boolean) {
    if (isDeleted) {
      EventBus.$emit(EVENT.REFRESH_RESERVATIONS);
    }

    this.openInformationPopup = false;
  }
}
