
























































































































































































































import { Component, Vue } from "vue-property-decorator";
import { mapActions } from "vuex";
import MenuHandler from "@/common/menuHandler";
import DATEHANDLER from "@/common/dateHandler";
import UTILS from "@/common/utilities";
// apis
import BoardApi from "@/api/boardApi";
import ApprovalApi from "@/api/approvalApi";
import NofificationApi from "@/api/noficationApi";
// models
import UserInfo from "@/models/board/UserInfo";
import ListItem from "@/models/board/ListItem";
// components
import HdLoadingMask from "@/components/HdLoadingMask.vue";
import { ApvAdvancedSearchListType } from "@/models/approval/enums/ApvEnums";
import ItemSearchOption from "@/models/board/ItemSearchOption";
import MobileMenu from "@/models/menu/MobileMenu";
import CrawledData from "@/models/board/CrawledData";
import DocumentSearchRequest from "@/models/approval/request/DocumentSearchRequest";

type Menu = {
  Name: {
    ko: string;
    en: string;
  };
  IconUrl: string;
  MenuUrl: string;
};

@Component({
  components: {
    HdLoadingMask,
  },
  methods: {
    ...mapActions(["loadConfigs"]),
  },
})
export default class Front extends Vue {
  private loadConfigs!: () => Promise<any>;
  private user: UserInfo = {} as UserInfo;
  private portalNotiCount: number = 0;
  private apvNotiCount: number = 0;
  private listItems: ListItem[] = [];
  private menuList: Menu[] = [];
  // 게시글 목록 첫 번째 게시글의 썸네일 존재 여부
  private hasThumbnail: boolean = false;

  // 게시글 목록 표시 정보
  private useBoard: boolean = false;
  private boardType: "board" | "crawling" | null = null;
  private boardTitle = "";
  private source = "";
  private maxCount: number = 5;

  private refreshing: boolean = false;
  private loading: boolean = false;

  mounted() {
    this.user = this.$store.getters.getCurrentUser;
    this.setUnreadCount();
    this.setApprvalWaitingCount();

    this.applyFrontConfig().then(this.loadBoardItems);
  }

  // 메인화면 설정 적용
  async applyFrontConfig() {
    var frontConfig = (await this.loadConfigs()).front;
    this.menuList = [];
    for (var i = 0; i < frontConfig.links.length; i++) {
      this.menuList.push(frontConfig.links[i] as Menu);
    }

    this.useBoard = frontConfig.board.use;
    if (this.useBoard) {
      this.boardType = frontConfig.board.type;
      this.maxCount = frontConfig.board.maxCount;
      this.source = frontConfig.board.source;
      this.boardTitle = frontConfig.board.title;
    }
  }

  // 안 읽은 알림 Count 조회
  async setUnreadCount() {
    return await NofificationApi.getUnreadCountAsync()
      .then((result) => {
        if (result.Success) {
          this.portalNotiCount = result.Data;
        } else {
          this.$log.debug("[front/setUnreadCount]", result.Message);
        }
      })
      .catch((e) => {
        this.$log.debug(e);
      });
  }

  // 결재할 문서 Count 조회
  async setApprvalWaitingCount() {
    // NOTE: 이 API는 실제 COUNT와 맞지 않음.
    // return await ApprovalApi.getDocumentCountAsync(this.user.UserID)
    //   .then((result) => {
    //     this.apvNotiCount = result.Items.find((o) => o.Category == "Waiting")?.Count ?? 0;
    //   })
    //   .catch((e) => {
    //     this.$log.debug(e);
    //   });

    // 현대엘리베이터와 그 외로 구분됨
    var listType = ApvAdvancedSearchListType.ListDocForApproval;
    if (this.user.ActivatedTenantId.toUpperCase() == "HEL") {
      listType = ApvAdvancedSearchListType.WaitingApproval;
    }

    var request: DocumentSearchRequest = {
      ListType: listType,
      TenantID: this.user.ActivatedTenantId,
      UserID: this.user.UserID,
      LangCode: this.user.UserLangCode,
      DeptID: this.user.DeptID,
      Size: 1,
    } as DocumentSearchRequest;

    try {
      var result = await ApprovalApi.getDocumentListAsync(request);
      if (result.Success) {
        this.apvNotiCount = result.Data.TotalCount;
      }
    } catch (e) {
      this.$log.error(e);
    }
  }

  async loadBoardItems() {
    if (!this.useBoard) {
      return;
    }

    if (this.boardType == "board") {
      /** 게시판을 사용하는 경우 */
      var option: ItemSearchOption = {
        page: 1,
        size: this.maxCount,
        type: "",
        keyword: "",
        thumbSize: "MOBILE",
      };
      return await BoardApi.getBoardItemListAsync(this.source, option)
        .then((result) => {
          this.listItems = result.items;
          if (result.items?.length == 0) {
            this.hasThumbnail = result.items[0].thumbnail.length ? true : false;
          }
          this.loading = false;
        })
        .catch((e) => {
          this.$log.error(e);
          this.loading = false;
        });
    } else if (this.boardType == "crawling") {
      /** 크롤링 데이터를 사용하는 경우 */
      this.loading = true;
      return await BoardApi.getCrawlingData(this.source)
        .then(async (result) => {
          if (result.Success) {
            this.listItems = [];

            for (let i = 0; i < result.Data.length; i++) {
              var item = result.Data[i];
              if (this.listItems.length == 0) {
                this.hasThumbnail = item.HasThumbnail;
              }

              var fieldInfo: string | null = null;

              var mobileMenu = await MenuHandler.findMenuByUrl(`Board/${item.BoardUrl}`);
              if (mobileMenu != undefined) {
                // NOTE: 무벡스 전용. 조회된 게시글에 대한 사용자의 게시판 권한을 확인하게 함
                if (this.user.ActivatedTenantId.toUpperCase() == "HMX" && item.GubunValue.length) {
                  var menus = await MenuHandler.findAllMenuByUrl(`Board/${item.BoardUrl}`);
                  var filter = this.isAvailableItem(menus, item);
                  if (filter == false) {
                    continue;
                  } else if (typeof filter === "string") {
                    fieldInfo = filter;
                  }
                }

                this.listItems.push({
                  boardId: item.BoardId,
                  boardUrl: item.BoardUrl,
                  itemId: item.ItemId,
                  important: item.NoticeYN == "Y" ? true : false,
                  subject: item.Title,
                  userName: item.WriterName,
                  deptName: item.WriterDept,
                  created: item.WrittenDate,
                  thumbnail: UTILS.convertToCurrentDomainUrl(item.ThumbnailUrl),
                  fieldInfo: fieldInfo,
                } as ListItem);
                if (this.listItems.length >= this.maxCount) {
                  break;
                }
              }
            }
          }
          this.loading = false;
        })
        .catch((e) => {
          this.$log.error(e);
          this.loading = false;
        });
    }
  }

  // NOTE: 무벡스 전용. 조회된 게시글에 대한 사용자의 권한을 확인하게 함
  isAvailableItem(menus: MobileMenu[], item: CrawledData): boolean | string {
    var params: any = {};
    // 동일 URL 메뉴가 여러개인 경우 최대 권한으로 체크. 현실적으로는 fieldInfo 값으로 확인
    for (var i = 0; i < menus.length; i++) {
      var menu = menus[i];
      // 필터가 걸리지 않은 메뉴가 하나라도 있는 사용자는 더이상 검사하지 않음
      if (menu.Url.split("?").length == 1) {
        return true;
      } else {
        menu.Url.replace(/[?&]+([^=&]+)=([^&]*)/gi, (str: string, key: string, value: string) => {
          if (value == undefined || value == "undefined") return "";
          if (params[key] == undefined) {
            params[key] = [];
          }
          params[key].push(value);
          return "";
        });
      }
    }

    var values = item.GubunValue?.split("\n");
    if (values != undefined && params.fieldInfo != undefined) {
      for (var j = 0; j < params.fieldInfo.length; j++) {
        var target = params.fieldInfo[j].split(/\||%7C/gi);
        if (target.length > 1 && values.indexOf(target[1]) > -1) {
          return params.fieldInfo[j];
        }
      }
      // 구분값도 있고, fieldFilter도 있는데 일치하는 정보가 없으면 조회 불가
      return false;
    }
    return true;
  }

  getDateYYYYMMDD(date: string): string {
    return DATEHANDLER.strToYYYYMMDD(date, this.$i18n.locale);
  }

  viewBoardItem(item: ListItem) {
    this.$router.push({
      name: `ItemView`,
      params: { boardUrl: this.boardType == "board" ? this.source : item.boardUrl },
      query: { boardId: item.boardId, itemId: item.itemId, fieldInfo: item.fieldInfo },
    });
  }

  viewAll() {
    if (this.boardType == "board") {
      this.moveTo("board", this.source);
    } else {
      this.$router.push({
        name: "CrawledList",
      });
    }
  }

  async moveToApproval() {
    var config = await this.loadConfigs();
    var apvMenu = await MenuHandler.findMenuByMenuId(config.approval.menuId);
    if (apvMenu != undefined && apvMenu.Children.length) {
      this.$router.push(apvMenu.Children[0].Url);
    } else {
      this.$router.push({
        name: "ApvList",
        params: { listType: ApvAdvancedSearchListType.WaitingApproval },
      });
    }
  }

  moveToNotification() {
    this.$router.push({ name: "Notification" });
  }

  moveTo(type: "board" | "menu", url: string) {
    switch (type) {
      case "board":
        this.$router.push({ path: `board/${url}` });
        return;
      case "menu":
        if (url.includes("http"))
        {
          window.location.href= `${url}?id=`+this.user.EmpID  ;
        }
        else if (url.includes("app://"))
        {
          try {
            console.log(url);
            var app = url.split("://")[1];
            console.log(app);
            switch(app)
            {
              case "qrcode":
                try {
                  window.webkit.messageHandlers.h1mobile.postMessage({ command: "seatReservationQR" });
                } catch (e) {
                  //
                }

                try {
                  window.h1mobile.seatReservationQR();
                } catch (e) {
                  //
                }
            }
          }
          catch { }
        }
        else {
          this.$router.push({ path: `../${url}` });}
        return;
    }
  }

  pullToRefresh(done: Function) {
    this.refresh().then(() => {
      done();
    });
  }

  handleThumbnailError(e: Event) {
    (e.target as HTMLImageElement).src = require("@/styles/images/default_thumbnail.png");
  }

  // 새로고침
  async refresh() {
    this.refreshing = true;

    await Promise.all([
      this.setUnreadCount(),
      this.setApprvalWaitingCount(),
      this.applyFrontConfig(),
      this.loadBoardItems(),
    ])
      .then(() => {
        this.refreshing = false;
      })
      .catch((e) => {
        this.refreshing = false;
        this.$log.error(e);
      });
  }
}
