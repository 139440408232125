












































import { Component, Vue, Prop } from "vue-property-decorator";
// component
import HdLoadingMask from "@/components/HdLoadingMask.vue";

@Component({
  components: {
    HdLoadingMask,
  },
})
export default class HdPreview extends Vue {
  @Prop() title!: string;
  @Prop() src!: string;
  @Prop() isImage!: boolean;
  private loading: boolean = true;

  mounted() {
    if (this.isImage) {
      this.loading = false;
    }
  }

  handleClickClose() {
    this.$emit("close");
  }

  handleLoadFrame() {
    //var iframe: any = this.$refs.contentFrame;
    //var $content = iframe.contentWindow.document;
    // var viewport = ($content as HTMLDocument).getElementsByName("viewport")[0];
    // viewport.setAttribute("content", "width=device-width, initial-scale=1, maximum-scale=1");
    this.loading = false;
  }
}
