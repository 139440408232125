//NOTE(2021-04-13): 어차피 파일로 로그저장이 안 되기 때문에(web), 무거운 winston 대신 가벼운걸로 대체함
import Vue from "vue";
import VueLogger from "vuejs-logger";

const isProduction = process.env.NODE_ENV === "production";

const options = {
  isEnabled: true,
  logLevel: isProduction ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};

// VueLogger를 그대로 use 하면 install이 정의되지 않았다는 에러가 발생한다.
Vue.use(VueLogger as any, options);

declare module "vue/types/vue" {
  interface Vue {
    $log: {
      debug(...args: any[]): void;
      info(...args: any[]): void;
      warn(...args: any[]): void;
      error(...args: any[]): void;
      fatal(...args: any[]): void;
    };
  }
}
