





































import { Component, Vue, Prop } from "vue-property-decorator";
// Apis
import ApprovalApi from "@/api/approvalApi";
// Models
import Comment from "@/models/approval/Comment";
// Components
import HdLoadingMask from "@/components/HdLoadingMask.vue";

@Component({
  components: {
    HdLoadingMask,
  },
})
export default class CommentViewlPopup extends Vue {
  @Prop() apvId!: number;
  private userId!: string;

  private comments: Comment[] = [];
  private loading = true;

  mounted() {
    var sessionUser = this.$store.getters.getCurrentUser;
    if (sessionUser) {
      this.userId = sessionUser.UserID;
    }

    ApprovalApi.getCommentList(this.apvId, this.userId).then((result) => {
      this.comments = result.data;
      this.loading = false;
    });
  }
}
