



























































import { Component, Vue, Prop } from "vue-property-decorator";
import UTILS from "@/common/utilities";
// models
import Attachment from "@/models/approval/Attachment";
import Reference from "@/models/approval/Reference";

@Component({
  components: {
    HdAlternativeMessage: () => import("@/components/HdAlternativeMessage.vue"),
    HdPreview: () => import("@/components/HdPreview.vue"),
  },
})
export default class ApvAttachment extends Vue {
  @Prop() attachList!: Attachment[];
  @Prop() referenceList!: Reference[];

  private previewSrc: string = "";
  private attachTitle: string = "";
  private isImage: boolean = false;
  private previewDialog: boolean = false;

  vueDocument(ref: Reference) {
    this.$router.push({
      name: "ApvView",
      params: { apvId: ref.apvID.toString() },
    });
  }

  handleAttachmentClick(attach: Attachment) {
    // NOTE(2021-05-23): AIP 적용하면서 미리보기 지원이 불가능해짐
    // var imgExts = ["bmp", "gif", "angif", "png", "jpeg", "jpg", "tiff", "icon"];
    // var ext = attach.extension.replace(/\./, "");
    // if (imgExts.indexOf(ext.toLowerCase()) > -1) {
    //   this.previewSrc = `/SmartTalk/Attach/Response/DownLoad.ashx?id=${attach.attachID}`;
    //   this.attachTitle = attach.fileName;
    //   this.isImage = true;
    //   this.previewDialog = true;
    // } else if (ext.toLowerCase() == "pdf") {
    //   let handlerUrl = `/SmartTalk/Attach/Response/DownLoad.ashx?id=${attach.attachID}`;
    //   let url = `/portal/viewer/pdfviewer/viewer.aspx?file=${encodeURIComponent(handlerUrl)}`;
    //   this.previewSrc = url;
    //   this.attachTitle = attach.fileName;
    //   this.isImage = false;
    //   this.previewDialog = true;
    // } else {
    //   this.$q.notify({
    //     group: false,
    //     message: "미리보기를 지원하지 않는 파일입니다.",
    //     color: "white",
    //     position: "bottom",
    //     textColor: "primary",
    //     timeout: 500,
    //   });
    // }

    // ios
    try {
      window.webkit.messageHandlers.h1mobile.postMessage({
        command: "downloadFileURL",
        url: `${window.location.origin}/SmartTalk/Attach/Response/DownLoad.ashx?id=${attach.attachID}`,
      });
      return;
    } catch {
      this.$log.warn("not ios");
    }

    // android
    try {
      window.h1mobile.downloadFileURL(
        `${window.location.origin}/SmartTalk/Attach/Response/DownLoad.ashx?id=${attach.attachID}`
      );
      return;
    } catch {
      this.$log.warn("not android");
    }
  }

  getIconClass(fileName: string) {
    return UTILS.getIconClassFromFileName(fileName);
  }
}
