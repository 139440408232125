
































































































































































































import { Component, Vue, Prop } from "vue-property-decorator";
import { mapActions } from "vuex";
import BoardApi from "@/api/boardApi";
import DATEHANDLER from "@/common/dateHandler";
// model
import Comment from "@/models/board/Comment";
import * as CommentRequest from "@/models/board/request/CommentRequest";
// component
import CommentInput from "./CommentInput.vue";

@Component({
  components: {
    CommentInput,
    HdConfirm: () => import("@/components/HdConfirm.vue"),
  },
  methods: {
    ...mapActions(["loadConfigs"]),
  },
})
export default class CommentList extends Vue {
  @Prop() boardId!: string;
  @Prop() itemId!: string;

  private loadConfigs!: () => Promise<any>;

  private currentUser = this.$store.getters.getCurrentUser;
  private totalCommentCount = 0;
  private totalCommentPage = 1;
  private commentPage: number = 0;
  private commmentSize: number = 15;
  private commentList: Comment[] = [];
  private loading = true;
  private commentcommentId: number = -1;
  private editCommentId: number = -1;
  private maxDepth: number = 2;

  private deleteConfirmDialog: boolean = false;
  private deleteCommentId: number = -1;
  private isAnonymous = (this.$route.query.isAnonymousStr === "true") as boolean;

  mounted() {
    this.loadComment();
  }

  getCreatedYYYYMMDDHHmm(date: string): string {
    return DATEHANDLER.dateAsYYYYMMDDHHmm(date, this.$i18n.locale);
  }

  // 댓글 불러오기
  loadComment(refresh: boolean = false) {
    this.loading = true;

    this.commentPage = refresh ? 1 : this.commentPage + 1;

    BoardApi.getCommentListAsync(this.boardId, this.itemId, this.commentPage, this.commmentSize)
      .then((result) => {
        this.loadConfigs().then((config) => {
          var photoUrl = config.resource.photoUrl;
          result.items?.forEach((comment) => {
            comment.UserProfileInfo.UserProfileSrc = photoUrl.replace(
              "{0}",
              this.isAnonymous ? "" : comment.UserProfileInfo.UserID
            );
          });
          this.commentList = refresh ? result.items : this.commentList.concat(result.items);
          this.totalCommentCount = result.page.TotalCount;
          this.totalCommentPage = Math.ceil(result.page.TotalCount / result.page.Size);
          this.loading = false;
          this.commentcommentId = -1;
          this.editCommentId = -1;
        });
      })
      .catch(() => {
        this.loading = false;
      });
  }

  // 댓글 삭제
  deleteComment(comment: Comment): void {
    this.deleteConfirmDialog = true;
    this.deleteCommentId = comment.CommentID;
  }

  handleDeleteDecision(confirm: boolean) {
    if (confirm) {
      var req: CommentRequest.Delete = {
        ListID: this.boardId,
        ItemID: this.itemId,
        CommentID: this.deleteCommentId,
      };
      BoardApi.deleteCommentAsync(req).then(() => {
        this.loadComment(true);
        this.$q.notify({
          message: this.$t("Board_CommentDeleteddMessage").toString(),
          group: false,
          color: "white",
          textColor: "primary",
          timeout: 500,
        });
      });
    }

    this.deleteCommentId = -1;
  }

  // 댓글 최대 Depth. 이상일 경우는 최대와 동일 depth로 보여줌
  getMaxDepth(depth: number): number {
    return Math.min(depth, this.maxDepth);
  }

  // 대댓 입력창 닫기
  closeCommentInout() {
    this.commentcommentId = -1;
    this.editCommentId = -1;
  }

  // 대댓입력창, 댓글수정창 열기
  setInputField(openType: "add" | "modify", commentId: number): void {
    switch (openType) {
      case "add":
        this.commentcommentId = commentId;
        this.editCommentId = -1;
        break;
      case "modify":
        this.editCommentId = commentId;
        this.commentcommentId = -1;
        break;
    }
  }
}
