









import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class HdLoadingMask extends Vue {
  @Prop({ default: false }) useFixedHeight!: boolean;
  @Prop() height!: string;
  private clientHeight: string = "";

  mounted() {
    this.$nextTick(() => {
      if (this.height) {
        this.clientHeight = this.height;
      } else {
        this.clientHeight = this.$root.$el.getElementsByTagName("main")[0].style.minHeight;
        //this.clientHeight = this.$root.$el.clientHeight;
      }
    });
  }
}
