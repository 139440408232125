
























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
// model
import KVType from "@/models/common/KVType";

@Component({
  components: {
    HdSelectBox: () => import("@/components/HdSelectBox.vue"),
  },
})
export default class HdComboBox extends Vue {
  @Prop() options!: KVType[];
  @Prop() initValue!: string;
  @Prop({ default: false }) disabled!: boolean;
  private selected: KVType = {} as KVType;

  private searchTypeDialog = false;

  created() {
    if (this.initValue != undefined) {
      var index = this.options.findIndex((o) => {
        return o.key == this.initValue;
      });
      if (index > -1) {
        this.selected = this.options[index];
      }
    } else {
      this.selected = this.options[0];
    }
  }

  handleOptionChange(option: KVType): void {
    this.selected = option;
    this.$emit("change", this.selected);
  }

  @Watch("options")
  handleOptionListChanged() {
    this.$nextTick(() => {
      this.selected = this.options[0];
      //this.$forceUpdate();
    });
  }
}
