















































import { Component, Vue } from "vue-property-decorator";
import { mapActions } from "vuex";

@Component({
  methods: {
    ...mapActions(["loadConfigs"]),
  },
})
export default class Login extends Vue {
  private loadConfigs!: () => Promise<any>;
  private appList: string[] = [];
  private downloadRoot: string = "";
  private baseUrl: string = "";

  created() {
    this.$i18n.locale = this.$store.getters.getCurrentUser?.UserLangCode ?? "ko";

    this.loadConfigs().then((config) => {
      this.appList = config.download.apps;
      this.downloadRoot = config.download.path;
      this.baseUrl = config.baseUrl;
    });
  }

  handleDownload(fileName: string) {
    var ios = ["iphone", "ipod", "ipad", "macintosh"];
    var android = ["android"];

    // ios
    if (new RegExp(ios.join("|")).test(navigator.userAgent.toLowerCase())) {
      // 12.1 이상
      // if (
      //   navigator.userAgent.indexOf("OS 9") > -1 ||
      //   navigator.userAgent.indexOf("OS 11") > -1 ||
      //   navigator.userAgent.indexOf("OS 12") > -1 ||
      //   navigator.userAgent.indexOf("OS 13") > -1
      // ) {

      location.href = `itms-services://?action=download-manifest&url=${this.baseUrl}${this.downloadRoot}/${fileName}.plist`;
    }
    // android
    else if (new RegExp(android.join("|")).test(navigator.userAgent.toLowerCase())) {
      if (
        navigator.userAgent.indexOf("Android 4") >= 0 ||
        navigator.userAgent.indexOf("Android 5") >= 0 ||
        navigator.userAgent.indexOf("Android 6") >= 0
      ) {
        alert(this.$t("Download_DenyDownloadMessage"));
      } else {
        location.href = `${this.baseUrl}${this.downloadRoot}/${fileName}.apk`;
      }
    }
  }
}
