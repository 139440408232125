import axios from "axios";
import MobileMenu from "@/models/menu/MobileMenu";
import FavoriteMenu from "@/models/menu/FavoriteMenu";
import ApiResult from "@/models/ApiResult";

export default {
  /**
   * 전체 메뉴 조회
   * @param tenantId 회사 코드
   * @param langCode 언어 코드
   */
  async getMobileMenuListAsync(
    tenantId: string,
    langCode: string,
    userId: string
  ): Promise<MobileMenu[]> {
    const response = await axios.get<MobileMenu[]>(
      `/Mobile/api/v1/menu/${langCode}/tenant/${tenantId}/user/${userId}`,
      { withCredentials: true }
    );

    return response.data;
  },

  /**
   * 공통, 회사별 설정 조회
   * @param tenantId 회사코드
   */
  async getServerConfigsAsync(tenantId: string): Promise<ApiResult<any>> {
    const response = await axios.get<ApiResult<any>>(`/Mobile/api/v1/config/${tenantId}`);

    return response.data;
  },

  /**
   * 즐겨찾기 메뉴 조회
   * @param userId 사용자 ID
   */
  async getMobileFavoriteMenuListAsync(userId: string): Promise<ApiResult<FavoriteMenu[]>> {
    const response = await axios.post<ApiResult<FavoriteMenu[]>>(
      `/Portal/api/v1/Gnb/MyFavorites?userId=${userId}`
    );

    return response.data;
  },
};
