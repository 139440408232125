







import { Component, Vue } from "vue-property-decorator";

@Component
export default class HdSpinnerDots extends Vue {
  //  @Prop({ default: false }) useFixedHeight!: boolean;
}
