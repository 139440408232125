







































































































































import { Component, Vue } from "vue-property-decorator";
import DATEHANDLER from "@/common/dateHandler";
import notificationApi from "@/api/noficationApi";
// model
import MessageSearchRequest from "@/models/notification/request/MessageSearchRequest";
import NotificationMessage from "@/models/notification/NotificationMessage";
// component
import HdLoadingMask from "@/components/HdLoadingMask.vue";

@Component({
  components: {
    HdLoadingMask,
    HdConfirm: () => import("@/components/HdConfirm.vue"),
    HdAlternativeMessage: () => import("@/components/HdAlternativeMessage.vue"),
  },
})
export default class SetNotificationRead extends Vue {
  private keyword: string = "";
  private pageNumber: number = 1;
  private readonly pageSize: number = 30;
  private unreadCount: number = 0;
  private notiList: NotificationMessage[] = [];
  private loading: boolean = true;
  private loadmore: boolean = false;
  private currentPage: number = 1;

  private selectedItems: number[] = [];
  private selectAll: boolean = false;

  private readConfirmDialog: boolean = false;

  mounted() {
    this.loadUnreadNotificationList(false);
  }

  // 알림 목록 불러오기
  loadUnreadNotificationList(loadMore: boolean = false) {
    if (loadMore) {
      this.pageNumber++;
      this.loadmore = true;
    } else {
      this.loading = true;
    }

    var request: MessageSearchRequest = {
      Keyword: this.keyword,
      WithObserved: false,
      PageNumber: this.pageNumber,
      PageSize: this.pageSize,
    };

    notificationApi.getMessageList(request).then((result) => {
      if (result.Success) {
        this.unreadCount = result.Data.UnreadCount;

        if (loadMore) {
          this.notiList = this.notiList.concat(result.Data.MessageList);
        } else {
          this.notiList = result.Data.MessageList;
        }

        this.loading = false;
        this.loadmore = false;
      } else {
        this.$log.debug(result.Message);
        this.loading = false;
        this.loadmore = false;
      }
    });
  }

  getDateAsYYYYMMDDHHMM(date: string) {
    return DATEHANDLER.dateAsYYYYMMDDHHmm(date, this.$i18n.locale);
  }

  getMultilangContent(item: any) {
    var lang = this.$i18n.locale;

    var content = item[lang];
    if (content == null) {
      content = item._;
    }

    return content;
  }

  // 개별 항목 체크 토글
  toggleSelected(item: number, evt: Event) {
    // 체크박스 부분을 클릭했을때 발생하는 이벤트 방지
    evt.preventDefault();

    var index = this.selectedItems.indexOf(item);

    // 있으면 제거
    if (index > -1) {
      this.selectedItems.splice(index, 1);
    }
    // 없으면 추가
    else {
      this.selectedItems.push(item);
    }
  }

  // 전체 선택 토클
  toggleSelectAll(evt: Event) {
    // 체크박스 부분을 클릭했을때 발생하는 이벤트 방지
    evt.preventDefault();

    this.selectAll = !this.selectAll;

    if (this.selectAll) {
      this.selectedItems = [];
      this.notiList.forEach((item) => {
        this.selectedItems.push(item.index);
      });
    } else {
      this.selectedItems = [];
    }
  }

  openReadConfirmDialog() {
    if (this.selectedItems.length) {
      this.readConfirmDialog = true;
    }
  }

  // 읽음 처리 확인 액션
  handleDecision(confirm: boolean) {
    if (confirm) {
      this.setReadSelected();
    } else {
      return;
    }
  }

  //읽음처리
  setReadSelected() {
    notificationApi.setMessageRead(this.selectedItems).then((result) => {
      if (result.Success) {
        this.selectedItems = [];
        this.selectAll = false;
        this.loadUnreadNotificationList(false);
      }
    });
  }

  handleClickClose() {
    this.$emit("close");
  }
}
