




































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import ReservationApi from "@/api/reservationApi";
import DATEHANDLER from "@/common/dateHandler";
import { EventBus, EVENT } from "@/common/eventBus";
// model
import Building from "@/models/reservation/Building";
import Floor from "@/models/reservation/Floor";
import Room from "@/models/reservation/Room";
import KVType from "@/models/common/KVType";
import UserInfo from "@/models/board/UserInfo";
import GetAvailableRoomReuest from "@/models/reservation/request/GetAvailableRoomReuest";
import ReservationInfo from "./models/ReservationInfo";

//components
import HdComboBox from "@/components/HdComboBox.vue";
import HdWeekCalendar from "@/components/HdWeekCalendar.vue";
import TimeSelector from "./components/TimeSelector.vue";
import HdLoadingMask from "@/components/HdLoadingMask.vue";

@Component({
  components: {
    HdComboBox,
    HdWeekCalendar,
    TimeSelector,
    HdLoadingMask,
    HdAlternativeMessage: () => import("@/components/HdAlternativeMessage.vue"),
    HdSpinnerDots: () => import("@/components/HdSpinnerDots.vue"),
  },
})
export default class EmptyRoom extends Vue {
  @Prop() refresh!: number;
  @Prop() categoryId!: string;
  //private categoryId: string = "";
  private user = this.$store.getters.getCurrentUser as UserInfo;
  private buildingList: Building[] = [];
  private rooms: Room[] = [];
  private bldgOptions: KVType[] = [];
  private floorOptions: KVType[] = [];
  // 선택한 정보들
  private resInfo: ReservationInfo = {} as ReservationInfo;
  private selectedRoomID: number = -1;
  // 로딩 마스크 표시용
  private loading: boolean = true;
  private loadingRooms: boolean = false;
  // 현재시간 이전 + 회의실을 선택한 경우 해당 회의실이 예약된 시간
  private unavailable: number[] = [];
  // 시간 테이블 리셋을 위한 변수.
  private resetSelectedTime: number = 0;

  mounted() {
    this.resInfo.date = new Date();
    this.initailize();
  }

  async initailize() {
    var buildingId: number | null = null;
    var floorId: number | null = null;
    // 1. 마지막 예약 내역을 조회한다.
    await ReservationApi.getLastReservation().then((result) => {
      if (result.Success && result.Data != null) {
        buildingId = result.Data.BuildingID;
        floorId = result.Data.BldgFloorID;
      }
    });

    // 2. 사옥 목록을 조회한다.
    await this.setBuildings();
    // 3. 마지막 예약 내역의 사옥을 세팅한다.
    if (buildingId != null) {
      var building =
        this.bldgOptions[this.bldgOptions.findIndex((o) => Number(o.key) == buildingId)];
      await this.handleBuildingChanged(building);
    } else {
      this.resInfo.bldgOption = this.bldgOptions[0];
    }
    // 4. 세팅된 사옥의 층 목록을 세팅한다.
    await this.setFloors();
    // 5. 마지막 예약내역의 층을 세팅한다.
    // 6. 선택된 사옥과 층의 회의실 목록을 세팅한다.
    if (floorId != null) {
      var floor = this.floorOptions[this.floorOptions.findIndex((o) => Number(o.key) == floorId)];
      await this.handleFloorChanged(floor);
    } else {
      this.resInfo.floorOption = this.floorOptions[0];
      await this.setRooms();
    }
    this.loading = false;
  }

  // 사옥 목록 세팅
  async setBuildings() {
    return await ReservationApi.getBuildings().then((result) => {
      if (result.Success) {
        this.buildingList = result.Data;
        this.buildingList.forEach((building) => {
          this.bldgOptions.push({
            key: building.BuildingID.toString(),
            value: building.BuildingName,
          });
        });
      } else {
        this.$log.debug(result.Message);
      }
    });
  }

  // 층 목록 세팅
  async setFloors() {
    if (this.resInfo.bldgOption == null) return;

    return await ReservationApi.getBuildingFloors(
      Number(this.resInfo.bldgOption.key),
      this.categoryId
    ).then((result) => {
      this.floorOptions = [];
      this.floorOptions.push({ key: "-1", value: this.$t("AllFloors").toString() });
      if (result.Success) {
        result.Data.forEach((floor: Floor) => {
          this.floorOptions.push({
            key: floor.BldgFloorID.toString(),
            value: `${floor.DisplayPosition}`,
          });
        });

        this.resInfo.floorOption = this.floorOptions[0];
      } else {
        this.$log.debug(result.Message);
      }
    });
  }

  // 회의실 목록 세팅
  async setRooms() {
    this.loadingRooms = true;

    if (this.resInfo.bldgOption == null) {
      return;
    }

    // 시간 선택이 되어있는 경우 해당 시간에 사용 가능한 회의실 목록 조회
    if (
      this.resInfo.startTime != null &&
      this.resInfo.startTime != undefined &&
      this.resInfo.endTime != null &&
      this.resInfo.endTime != undefined
    ) {
      var startDateTime: Date = new Date(this.resInfo.date);
      var endDateTime: Date = new Date(this.resInfo.date);

      startDateTime.setHours(this.resInfo.startTime, 0, 0, 0);
      endDateTime.setHours(this.resInfo.endTime, 0, 0, 0);
      startDateTime = DATEHANDLER.convertUTCDateToLocalDate(startDateTime);
      endDateTime = DATEHANDLER.convertUTCDateToLocalDate(endDateTime);

      var floor: string | null = this.resInfo.floorOption?.key ?? null;
      if (floor == null || floor == "-1") {
        floor = null;
      }

      var now = new Date();
      var resuest: GetAvailableRoomReuest = {
        BuildingID: Number(this.resInfo.bldgOption.key),
        ReservationDatetime: DATEHANDLER.convertUTCDateToLocalDate(now).toISOString(),
        ResStartDatetime: startDateTime.toISOString(),
        ResEndDatetime: endDateTime.toISOString(),
        FloorID: floor != null ? Number(floor) : null,
        FloorType: floor != null ? "Only" : "All",
        ReservationID: null,
        CategoryID: this.categoryId,
      } as GetAvailableRoomReuest;
      return await ReservationApi.getAvailableRooms(resuest)
        .then((result) => {
          if (result.Success) {
            this.rooms = result.Data;
            this.loadingRooms = false;
          } else {
            this.$log.debug(result.Message);
            this.loadingRooms = false;
          }
        })
        .catch((e) => {
          this.$log.error(e);
          this.loadingRooms = false;
        });
    }
    // 시간 선택이 안 되어 있는 경우 전체 회의실 목록 조회
    else {
      var floorId: number | null =
        this.resInfo.floorOption == null || this.resInfo.floorOption.key == "-1"
          ? null
          : Number(this.resInfo.floorOption.key);

      return await ReservationApi.getRooms(
        Number(this.resInfo.bldgOption.key),
        floorId,
        this.categoryId
      )
        .then((result) => {
          if (result.Success) {
            this.rooms = result.Data;

            this.loadingRooms = false;
          } else {
            this.$log.debug(result.Message);
            this.loadingRooms = false;
          }
        })
        .catch((e) => {
          this.$log.error(e);
          this.loadingRooms = false;
        });
    }
  }

  // 날짜 변경 적용
  handleDateChanged(date: Date) {
    this.resInfo.date = date;

    // 선택한 시간이 있으면 초기화
    this.resInfo.startTime = null;
    this.resInfo.endTime = null;
    this.resetSelectedTime++;

    this.initUnavailable();

    // 선택한 회의실에 해당 날짜 예약내역 조회
    if (this.selectedRoomID != -1) {
      var room = this.rooms.find((o) => o.RoomID == this.selectedRoomID) ?? null;
      this.selectRoom(room);
    }
  }

  initUnavailable() {
    var today = new Date();
    this.unavailable = [];

    // 오늘일 때
    if (today.toDateString() == this.resInfo.date.toDateString()) {
      for (let i = 7; i <= today.getHours(); i++) {
        this.unavailable.push(i);
      }
    }
    // 오늘 이전일 때
    else if (today > this.resInfo.date) {
      for (let i = 7; i <= 21; i++) {
        this.unavailable.push(i);
      }
    }
  }

  // 시간 변경 적용
  handleTimeChanged(startTime: number | null, endTime: number | null) {
    // 실제 값에 변화가 없으면 무시
    if (this.resInfo.startTime == startTime && this.resInfo.endTime == endTime) {
      return;
    }
    this.resInfo.startTime = startTime;
    this.resInfo.endTime = endTime;
    this.setRooms();
  }

  // 건물 변경 적용
  async handleBuildingChanged(option: KVType) {
    this.resInfo.bldgOption = option;

    // 선택한 시간이 있으면 초기화
    this.resetSelectedTime++;

    // 층 세팅하고 선택 옵션 초기화
    await this.setFloors();
    this.resInfo.floorOption = this.floorOptions[0];

    // 회의실 세팅하고 선택옵션 초기화
    await this.setRooms();
    this.selectRoom(null);
  }

  async handleFloorChanged(option: KVType) {
    this.resInfo.floorOption = option;

    await this.setRooms();
  }

  // 회의실 선택
  selectRoom(room: Room | null) {
    // 선택이 초기화 된 경우. 시간도 초기화
    if (room == null) {
      this.initUnavailable();
      // 선택한 시간이 있으면 초기화
      this.resetSelectedTime++;
      return;
    }
    this.resInfo.room = {
      key: room.RoomID.toString(),
      value: room.RoomName,
    };
    this.selectedRoomID = room.RoomID;

    this.resInfo.room = {
      key: room.RoomID.toString(),
      value: room.RoomName,
    };

    ReservationApi.getRoomReservations(room.RoomID, this.resInfo.date.toDateString(), false).then(
      (result) => {
        if (result.Success) {
          this.initUnavailable();

          result.Data.forEach((reservation) => {
            const startTime = new Date(reservation.RoomInfo.ResStartDatetime);
            const endTime = new Date(reservation.RoomInfo.ResEndDatetime);

            // 정각에 끝나면 예약 가능
            const to = endTime.getMinutes() == 0 ? endTime.getHours() - 1 : endTime.getHours();

            for (let i = startTime.getHours(); i <= to; i++) {
              this.unavailable.push(i);
            }
          });

          // 선택한 시간이 있으면 초기화
          if (
            room.RoomID == this.selectedRoomID &&
            this.resInfo.startTime != null &&
            this.resInfo.endTime != null
          ) {
            var reselect = true;
            for (let i = this.resInfo.startTime; i < this.resInfo.endTime; i++) {
              if (this.unavailable.indexOf(i) > -1) {
                reselect = false;
                break;
              }
            }

            if (reselect) {
              var start = this.resInfo.startTime;
              var end = this.resInfo.endTime;
              this.$nextTick(() => {
                this.handleTimeChanged(start, end);
                this.resetSelectedTime++;
              });
            }
          }
        }
      }
    );
  }

  // 회의실 예약 화면 팝업
  openReservationDialog() {
    EventBus.$emit(EVENT.OPEN_MAKE_RESERVATION, this.resInfo, false);
  }

  @Watch("refresh")
  updateList() {
    if (this.selectedRoomID != -1) {
      var room = this.rooms.find((o) => o.RoomID == this.selectedRoomID) ?? null;
      this.selectRoom(room);
    }

    this.resInfo.startTime = null;
    this.resInfo.endTime = null;
    this.resetSelectedTime++;
  }
}
