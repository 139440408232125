

































import { Component, Vue, Prop } from "vue-property-decorator";

const START_UNSET = 999;
const END_UNSET = -1;

@Component
export default class TimeSelector extends Vue {
  @Prop() initStartTime!: number;
  @Prop() initEndTime!: number;
  // 처음에 전달받은 예약 불가 시간
  @Prop() disabled!: number[];
  // 선택에 따라 변경된 예약 불가 시간
  private unavailableBefore: number = END_UNSET;
  private unavailableAfter: number = START_UNSET;

  private timeTable = [
    [7, 8, 9, 10, 11],
    [12, 13, 14, 15, 16],
    [17, 18, 19, 20, 21],
  ];
  private startTime: number = START_UNSET;
  private endTime: number = END_UNSET;

  mounted() {
    if (this.initStartTime != undefined && this.initEndTime != undefined) {
      if (!this.isDisabled(this.initStartTime) && !this.isDisabled(this.initEndTime - 1)) {
        this.setTime(this.initStartTime);
        // setTime은 클릭 기반으로 계산하므로 1시간만 선택한 경우 endTime은 클릭하지 않은것으로 처리
        if (this.initEndTime > this.initStartTime + 1) {
          // NOTE: setTime함수의 특성 때문에 this.initEndTime - 1로 줘야함
          this.setTime(this.initEndTime - 1);
        }
      }
    }

    this.$emit(
      "changed",
      this.startTime == START_UNSET ? null : this.startTime,
      this.endTime == END_UNSET ? null : this.endTime
    );
  }

  setTime(time: number) {
    if (this.isDisabled(time)) {
      return;
    }

    // 처음 클릭한 경우
    if (this.startTime == START_UNSET) {
      this.startTime = time;
      if (this.endTime == END_UNSET) {
        this.endTime = time + 1;
      }

      // 선택 가능한 범위가 현재 선택에서 이전 / 이후 예약 사이만 가능하도록 처리
      for (let i = this.startTime; i < 21; i++) {
        if (this.isDisabled(i)) {
          this.unavailableAfter = i;
          break;
        }
      }

      for (let i = this.startTime; i >= 7; i--) {
        if (this.isDisabled(i)) {
          this.unavailableBefore = i;
          break;
        }
      }
    }
    // 두번째 클릭한 경우
    else if (this.endTime == END_UNSET || this.endTime == this.startTime + 1) {
      // 시작 시간을 다시 클릭한 경우.
      if (this.startTime == time) {
        if (this.endTime != END_UNSET && this.endTime != this.startTime + 1) {
          this.startTime = this.endTime;
          this.endTime = this.startTime + 1;
        }
        this.startTime = START_UNSET;
        this.endTime = END_UNSET;
        this.unavailableBefore = END_UNSET;
        this.unavailableAfter = START_UNSET;
      }
      // 시작시간 이전을 클릭한 경우
      else if (this.startTime > time) {
        //this.endTime = this.startTime;
        this.startTime = time;
      }
      // 시작시간 이후를 클릭한 경우
      else {
        this.endTime = time + 1;
      }
    }
    // 세번째 클릭한 경우
    else {
      this.startTime = START_UNSET;
      this.endTime = END_UNSET;
      this.unavailableBefore = END_UNSET;
      this.unavailableAfter = START_UNSET;
    }

    this.$emit(
      "changed",
      this.startTime == START_UNSET ? null : this.startTime,
      this.endTime == END_UNSET ? null : this.endTime
    );
    return;

    // if (this.endTime == END_UNSET) {
    //   this.endTime == ;
    //   return;
    // }
  }

  isDisabled(time: number) {
    //var today = new Date();
    if (
      this.disabled?.indexOf(time) > END_UNSET ||
      //col < today.getHours() ||
      time > this.unavailableAfter ||
      time < this.unavailableBefore
    ) {
      return true;
    }

    return false;
  }

  getClass(time: number) {
    var cls = "";
    if (this.isDisabled(time)) {
      cls = "hd-time-disabled";
    }
    if (time >= this.startTime && time < this.endTime) {
      cls += " hd-time-selected";
    }

    return cls;
  }
}
