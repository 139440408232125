







import { Component, Vue } from "vue-property-decorator";

@Component
export default class App extends Vue {
  mounted() {
    this.$i18n.locale = this.$store.getters.getCurrentUser?.UserLangCode ?? "ko";
  }
}
