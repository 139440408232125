






































































































































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { mapActions } from "vuex";
import BoardApi from "@/api/boardApi";
import MenuHandler from "@/common/menuHandler";
import UTILS from "@/common/utilities";
import DATEHANDLER from "@/common/dateHandler";
// model
import MListItemEntity from "@/models/board/MListItemEntity";
import Attachment from "@/models/board/Attachment";
// component
import HdLoadingMask from "@/components/HdLoadingMask.vue";
import CommentList from "./components/CommentList.vue";
import scroll from 'quasar/src/utils/scroll.js';;

@Component({
  components: {
    CommentList,
    HdLoadingMask,
    HdPreview: () => import("@/components/HdPreview.vue"),
    HdMakeReservation: () => import("@/views/reservation/MakeReservation.vue"),
    HdAlternativeMessage: () => import("@/components/HdAlternativeMessage.vue"),
  },
  methods: {
    ...mapActions(["loadConfigs"]),
  },
})
export default class ItemList extends Vue {
  private loadConfigs!: () => Promise<any>;
  private boardId = this.$route.query.boardId as string;
  private itemId = this.$route.query.itemId as string;
  private isAnonymousStr = this.$route.query.isAnonymousStr as string;
  private isAnonymous = (this.isAnonymousStr === "true") as boolean;

  // 조회 실패 안내팝업
  private errorDialog: boolean = false;
  private errorMessage: string = "";

  // 게시글 속성 관련
  private listItem: MListItemEntity = {} as MListItemEntity;
  private boardName: string = "";
  private attachments: Attachment[] = [];
  private useComment: boolean = false;
  private useRecommendation: boolean = false;
  private useScrap: boolean = false;

  // 이미지 클릭 처리 관련
  private imageSrc: string = "";
  private openImage: boolean = false;
  private imageViewStyle: string = "";

  private itemLoading: boolean = true;
  private contentLoading: boolean = true;

  private recommendBtnClass: string = "";
  private recommendCountStr: string = "";

  private appConfigs: any;
  private photoUrl: string = "";

  // 첨부파일 미리보기 관련
  private previewSrc: string = "";
  private attachTitle: string = "";
  private isImage: boolean = false;
  private previewDialog: boolean = false;

  // 이전글/다음글 검색옵션
  private searchType: string = (this.$route.query.searchType as string) ?? "";
  private searchKeyword: string = (this.$route.query.searchKeyword as string) ?? "";
  private fieldInfo: string = (this.$route.query.fieldInfo as string) ?? "";

  // 게시글 필드 HTML
  private fieldHtml: string = "";

  mounted() {
    // 필드사용 게시판 여부 확인
    this.loadConfigs().then((config) => {
      this.appConfigs = config;
      this.photoUrl = config.resource.photoUrl;
      this.init();
    });
  }

  async init() {
    // 로딩마스트 위치가 틀어지는것 방지용
    this.itemLoading = true;
    this.contentLoading = true;
    var item = scroll.getScrollTarget(this.$root.$el);
    scroll.setScrollPosition(item, 0);

    // NOTE: queryParam만 바뀌면 자동으로 화면 새고침이 일어나지 않기 때문에 강제해줌
    this.itemId = this.$route.query.itemId as string;
    this.loadItem();

    var data = await BoardApi.GetItemFieldHtml(this.boardId, this.itemId);
    this.fieldHtml = data;
    this.$nextTick(() => {
      var rows = (this.$refs.divField as HTMLDivElement).getElementsByClassName("row");
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].children.length > 2) {
          (rows[i] as HTMLDivElement).className += " block-row";
        }
      }
    });
  }

  refreshContent(done: Function) {
    this.init().then(() => {
      done();
    });
  }

  async loadItem() {
    (this.$refs.mainContainer as HTMLDivElement).style.height = "0";

    BoardApi.getBoardItemAsync(
      this.boardId,
      this.itemId,
      false,
      this.searchType,
      this.searchKeyword,
      this.fieldInfo
    )
      .then((result) => {
        if (result.Success) {
          var data = result.Data;
          this.listItem = data.Item;
          this.attachments = data.Attachments;
          this.boardName = data.BoardName;
          this.useRecommendation = data.BoardOptions.UseRecommendation;
          this.useComment = data.BoardOptions.UseComment;
          this.useScrap = data.BoardOptions.UseScrap;

          // NOTE: Iframe에 src를 직접 지정하면 iframe이 로드되면서 vue router history에 추가되어 뒤로가기 액션시 문제가 발생한다.
          // 아래처럼 replace를 이용하여 history에 추가되지 않도록 수정함.
          var contentFrame = this.$refs.contentFrame as HTMLIFrameElement;
          contentFrame?.contentWindow?.location.replace(
            `/Portal/Board/Pages/Content/ContentView.aspx?BoardId=${this.boardId}&ItemId=${this.itemId}&Alias=Content`
          );
          contentFrame.onload = this.handleContentFrameLoadComplete;

          MenuHandler.findMenuByUrl(`board/${this.$route.params.boardUrl}`).then((currentMenu) => {
            this.boardName = currentMenu?.ShortName ?? data.BoardName;
          });
          this.setRecommend();
        } else {
          this.errorMessage = result.Message;
          this.errorDialog = true;
        }
        this.itemLoading = false;
      })
      .catch((e) => {
        this.$log.error(e);
        this.itemLoading = false;
      });
  }

  getSizeString(size: number): string {
    return UTILS.byteToStr(size);
  }

  getCreatedYYYYMMDD(date: string): string {
    return DATEHANDLER.strToYYYYMMDD(date, this.$i18n.locale);
  }

  // iframe 내부 코드 처리 및 높이를 본문 높이에 맞게 조정
  handleContentFrameLoadComplete() {
    var iframe: any = this.$refs.contentFrame;
    var $content = iframe.contentWindow.document;

    (this.$refs.mainContainer as HTMLDivElement).style.height = "";

    // 이미지 크기 조정 및 클릭 이벤트 등록
    var images = $content.getElementsByTagName("img");
    for (let index = 0; index < images.length; index++) {
      let el: HTMLImageElement = images[index];
      // 인라인 이미지 src도 회사 URL이 박혀있는 경우가 있음
      el.src = el.src.replace(/(https?:\/\/)(.*?\/)CrossEditor/gi, "/CrossEditor");
      el.src = el.src.replace(
        /(https?:\/\/)(.*?\/)Portal\/Board\/Controls\/ImageFileViewer.ashx/gi,
        "/Portal/Board/Controls/ImageFileViewer.ashx"
      );

      el.style.maxWidth = "100%";
      el.style.height = "auto";
      el.addEventListener("click", this.handleImageClick);
      el.addEventListener("load", this.handleHeight);
    }

    // iframe이 또(동영상 등) 들어있을 때 크기 조정
    var iframes = $content.getElementsByTagName("iframe");
    for (let index = 0; index < iframes.length; index++) {
      let el: HTMLIFrameElement = iframes[index];

      el.style.width = "100%";
      el.style.height = "auto";
    }

    // embed(동영상 등) 들어있는 경우 크기 조정
    var embeds = $content.getElementsByTagName("embed");
    for (let index = 0; index < embeds.length; index++) {
      let el: HTMLEmbedElement = embeds[index];

      el.style.width = "100%";
      el.style.height = "auto";
    }

    // NOTE: 에디터 설정이 본문에 하드코딩되어 있어 CORS문제로 로딩에 실패하므로 URL을 바꿔준다
    var namoCss = $content.getElementById("NamoSEEditorCSS");
    var namoStyle = $content.getElementById("NamoSEEditorStyle");
    var namoFileStyle = $content.getElementById("NamoSEInsertFileStyle");
    var editorCss = $content.getElementsByTagName("link")[0];

    if (namoCss) {
      namoCss.outerHTML = namoCss.outerHTML.replace(/(https?:\/\/)(.*?\/)/gi, "/");
    }
    if (namoStyle) {
      namoStyle.outerHTML = namoStyle.outerHTML.replace(/(https?:\/\/)(.*?\/)/gi, "/");
    }
    if (namoFileStyle) {
      namoFileStyle.outerHTML = namoFileStyle.outerHTML?.replace(/(https?:\/\/)(.*?\/)/gi, "/");
    }
    if (editorCss) {
      editorCss.outerHTML = editorCss.outerHTML.replace(/(https?:\/\/)(.*?\/)/gi, "/");
    }

    // hyperlink를 외부에서 호출하기 위한 설정
    var hyperLinks = $content.getElementsByTagName("a");
    for (let i = 0; i < hyperLinks.length; i++) {
      (hyperLinks[i] as HTMLAnchorElement).addEventListener("click", (e: Event) => {
        var url = (hyperLinks[i] as HTMLAnchorElement).href;

        if (url != undefined) {
          e.preventDefault();
          if (!url.startsWith(this.appConfigs.baseUrl)) {
            // ios에서
            try {
              window.webkit.messageHandlers.h1mobile.postMessage({
                command: "openExternBrowser",
                url: url,
              });
            } catch (e) {
              this.$log.error("not ios");
              this.$log.error(e);
            }

            // android에서
            try {
              window.h1mobile.openExternBrowser(url);
            } catch (e) {
              this.$log.error("not android");
              this.$log.error(e);
            }
          }
        }
      });
    }

    // NOTE: 가끔 편집모드로 불러와지는 애들이 있어 막음
    $content.body.setAttribute("contenteditable", false);

    var contentHeight = $content.body.offsetHeight;
    // NOTE: 상하 padding 각 16px 만큼 여유를 줌
    iframe.style.height = contentHeight + 32 + "px";

    if (!this.itemLoading) {
      this.contentLoading = false;
    }

    setTimeout(() => {
      this.handleHeight();
    }, 500);
  }

  // NOTE: 먼저 화면 전체가 로드된 뒤에 화면 크기를 재조정해야하는데, 이미지 url변경등으로 로딩이 늦어질 수 있으므로, 이미지 로드 되었을 때 다시 높이를 조절하게 한다
  handleHeight() {
    var iframe: any = this.$refs.contentFrame;
    var $content = iframe.contentWindow.document;
    var contentHeight = $content.body.offsetHeight;

    // NOTE: 상하 padding 각 16px 만큼 여유를 줌
    iframe.style.height = contentHeight + 32 + "px";
  }

  /**
   * 첨부파일 미리보기
   */
  previewAttachment(attach: Attachment): void {
    var ext = attach.FileFullName.split(".").pop() ?? "";
    //var imgExts = ["bmp", "gif", "angif", "png", "jpeg", "jpg", "tiff", "icon"];

    // 미리보기를 지원하는 파일 확장자
    // 포탈의 10_UI/MIK.SmartPortal/Web/Board/Controls/Attachment/FileUpload.ascx에서 가져옴
    var previewExts = [
      "doc",
      "docx",
      "docm",
      "dot",
      "dotx",
      "dotm",
      "xls",
      "xlsx",
      "xlsm",
      "xlsb",
      "xlt",
      "xltx",
      "xltm",
      "xla",
      "xlam",
      "ppt",
      "pptx",
      "pptm",
      "pot",
      "potx",
      "potm",
      "pps",
      "ppsx",
      "ppsm",
      "ppa",
      "ppam",
      "pdf",
    ];

    var downloadExts = ['apk', 'ipa', 'jpg'];

    if (previewExts.indexOf(ext.toLowerCase()) == -1) {
      // 모바일 다운로드 가능 여부 체크
      if(downloadExts.indexOf(ext.toLowerCase()) == -1){
        this.previewUnavailableToast(this.$t("Board_PreviewUnavailableMessage").toString());
      }
      else{
        var downloadUrl  = `${window.location.origin}/Portal/Board/Controls/Attachment/FileTransferHandler.ashx?f=${attach.AttachmentFileID}&b=${this.boardId}`;
        //this.$log.info(downloadUrl);
        try {
          window.webkit.messageHandlers.h1mobile.postMessage({
            command: "downloadFileURL",
            url: downloadUrl,
          });
          return;
        } catch {
          this.$log.warn("not ios");
        }

        // android
        try {
          window.h1mobile.downloadFileURL(
            downloadUrl
          );
          return;
        } catch {
          this.$log.warn("not android");
        }
      }
    } else {
      // if (imgExts.indexOf(ext.toLowerCase()) > -1) {
      //   this.previewSrc = `/Portal/Board/Controls/Attachment/FileTransferHandler.ashx?b=${attach.ListID}&f=${attach.AttachmentFileID}`;
      //   this.attachTitle = attach.FileName;
      //   this.isImage = true;
      //   this.previewDialog = true;
      BoardApi.checkPreview(attach.AttachmentFileID, this.itemId, this.boardId).then((result) => {
        if (result.IsExist) {
          this.openPdfPreview(attach);
        } else {
          this.previewUnavailableToast(this.$t("Board_PreviewCreatingMessage").toString());
        }
      });
    }
  }

  previewUnavailableToast(message: string) {
    this.$q.notify({
      group: false,
      message: message,
      color: "white",
      position: "bottom",
      textColor: "primary",
      timeout: 500,
    });
  }

  openPdfPreview(attach: Attachment) {
    let handlerUrl = `/Portal/Board/Controls/Attachment/FileTransferHandler.ashx?f=${attach.AttachmentFileID}&b=${this.boardId}&p=true`;
    let url = `/portal/viewer/pdfviewer/viewer.aspx?file=${encodeURIComponent(handlerUrl)}`;
    this.previewSrc = url;
    this.attachTitle = attach.FileName;
    this.isImage = false;
    this.previewDialog = true;
  }

  /**
   * 인라인 이미지 확대
   */
  handleImageClick(e: Event): void {
    var image = e.target as HTMLImageElement;

    // NOTE: 화면 크기보다 긴 이미지일 경우 display: block이 없으면 이미지 일부가 잘린다.
    // 화면 크기보다 짧은 이미지일 경우 display: block이 있으면 가운데에 표시되지 않는다.
    if (image.clientHeight > window.screen.height) {
      this.imageViewStyle = "display: block";
    } else {
      this.imageViewStyle = "";
    }

    this.imageSrc = image.src;
    this.openImage = true;
  }

  setRecommend() {
    this.setRecommendClass();
    this.setRecommentCountStr();
  }

  setRecommendClass() {
    this.recommendBtnClass = "";
    if (this.listItem.IsRecommended) {
      this.recommendBtnClass += "hd-listitem-my-recommend ";
    }
    if (this.listItem.RecommendCount > 0) {
      this.recommendBtnClass += "hd-listitem-recommended";
    }
  }

  /**
   * 추천 수 표시 문자열
   */
  setRecommentCountStr() {
    this.recommendCountStr;
    if (this.listItem.RecommendCount == 0) {
      this.recommendCountStr = "";
    } else if (this.listItem.RecommendCount > 99) {
      this.recommendCountStr = "99+";
    } else {
      this.recommendCountStr = this.listItem.RecommendCount + "";
    }
  }

  /**
   * 추천 / 추천 취소
   */
  toggleRecommend() {
    // if (!this.listItem.IsRecommended) {
    BoardApi.toggleRecommendAsync(this.boardId, this.itemId).then((result) => {
      if (result.Status != -1) {
        this.listItem.IsRecommended = true;
      } else {
        this.listItem.IsRecommended = false;
      }

      this.listItem.RecommendCount = result.Count;
      this.setRecommend();
    });
  }

  /**
   * 스크랩 / 스크랩 취소
   */
  toggleScrap() {
    // this.listItem.IsScrapped를 현재상태로 보내야 제대로 처리됨
    BoardApi.toggleScrap(this.boardId, this.itemId, this.listItem.IsScrapped).then((success) => {
      if (success) {
        this.listItem.IsScrapped = !this.listItem.IsScrapped;
      }
    });
  }

  getIconClass(fileName: string) {
    return UTILS.getIconClassFromFileName(fileName);
  }

  calculateMessageHeight() {
    var fullHeight = document.getElementsByTagName("main")[0]?.style.minHeight ?? "";
    return fullHeight;
  }

  /**
   * 뒤로가기
   */
  goBack() {
    if (history.length && history.state != null) {
      this.$router.go(-1);
    } else {
      this.$router.replace({ name: "Front" });
    }
  }

  /**
   * 이전글/다음글 이동
   */
  moveTo(toItemId: string): void {
    this.$router.push({
      query: {
        boardId: this.boardId,
        itemId: toItemId,
        searchType: this.searchType,
        searchKeyword: this.searchKeyword,
        fieldInfo: this.fieldInfo,
        isAnonymousStr: this.isAnonymousStr,
      },
    });
  }

  // NOTE: 이전글 다음글 이동시
  @Watch("$route")
  handleItemIdChnaged() {
    this.$nextTick(() => {
      this.init();
    });
  }
}
