


































































import { Component, Vue, Prop } from "vue-property-decorator";
import { mapActions } from "vuex";
import MenuHandler from "@/common/menuHandler";
import LineInfo from "@/models/approval/LineInfo";
import { ButtonCode as BC } from "@/models/approval/enums/ButtonCode";
import Button from "@/models/approval/Button";
import UserInfo from "@/models/board/UserInfo";
import { ApvAdvancedSearchListType } from "@/models/approval/enums/ApvEnums";

@Component({
  components: {
    LineApprovalPopup: () => import("../popups/LineApprovalPopup.vue"),
    CommentViewPopup: () => import("../popups/CommentViewPopup.vue"),
    ModificationOpinionPopup: () => import("../popups/ModificationOpinionPopup.vue"),
    OriginalDocumentPopup: () => import("../popups/OriginalDocumentPopup.vue"),
  },
  methods: {
    ...mapActions(["loadConfigs"]),
  },
})
export default class ApvButtons extends Vue {
  @Prop() buttons!: Button[];
  @Prop() lineInfo!: LineInfo;
  @Prop() rejectDic!: { hold: boolean; reject: boolean; rejectOne: boolean };
  @Prop() rejectDefault!: string;
  private loadConfigs!: () => Promise<any>;
  private user: UserInfo = {} as UserInfo;
  // 표시할 버튼
  private buttonsToDisplay: Button[] = [];
  private rejectCode: string = "";
  private actionName: string = "";

  private submissionDialog: boolean = false;
  private viewCommentDialog: boolean = false;
  private confirmDialog: boolean = false;
  private modificationOpinionDialog: boolean = false;

  private isCurrentStep: boolean = false;
  // 결재 진행 중 오류 발생 여부
  private errorOccurred: boolean = false;

  mounted() {
    var sessionUser = this.$store.getters.getCurrentUser;
    if (sessionUser) {
      this.user = sessionUser;
    }

    // NOTE: 모든 액션을 다 구현하지 않으므로 Whitelist로 관리
    var btnShow: BC[] = [
      BC.Approve,
      BC.Reject,
      BC.Agree,
      BC.Disagree,
      // BC.ViewComment,
      // BC.Hold,
      // BC.StopHold,
      // BC.CancelDraft,
      // BC.DownloadPdf,
      BC.ExtendedButton04,
    ];

    this.buttonsToDisplay = this.buttons.filter((item) => btnShow.indexOf(item.btnCode) != -1);
  }

  getBtnStyle(btn: Button) {
    var btnForward = [BC.Approve, BC.Agree];
    var btnBackward = [BC.Reject, BC.Disagree];

    var style = `width: calc(100% / ${this.buttonsToDisplay.length});`;
    if (btnForward.indexOf(btn.btnCode) > -1) {
      style += "background-color: #2F4BD5;";
    } else if (btnBackward.indexOf(btn.btnCode) > -1) {
      style += "background-color: #162257;";
    } else {
      style += "background-color: #989EB3";
    }

    return style;
  }

  /* 버튼 액션 */
  submitDecision(button: Button) {
    var bc = button.btnCode;
    switch (bc) {
      case BC.Approve:
        this.rejectCode = "0";
        break;
      case BC.Reject:
        this.rejectCode = "2";
        break;
      case BC.Hold:
        this.rejectCode = "3";
        break;
      case BC.StopHold:
        this.rejectCode = "4";
        break;
      case BC.CancelDraft:
        this.rejectCode = "7";
        break;
      // case BC.SelfApprove:
      //   this.rejectCode = "9";
      //   break;
      case BC.Agree:
        this.rejectCode = "a";
        break;
      case BC.Disagree:
        this.rejectCode = "d";
        break;
      case BC.ExtendedButton04:
        this.modificationOpinionDialog = true;
        return;
      case BC.ViewComment:
        this.viewCommentDialog = true;
        return;
    }

    this.actionName = button.displayName;
    this.submissionDialog = true;
  }

  getIconBackgrounClass() {
    // 에러 발생
    if (this.errorOccurred) {
      return "hd-bg-point";
    } else {
      // 반려
      if (this.rejectCode == "1" || this.rejectCode == "2" || this.rejectCode == "9") {
        return "hd-bg-secondary";
      }
      // 승인
      return "";
    }
  }

  /**
   * 결재 팝업 종료시
   * @param 1: 성공, 2: 실패 3: 그냥 닫기
   */
  handleCloseSubmissionDialog(state: number) {
    this.submissionDialog = false;

    if (state == 1) {
      this.confirmDialog = true;
    } else if (state == 2) {
      this.confirmDialog = true;
      this.errorOccurred = true;
      //
    }
  }

  // 수정의견 팝업 종료시
  handleCloseModificationRequestDialog(stateChanged: boolean) {
    this.modificationOpinionDialog = false;

    // 상태 변화가 있으면 업데이트
    if (stateChanged) {
      this.$emit("stateChange");
    }
  }

  // 완료 안내 팝업 종료시
  async handleConfirm() {
    this.confirmDialog = false;
    if (this.errorOccurred) {
      if (history.length && history.state != null) {
        this.$router.go(-1);
      } else {
        var config = await this.loadConfigs();
        var apvMenu = await MenuHandler.findMenuByMenuId(config.approval.menuId);
        if (apvMenu != undefined && apvMenu.Children.length) {
          this.$router.push(apvMenu.Children[0].Url);
        } else {
          this.$router.push({
            name: "ApvList",
            params: { listType: ApvAdvancedSearchListType.WaitingApproval },
          });
        }
      }
    } else {
      // 결재가 끝났으므로 상태 업데이트를 위해 이벤트 전파
      this.$emit("stateChange");
    }
  }
}
