import axios from "axios";
import ApiResult from "@/models/apiResult";
import Restaurant from "@/models/cafeteriaMenu/Restaurant";
import MealInfo from "@/models/cafeteriaMenu/MealInfo";

export default {
  /**
   * 식당 조회
   */
  async getRestaurantsAsync(): Promise<ApiResult<Restaurant[]>> {
    const response = await axios.get<ApiResult<Restaurant[]>>(`/Portal/api/v1/Food/GetRestaurants`);

    return response.data;
  },

  /**
   * 코스 및 메뉴 조회
   * @param rId 식당 ID
   * @param date 조회 날짜
   */
  async getMenusAsync(rId: string, date: string): Promise<ApiResult<MealInfo[]>> {
    const response = await axios.get<ApiResult<MealInfo[]>>(
      `/Portal/api/v1/Food/GetMenus?rId=${rId}&date=${date}`
    );

    return response.data;
  },
};
