












































import { Component, Vue, Prop } from "vue-property-decorator";
import ApprovalApi from "@/api/approvalApi";

// Components
import Button from "@/models/approval/Button";
import LineInfo from "@/models/approval/LineInfo";
import ContentInfo from "@/models/approval/ContentInfo";

import { EventBus, EVENT } from "@/common/eventBus";
import Field from "@/models/approval/Field";
@Component({
  components: {
    ApvButtons: () => import("./ApvButtons.vue"),
    ApvOriginal: () => import("./ApvOriginal.vue"),
  },
})
export default class ApvContent extends Vue {
  @Prop() lineInfo!: LineInfo;
  @Prop() contentInfo!: ContentInfo;
  private contentHtml = "";
  private apvId = Number(this.$route.params.apvId);

  private buttons: Button[] = [];
  private rejectDic: any;
  private rejectDefault: string = "";

  //private tapedTwice: boolean = false;
  private contentEnlarged: boolean = false;

  private loading = true;
  private originalLoading = false;
  private showOriginal = false;

  private script: string = "";

  mounted() {
    this.getContentData().then(this.adjustContent);
  }

  async getContentData() {
    var userLang = this.$i18n.locale;
    var user = this.$store.getters.getCurrentUser;
    if (user != undefined) {
      return await ApprovalApi.getDocumentHtmlAsync(this.apvId, userLang, user.UserID)
        .then((result) => {
          if (result.success) {
            this.contentHtml = result.data[0].replace(/&nbsp;/gi, "");
            this.contentHtml = this.contentHtml.replace(/<input /gi, "<input disabled ");
            this.contentHtml = this.contentHtml.replace(/<select /gi, "<select disabled ");
            this.contentHtml = this.contentHtml.replace(/width="(.*)px"/gi, "");
            this.contentHtml = this.contentHtml.replace(/<td width="(.*)"/gi, "<td ");
            this.contentHtml = this.contentHtml.replace(/margin-right: (.*)px/gi, "");
            this.contentHtml = this.contentHtml.replace(/FONT-FAMILY: gulim;/gi, "");

            // 연동양식에서 추가된 클래스 대체
            /* AHH, 구매품의서 */
            this.contentHtml = this.contentHtml.replace(
              /class=('|")docTh('|")/gi,
              "class='docTh_'"
            );
            this.contentHtml = this.contentHtml.replace(
              /class=('|")docTd('|")/gi,
              "class='docTd_'"
            );

            // 액션 버튼
            this.buttons = result.data[1];

            this.rejectDic = result.data[2].rejectDic;
            this.rejectDefault = result.data[2].rejectDefault;

            this.script = result.data[3];

            this.loading = false;
          } else {
            this.loading = false;
            this.$log.error(result);
            // TODO(Dohyeon): 예외처리
          }
        })
        .catch((e) => {
          this.loading = false;
          this.$log.error(e);
        });
    }
  }

  /**
   * 본문 사이즈 확대/축소
   */
  // NOTE: Mobile에서 dbclick 이번트를 잡지 못해 수동으로 처리
  // toggleContentSize(evt: MouseEvent) {
  //   evt.preventDefault();
  //   if (!this.tapedTwice) {
  //     this.tapedTwice = true;
  //     setTimeout(() => {
  //       this.tapedTwice = false;
  //     }, 200);
  //     return false;
  //   }

  //   if (this.contentEnlarged) {
  //     (this.$refs.apvContent as HTMLElement).style.width = "";
  //   } else {
  //     (this.$refs.apvContent as HTMLElement).style.width = "1000px";
  //   }

  //   this.contentEnlarged = !this.contentEnlarged;
  // }

  adjustContent() {
    var content = this.$refs.apvContent as HTMLElement;

    if (!content) {
      return;
    }

    // iframe이 포함된 문서면 원문 스크린샷으로 보여줌
    var iframes = content.getElementsByTagName("iframe");
    if (iframes.length) {
      this.originalLoading = true;
      this.showOriginal = true;
      return;
    }

    var tableList = content.getElementsByTagName("table");

    // 가로로 긴 테이블 처리
    for (let i = 0; i < tableList.length; i++) {
      var table = tableList[i];

      /**** 연동양식에 대한 클래스 적용 - start ****/
      /* AHH, 구매품의서 */
      if (table.classList.contains("docTbl")) {
        table.classList.remove("docTbl");
        table.classList.add("docTbl_");
        table.classList.add("none-vert-table");
      }

      /* 클래스가 없는 테이블 HXM, 출장신청서 외 */
      if (!table.classList.length) {
        // 클래스를 추가해준다.
        this.setApvClasses(table);
      }
      /**** 연동양식에 대한 클래스 적용 - end ****/

      if (table.classList.contains("none-vert-table")) {
        continue;
      }

      let headers = table.getElementsByTagName("thead")[0]?.getElementsByTagName("th");

      if (headers?.length > 7) {
        this.setVerticalTable(table);
      } else {
        table.classList.add("hd-apv-table");
      }
    }

    // 명시적으로 세로 테이블로 선언한 경우
    var vertTables = content.getElementsByClassName("hd-vert-table__container");
    for (let i = 0; i < vertTables.length; i++) {
      let table = vertTables[i].getElementsByTagName("table");
      if (table.length) {
        this.setVerticalTable(table[0]);
      }
    }

    // 시간 표시 필드가 있는경우 확인
    var displayFields: Field[] = [];
    this.contentInfo.fields.forEach((field) => {
      if (field.fieldType != "hidden" && field.fieldType != "label") {
        displayFields.push(field);
      }
    });
    var fields = content.getElementsByTagName("td");
    if (fields?.length == displayFields?.length) {
      for (let i = 0; i < displayFields.length; i++) {
        if (displayFields[i].fieldType == "datetime") {
          try {
            var dateTimeText = (fields[i] as HTMLElement).innerText;
            // 시간표시
            // 대상 양식: HMX: Leave of Absence (LOA)
            if (displayFields[i].displayTimeYN != "N") {
              fields[i].innerText = dateTimeText.replace(`${dateTimeText.split(" ")[0]} `, "");
            }
            // 날짜 표시
            else {
              fields[i].innerText = dateTimeText.split(" ")[0];
            }
          } catch {
            //
          }
        }
      }
    }
    // 필드가 안 맞아서 확인이 어려운 경우는 기존 로직 유지
    else {
      var dates = content.getElementsByClassName("has-datepicker-group-td");
      for (let i = 0; i < dates.length; i++) {
        var dateItem = dates[i].firstElementChild;

        if (dateItem) {
          (dateItem as HTMLElement).innerText = (dateItem as HTMLElement).innerText?.split(" ")[0];
        }
      }
    }

    // 인라인 스타일 우선순위 높이기
    var styles = content.getElementsByTagName("style");
    for (let i = 0; i < styles.length; i++) {
      var style = styles[i];
      style.innerHTML = style.innerHTML.replace(/;/gi, " !important;");
    }

    // hyperlink를 외부에서 호출하기 위한 설정 --> @/vies/board/ItemView.vue에서 가져옴
    var hyperLinks = content.getElementsByTagName("a");
    for (let i = 0; i < hyperLinks.length; i++) {
      (hyperLinks[i] as HTMLAnchorElement).addEventListener("click", (e: Event) => {
        var url = (hyperLinks[i] as HTMLAnchorElement).href;

        if (url != undefined) {
          e.preventDefault();
          // ios에서
          try {
            window.webkit.messageHandlers.h1mobile.postMessage({
              command: "openExternBrowser",
              url: url,
            });
          } catch (e) {
            this.$log.error("not ios");
            this.$log.error(e);
          }

          // android에서
          try {
            window.h1mobile.openExternBrowser(url);
          } catch (e) {
            this.$log.error("not android");
            this.$log.error(e);
          }
        }
      });
    }

    this.executeInitializationScript();

    this.loading = false;
  }

  setApvClasses(table: HTMLTableElement) {
    table.classList.add("docTbl_");
    table.setAttribute("style", "");

    var rows = table.getElementsByTagName("tr");

    var isNoneVert = false;

    for (let i = 0; i < rows.length; i++) {
      rows[i].classList.add("tbl_row");

      var heads = table.getElementsByTagName("th");
      for (let j = 0; j < heads.length; j++) {
        let th = heads[j];
        th.classList.add("docTh_");
        th.classList.add("tbl_head");
        th.classList.add("td-header");
        th.setAttribute("style", "");
        if (th.colSpan > 1 || th.rowSpan > 1) {
          isNoneVert = true;
        }
      }

      let datas = table.getElementsByTagName("td");
      for (let k = 0; k < datas.length; k++) {
        let td = datas[k];
        td.classList.add("docTd_");
        td.classList.add("tbl_data");
        td.setAttribute("style", "");
        if (td.colSpan > 1 || td.rowSpan > 1) {
          isNoneVert = true;
        }
      }
    }

    if (isNoneVert) {
      table.classList.add("none-vert-table");
    }
  }

  // 양식 초기화 스크립트를 실행한다.
  // 결재의 모든 스크립트를 실행하는 것이 아니라, 보여야 하는 값이 안 보이는 경우가 있어 최소만 실행함
  executeInitializationScript() {
    if (!this.script.trim().length) {
      return;
    }

    let jquery = document.createElement("script");
    jquery.src = "https://ajax.googleapis.com/ajax/libs/jquery/3.3.1/jquery.min.js";
    document.getElementsByTagName("head")[0].appendChild(jquery);

    jquery.addEventListener("load", () => {
      // NOTE: script 안에 return 구문이 있는 경우 바로 실행하면 스크립트 에러가 발생하므로 IIFE형태로 만들어줌
      // script를 new line에 넣어준다. 그렇지 않으면 함수 닫는 부분이 주석처리되어버리는 경우가 있음.
      var initializationScript = `(function (){
        ${this.script}
      })();`;

      // 지우지 말 것. eval()에서 사용하는 변수임.
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      var g_approvalLineInfo = this.lineInfo;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      var g_LangCode = this.$i18n.locale;

      try {
        eval(initializationScript);
      } catch (e) {
        this.$log.error(e);
      }
    });
  }

  setVerticalTable(table: HTMLTableElement) {
    let colName: string[] = [];
    let headers = table.getElementsByTagName("thead")[0]?.getElementsByTagName("th");

    table.classList.add("hd-vert-table");
    table.parentElement?.classList.remove("tbl_horiz");
    table.parentElement?.classList.remove("tbl_vert");

    for (let j = 0; j < headers.length; j++) {
      colName.push(headers[j].innerText);
    }

    let rows = table.getElementsByTagName("tbody")[0].getElementsByTagName("tr");
    for (let k = 0; k < rows.length; k++) {
      let cols = rows[k].getElementsByTagName("td");
      if (cols.length && cols[0].innerText.trim().length == 0) {
        rows[k].style.display = "none";
        continue;
      }
      for (let l = 0; l < cols.length; l++) {
        let col = cols[l];
        col.setAttribute("data-label", colName[l]);
      }
    }
  }

  handleStateChanged() {
    //this.$emit("refresh");
    EventBus.$emit(EVENT.APPROVAL_REFRESH_DETAIL);
    this.getContentData().then(this.adjustContent);
  }
}
