














































































































import { Component, Vue, Prop } from "vue-property-decorator";
import User from "@/models/yellowPage/User";

@Component
export default class MemberDetail extends Vue {
  @Prop() memberInfo!: User;

  makeCall(phoneNumber: string): void {
    location.href = `tel:${phoneNumber}`;
  }

  mailTo(emailAddress: string): void {
    location.href = `mailto:${emailAddress}`;
  }
}
