





























import { Component, Vue } from "vue-property-decorator";
import MenuHandler from "@/common/menuHandler";

@Component
export default class StaticPage extends Vue {
  private menuName: string = "";

  mounted() {
    MenuHandler.findMenuByMenuId(this.$route.query.menuId as string).then((currentMenu) => {
      this.menuName = currentMenu?.ShortName ?? "";
    });

    var src = this.$route.query.url as string;

    (this.$refs.contentFrame as HTMLIFrameElement)?.contentWindow?.location.replace(src);
  }

  // iframe 내부 코드 처리 및 높이를 본문 높이에 맞게 조정
  // NOTE: 게시글 조회 페이지에서 가져오되 최소만 가져옴. cf: @/views/voard/ItemView.vue
  fnFrameLoadComplete() {
    var iframe: any = this.$refs.contentFrame;
    var $content = iframe.contentWindow.document;

    // 이미지 크기 조정 및 클릭 이벤트 등록
    var images = $content.getElementsByTagName("img");
    for (let index = 0; index < images.length; index++) {
      let el: HTMLImageElement = images[index];
      // 인라인 이미지 src도 회사 URL이 박혀있는 경우가 있음
      el.src = el.src.replace(/(https?:\/\/)(.*?\/)/gi, "");

      el.style.maxWidth = "100%";
      el.style.height = "auto";
      el.onload = this.handleHeight;
    }

    // 정적페이지용 스타일 적용 - start
    var tables = $content.getElementsByTagName("table");
    for (let index = 0; index < tables.length; index++) {
      tables[index].style.width = "";
      tables[index].style.margin = "";
    }

    var uls = $content.getElementsByTagName("ul");
    for (let index = 0; index < uls.length; index++) {
      uls[index].style.marginRight = "7px";
    }
    // 정적페이지용 스타일 적용 - end

    this.handleHeight();
  }

  // NOTE: 먼저 화면 전체가 로드된 뒤에 화면 크기를 재조정해야하는데, 이미지 url변경등으로 로딩이 늦어질 수 있으므로, 이미지 로드 되었을 때 다시 높이를 조절하게 한다
  handleHeight() {
    var iframe: any = this.$refs.contentFrame;
    var $content = iframe.contentWindow.document;
    var contentHeight = $content.body.offsetHeight;

    // NOTE: 상하 padding 각 16px 만큼 여유를 줌
    iframe.style.height = contentHeight + 32 + "px";
  }

  goBack() {
    if (history.length && history.state != null) {
      this.$router.go(-1);
    } else {
      this.$router.replace({ name: "Menu" });
    }
  }
}
