














































import { Component, Vue, Prop } from "vue-property-decorator";
import BoardApi from "@/api/boardApi";
// model
import * as CommentRequest from "@/models/board/request/CommentRequest";

// component

@Component
export default class CommentInput extends Vue {
  @Prop() boardId!: string;
  @Prop() itemId!: string;
  // 대댓 작성일 경우
  @Prop({ default: 0 }) parentCommentId!: number;
  // 댓글 수정일 경우
  @Prop({ default: false }) isEdit!: boolean;
  @Prop() oldComment!: string;
  @Prop() commentId!: number;
  @Prop() oldCommentName!: string;

  private commentInput: string = "";
  private saving = false;
  private commentName: string = "";
  private isAnonymous = (this.$route.query.isAnonymousStr === "true") as boolean;
  private iscommentNameFocused: boolean = false;

  mounted() {
    if (this.isEdit) {
      this.commentInput = this.oldComment;
      this.commentName = this.oldCommentName;
    }
  }
  //댓글 추가
  saveComment() {
    var content = this.commentInput;
    // NOTE: escapeHtml은 서버에서 처리
    this.saving = true;
    // 앞뒤 공백제거 후 체크
    content = content.replace(/(^\s*)|(\s*$)/gi, "");
    if (content.length > 0) {
      if (this.isEdit) {
        this.UpdateComment(content);
      } else {
        this.InsertComment(content);
      }
    } else {
      this.saving = false;
    }
  }

  // 댓글 등록
  InsertComment(content: string) {
    var req: CommentRequest.Insert = {
      ListID: this.boardId,
      ItemID: this.itemId,
      Content: content,
      ParentCommentID: this.parentCommentId,
      IsAnonymousUserMode: this.isAnonymous,
      DisplayName: this.commentName,
    };
    BoardApi.insertCommentAsync(req)
      .then(() => {
        this.commentInput = "";
        this.commentName = "";
        this.$emit("commentAdded");

        this.$q.notify({
          group: false,
          message: this.$t("Board_CommentPostedMessage").toString(),
          color: "white",
          textColor: "primary",
          timeout: 500,
        });
        this.saving = false;
      })
      .catch(() => {
        this.saving = false;
      });
  }

  // 댓글 수정
  UpdateComment(content: string) {
    var req: CommentRequest.Update = {
      ListID: this.boardId,
      ItemID: this.itemId,
      Content: content,
      commentID: this.commentId,
    };
    BoardApi.updateCommentAsync(req)
      .then(() => {
        this.commentInput = "";
        this.$emit("commentAdded");

        this.$q.notify({
          message: this.$t("Board_CommentModifieddMessage").toString(),
          group: false,
          color: "white",
          textColor: "primary",
          timeout: 500,
        });
        this.saving = false;
      })
      .catch(() => {
        this.saving = false;
      });
  }
  // 댓글창에 포커스
  setFocus() {
    if (!this.iscommentNameFocused){
      (this.$el.querySelector("textarea") as HTMLTextAreaElement).focus();
    }
  }

  setCommentNameFocus(isFocus: boolean){
    if (isFocus) {
      (this.$refs["commentName"] as HTMLInputElement).focus();
    }
    else{
      (this.$refs["commentName"] as HTMLInputElement).blur();
    }
    this.iscommentNameFocused = isFocus;
  }

  handleCancel() {
    this.commentInput = "";
    this.commentName = "";
    if (this.parentCommentId != 0 || this.isEdit) {
      this.$emit("cancel");
    }
  }

  getInlineStyle(): string {
    var style = "";
    if (this.parentCommentId != 0 || this.isEdit) {
      style += "margin-left:0; margin-right:0;";
    }
    if (this.isEdit) {
      style += "margin-top:0;";
    }
    return style;
  }
}
