






























































































import { Component, Vue } from "vue-property-decorator";
import DATEHANDLER from "@/common/dateHandler";
import MenuHandler from "@/common/menuHandler";
import notificationApi from "@/api/noficationApi";
// models
import MessageSearchRequest from "@/models/notification/request/MessageSearchRequest";
import NotificationMessage from "@/models/notification/NotificationMessage";
import * as NotiExData from "@/models/notification/NotiExData";
// component
import HdLoadingMask from "@/components/HdLoadingMask.vue";
import HdSearchInput from "@/components/HdSearchInput.vue";
import scroll from 'quasar/src/utils/scroll.js';;

@Component({
  components: {
    HdLoadingMask,
    HdSearchInput,
    HdSpinnerDots: () => import("@/components/HdSpinnerDots.vue"),
    SetNotificationRead: () => import("./SetNotificationRead.vue"),
  },
})
export default class Notification extends Vue {
  private keyword: string = "";
  private pageNumber: number = 1;
  private readonly pageSize: number = 30;
  private notiList: NotificationMessage[] = [];
  private loading: boolean = true;
  private unreadCount: number = 0;
  private totalCount: number = 0;
  // 알림처리 화면
  private setReadDialog: boolean = false;

  mounted() {
    this.loadNotificationList(false);
  }

  // 무한 스크롤 적용
  onScroll() {
    if (this.loading) {
      return;
    }
    var item = scroll.getScrollTarget(this.$root.$el);
    var scrollHeight = scroll.getScrollHeight(item);
    var maxOffset = scrollHeight - (item as Window).outerHeight;
    var offset = scroll.getScrollPosition(item);
    if (offset > 0.9 * maxOffset) {
      this.loadNotificationList(true);
    }
  }

  // 알림 목록 불러오기
  loadNotificationList(loadMore: boolean = false) {
    if (loadMore) {
      this.pageNumber++;

      // 더 불러올 게 없음
      if (this.totalCount <= this.loadNotificationList.length) {
        return;
      }
    }

    var request: MessageSearchRequest = {
      Keyword: this.keyword,
      WithObserved: true,
      PageNumber: this.pageNumber,
      PageSize: this.pageSize,
    };

    this.loading = true;
    notificationApi
      .getMessageList(request)
      .then((result) => {
        if (result.Success) {
          let data = result.Data;

          this.unreadCount = data.UnreadCount;
          this.totalCount = data.TotalCount;

          data.MessageList.forEach((item) => {
            switch ((item.exdata as NotiExData.defaultData).PushSystem?.toLowerCase()) {
              case "board": {
                let data = item.exdata as NotiExData.boardData;
                MenuHandler.findMenuByUrl(data.LinkUrl).then((mobileMenu) => {
                  if (mobileMenu) {
                    item.url = `${data.LinkUrl}/itemView?boardId=${data.BoardId}&itemId=${data.ItemId}`;
                  } else {
                    item.url = null;
                  }
                });
                break;
              }
              case "approval": {
                let data = item.exdata as NotiExData.approvalData;
                item.url = `approval/view/${data.PushItemId}`;
                break;
              }
              case "reservation": {
                let data = item.exdata as NotiExData.reservationData;
                item.url = `reservation/myReservations?reservationId=${data.ReservationID}`;
                break;
              }
              default:
                item.url = null;
            }
          });

          if (loadMore) {
            this.notiList = this.notiList.concat(data.MessageList);
            this.loading = false;
          } else {
            this.notiList = data.MessageList;
            this.loading = false;
          }
        } else {
          this.$log.debug(result.Message);
          this.loading = false;
        }
      })
      .catch((e) => {
        this.loading = false;
        this.$log.error(e);
      });
  }

  // 뒤로가기
  handleBack() {
    if (history.length && history.state != null) {
      this.$router.go(-1);
    } else {
      this.$router.replace({ name: "Front" });
    }
  }

  getDateAsYYYYMMDDHHMM(date: string) {
    return DATEHANDLER.dateAsYYYYMMDDHHmm(date, this.$i18n.locale);
  }

  getMultilangContent(item: any) {
    var lang = this.$i18n.locale;

    var content = item[lang];
    if (content == null) {
      content = item._;
    }

    return content;
  }

  // 해당 알림으로 이동
  moveTo(noti: NotificationMessage) {
    if (noti.url == null || noti.url == undefined) {
      return;
    }

    //읽음처리
    notificationApi.setMessageRead([noti.index]);

    this.$router.push({ path: noti.url });
  }

  // 알림 검색
  handleSearch(keyword: string) {
    this.keyword = keyword;
    this.loadNotificationList(false);
  }

  // 검색결과 강조
  highlightText(text: string): string {
    if (text == undefined) {
      return text;
    }

    text = text.replace(/</gi, "＜").replace(/>/gi, "＞");
    // 입력 키워드 없음
    if (this.keyword == "" || this.keyword == undefined) return text;

    var keyword = this.keyword;
    return text.replace(new RegExp(keyword, "gi"), (match) => {
      return "<strong>" + match + "</strong>";
    });
  }

  // 메시지 읽음 여부 체크
  isObserved(dateStr: string | undefined) {
    if (dateStr == "" || dateStr == undefined) {
      return false;
    } else {
      var date = new Date(dateStr);
      var dateUndefined = new Date("0001-01-01T00:00:00");
      return date.toString() != dateUndefined.toString();
    }
  }

  // 읽음처리 창 닫기
  handleSetReadDialogClosed() {
    this.setReadDialog = false;
    this.loadNotificationList(false);
  }
}
