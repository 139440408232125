import i18n from "@/libs/lang";
import * as ApvEnum from "./ApvEnums";

// export function apvTypeToString(state: ApvEnum.ApvTypeCode): string {
//   if (typeof state === "string") {
//     const str: string = (state as string).replace(".", "_");
//     const key = str as keyof typeof ApvEnum.ApvTypeCode;
//     state = ApvEnum.ApvTypeCode[key];
//   }

//   switch (state) {
//     case ApvEnum.ApvTypeCode.ATY_001:
//       return "결재";
//     case ApvEnum.ApvTypeCode.ATY_002:
//       return "대결";
//     case ApvEnum.ApvTypeCode.ATY_003:
//       return "전결";
//     case ApvEnum.ApvTypeCode.ATY_004:
//       return "순차협조";
//     case ApvEnum.ApvTypeCode.ATY_005:
//       return "병렬협조";
//     case ApvEnum.ApvTypeCode.ATY_006:
//       return "검토";
//     case ApvEnum.ApvTypeCode.ATY_007:
//       return "후열";
//     case ApvEnum.ApvTypeCode.ATY_008:
//       return "기안";
//     case ApvEnum.ApvTypeCode.ATY_009:
//       return "담당";
//     case ApvEnum.ApvTypeCode.ATY_010:
//       return "부서합의";
//     case ApvEnum.ApvTypeCode.ATY_011:
//       return "담당그룹결재";
//     case ApvEnum.ApvTypeCode.ATY_012:
//       return "합의";
//     case ApvEnum.ApvTypeCode.EMPTY:
//     default:
//       // return "해당업음";
//       return "";
//   }
// }

// export function apvTitleCodeToString(state: ApvEnum.ApvTitleCode): string {
//   if (typeof state === "string") {
//     const str: string = (state as string).replace(".", "_");
//     const key = str as keyof typeof ApvEnum.ApvTitleCode;
//     state = ApvEnum.ApvTitleCode[key];
//   }

//   switch (state) {
//     case ApvEnum.ApvTitleCode.AT_001:
//       return "기안";
//     case ApvEnum.ApvTitleCode.AT_002:
//       return "검토";
//     case ApvEnum.ApvTitleCode.AT_003:
//       return "승인";
//     case ApvEnum.ApvTitleCode.AT_004:
//       return "담당";
//     case ApvEnum.ApvTitleCode.AT_005:
//       return "병렬협조";
//     case ApvEnum.ApvTitleCode.AT_006:
//       return "순차협조";
//     default:
//       return "해당없음";
//   }
// }

// export function processStepCurrentStausCodeToString(
//   status: ApvEnum.ProcessStepCurrentStausCode | string
// ): string {
//   if (typeof status === "string") {
//     const str: string = (status as string).replace(".", "_");
//     const key = str as keyof typeof ApvEnum.ProcessStepCurrentStausCode;
//     status = ApvEnum.ProcessStepCurrentStausCode[key];
//   }

//   switch (status) {
//     case ApvEnum.ProcessStepCurrentStausCode.EMPTY:
//       return "해당 사항 없음";
//     case ApvEnum.ProcessStepCurrentStausCode.AS_001:
//       return "대기";
//     case ApvEnum.ProcessStepCurrentStausCode.AS_002:
//       return "결재";
//     case ApvEnum.ProcessStepCurrentStausCode.AS_003:
//       return "반려";
//     case ApvEnum.ProcessStepCurrentStausCode.AS_004:
//       return "한단계 반려";
//     case ApvEnum.ProcessStepCurrentStausCode.AS_005:
//       return "병렬협조진행";
//     case ApvEnum.ProcessStepCurrentStausCode.AS_006:
//       return "보류";
//     case ApvEnum.ProcessStepCurrentStausCode.AS_007:
//       return "철회";
//     case ApvEnum.ProcessStepCurrentStausCode.AS_008:
//       return "전결 진행";
//     case ApvEnum.ProcessStepCurrentStausCode.AS_009:
//       return "후열";
//     case ApvEnum.ProcessStepCurrentStausCode.AS_010:
//       return "이전 결재자의 결재 취소";
//     case ApvEnum.ProcessStepCurrentStausCode.AS_011:
//       return "수신 문서 기안 부서 반려";
//     case ApvEnum.ProcessStepCurrentStausCode.AS_012:
//       return "동의";
//     case ApvEnum.ProcessStepCurrentStausCode.AS_013:
//       return "다른 의견";
//     case ApvEnum.ProcessStepCurrentStausCode.AS_014:
//       return "담당자변경";
//     case ApvEnum.ProcessStepCurrentStausCode.AS_015:
//       return "자동결재";
//     case ApvEnum.ProcessStepCurrentStausCode.AS_016:
//       return "시스템 결재취소";
//     default:
//       return "";
//   }
// }

export function lineStatusToString(status: ApvEnum.LineStatusCode): string {
  switch (status) {
    case ApvEnum.LineStatusCode.LS_001:
      return i18n.t("LineStatus_LS_001").toString();
    case ApvEnum.LineStatusCode.LS_002:
      return i18n.t("LineStatus_LS_002").toString();
    case ApvEnum.LineStatusCode.LS_003:
      return i18n.t("LineStatus_LS_003").toString();
    case ApvEnum.LineStatusCode.LS_004:
      return i18n.t("LineStatus_LS_004").toString();
    case ApvEnum.LineStatusCode.LS_005:
      return i18n.t("LineStatus_LS_005").toString();
    case ApvEnum.LineStatusCode.LS_006:
      return i18n.t("LineStatus_LS_006").toString();
    case ApvEnum.LineStatusCode.LS_007:
      return i18n.t("LineStatus_LS_007").toString();
    case ApvEnum.LineStatusCode.LS_008:
      return i18n.t("LineStatus_LS_008").toString();
    case ApvEnum.LineStatusCode.LS_009:
      return i18n.t("LineStatus_LS_009").toString();
    case ApvEnum.LineStatusCode.LS_010:
      return i18n.t("LineStatus_LS_010").toString();
    case ApvEnum.LineStatusCode.LS_011:
      return i18n.t("LineStatus_LS_011").toString();
    case ApvEnum.LineStatusCode.LS_012:
      return i18n.t("LineStatus_LS_012").toString();
    case ApvEnum.LineStatusCode.LS_013:
      return i18n.t("LineStatus_LS_013").toString();
    case ApvEnum.LineStatusCode.LS_014:
      return i18n.t("LineStatus_LS_014").toString();
    case ApvEnum.LineStatusCode.LS_015:
      return i18n.t("LineStatus_LS_015").toString();
    case ApvEnum.LineStatusCode.EMPTY:
    default:
      return i18n.t("LineStatus_EMPTY").toString();
  }
}

// export function rejectCodeToString(rejectCode: string): string {
//   switch (rejectCode) {
//     case "0":
//       return "승인";
//     case "1":
//       // return "한단계반려";
//       return "반려";
//     case "2":
//       return "기안반려";
//     case "3":
//       return "보류";
//     case "4":
//       return "보류해제";
//     case "5":
//       return "회수";
//     case "6":
//       return "후열";
//     case "7":
//       return "결재철회";
//     case "8":
//       return "수신기안부서반려";
//     case "9":
//       return "자가승인";
//     case "a":
//       return "동의";
//     case "d":
//       return "다른의견";
//     default:
//       return "";
//   }
// }

export function accessToString(access: string): string {
  switch (access) {
    case "ALL":
      return i18n.t("AccesTo_All").toString();
    case "AA.001":
      return i18n.t("AccesTo_AA_001").toString();
    case "AA.002":
      return i18n.t("AccesTo_AA_002").toString();
    case "AA.003":
      return i18n.t("AccesTo_AA_003").toString();
    case "AA.004":
      return i18n.t("AccesTo_AA_004").toString();
    case "NONE":
    default:
      return i18n.t("AccesTo_NONE").toString();
  }
}

// export function lineStepTypeToString(stepType: ApvEnum.LineStepType): string {
//   switch (stepType) {
//     case ApvEnum.LineStepType.User:
//       return "사용자";
//     case ApvEnum.LineStepType.ApvRoleGroup:
//       return "결재자그룹";
//     case ApvEnum.LineStepType.Dept:
//       return "부서";
//   }
// }
