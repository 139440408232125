







































































import { Component, Vue, Prop } from "vue-property-decorator";
import DATEHANDLER from "@/common/dateHandler";
import UTILS from "@/common/utilities";
// models
import LineInfo from "@/models/approval/LineInfo";
import { CircularListType } from "@/models/approval/CircularListType";
// components
import ApvLineUserCard from "./ApvLineUserCard.vue";
import Attachment from "@/models/approval/Attachment";
import Receipt from "@/models/approval/Receipt";

@Component({
  components: {
    ApvLineUserCard,
    HdAlternativeMessage: () => import("@/components/HdAlternativeMessage.vue"),
  },
})
export default class ApvReceivers extends Vue {
  @Prop() lineInfo!: LineInfo;
  @Prop() apvId!: number;
  @Prop() userId!: string;

  private circularList: CircularListType[] = [];

  mounted() {
    // NOTE: this.lineInfo.apvLineCircularList에 회람 목록이 있긴 하지만 조회여부, 코멘트 등의 정보를 확인할 수 없어 별도로 가져온다.
    // NOTE(2021-04-05): 구성이 까다로워서 우선 회람은 제외한다.
    // ApprovalApi.getCircularListAsync(this.apvId, this.userId).then((result) => {
    //   this.circularList = result.data;
    // });
  }

  getDateAsYYYYMMDDHHmm(date: string): string {
    if (!date.length) {
      return "";
    }
    return DATEHANDLER.dateAsYYYYMMDDHHmm(date, this.$i18n.locale);
  }

  // getCircularDisplayName(circular: CircularListType) {
  //   switch (circular.memberType) {
  //     case 0: // 사용자
  //       return `${circular.cuDisplayName}`;
  //     case 1: // 부서
  //       return `${circular.circularDeptName}`;
  //   }

  //   return "";
  // }

  downloadAttachment(attach: Attachment) {
    location.href = `/SmartTalk/Attach/Response/DownLoad.ashx?id=${attach.attachID}`;
  }

  /**
   * 수신처 / 사본(참조) 목록
   */
  getRecipientstWithGubun(gubun: "T" | "C"): Receipt[] {
    var list = this.lineInfo.receipt.filter((o) => {
      return o.gubun == gubun;
    });
    return list;
  }

  getReceipientDisplayName(recipient: Receipt): string {
    switch (recipient.receiptType) {
      case "U": // 사용자
        return recipient.userName;
      case "D": // 부서
        return recipient.deptName;
    }
  }

  getIconClass(fileName: string) {
    return UTILS.getIconClassFromFileName(fileName);
  }
}
