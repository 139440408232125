




















































































import { Component, Vue, Prop } from "vue-property-decorator";
import DATEHANDLER from "@/common/dateHandler";
// model
import ReservationInfo from "../models/ReservationInfo";

@Component
export default class ReservationDetail extends Vue {
  @Prop() resInfo!: ReservationInfo;

  private stateClassName: string = "badge";
  private state: string = "";

  mounted() {
    switch (this.resInfo.processState) {
      case "B":
        // 접수 완료
        this.stateClassName = "badge color01";
        this.state = this.$t("ReservationProcessTypeB").toString();
        break;
      case "A":
        // 접수중
        this.stateClassName = "badge color06";
        this.state = this.$t("ReservationProcessTypeA").toString();
        break;
      case "C":
        // 접수 불가
        this.stateClassName = "badge color07";
        this.state = this.$t("ReservationProcessTypeC").toString();
        break;
      default:
        this.stateClassName = "";
    }
  }

  getDateAsYYYYMMDD(date: Date) {
    return DATEHANDLER.dateToStrYYYYMMDD(date, this.$i18n.locale);
  }

  getComma(index: number, length: number) {
    return index < length - 1 ? ", " : "";
  }
}
