import Vue from "vue";

// ts파일에서 scss import시 오류가 발생하여 App.vue로 이동
import "./styles/quasar.scss";

import "@quasar/extras/material-icons/material-icons.css";
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/Notify.js';import Ripple from 'quasar/src/directives/Ripple.js';;

Vue.use(Quasar, {
  directives: {
    Ripple,
  },
  config: {
    notify: {
      color: "primary",
    },
  },
  plugins: { Notify },
});
