



import { Component, Vue } from "vue-property-decorator";
import UserInfo from "@/models/board/UserInfo";
// component
import HdLoadingMask from "@/components/HdLoadingMask.vue";
import { Dictionary } from "vue-router/types/router";

@Component({
    components: {
        HdLoadingMask,
    },
})
export default class QRCode extends Vue {
    private user: UserInfo = {} as UserInfo;
    private loading: boolean = true;
    private app: string = '';
    
    data() {
        return {
            app: ''
        };
    }
    mounted() {
        this.user = this.$store.getters.getCurrentUser;
        this.loading = false;
        let urlParams = new URLSearchParams(location.search);

        try
        {
            if (this.$route.query.qr != undefined)
            {
                let qrUrl = new URL(this.$route.query.qr.toString());
                urlParams = new URLSearchParams(qrUrl.search);
                this.app = urlParams.get("app") as string;
            }
            else
            {
                this.app = urlParams.get("app") as string;
            }
        }
        catch {}

        switch(this.app)
        {
            case "hmx_seat":
                window.location.replace(`${location.origin}/Mobile/qrcode/SeatHMX?${urlParams.toString()}`);
                break;
            default:
                window.location.replace(`${location.origin}/Mobile/qrcode/SeatHMX?${urlParams.toString()}`);
                break;
        }        
    }
}
