import axios from "axios";
import ApiResult from "@/models/ApiResult";
import SeatCheckInResponse from "@/models/qrcode/response/SeatCheckInResponse";
import SeatCheckOutResponse from "@/models/qrcode/response/SeatCheckOutResponse";

export default {
    
    // 좌석 체크인 함수
    async seatCheckIn(si: string, userid: string): Promise<ApiResult<SeatCheckInResponse>> {
        const response = await axios.get<ApiResult<SeatCheckInResponse>>(
            `/Mobile/api/v1/qr/seatCheckIn?userid=${encodeURIComponent(userid)}&si=${encodeURIComponent(si)}`            
        );
        return response.data;
    },

    // 좌석 체크아웃 함수
    async seatCheckOut(rn: string, userid: string): Promise<ApiResult<SeatCheckOutResponse>> {
        const response = await axios.get<ApiResult<SeatCheckInResponse>>(
            `/Mobile/api/v1/qr/seatCheckOut?userid=${encodeURIComponent(userid)}&rn=${encodeURIComponent(rn)}`
        );
        return response.data;
    }
}