


















































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { mapActions } from "vuex";
import YellowPageApi from "@/api/yellowPageApi";
// model
import KVType from "@/models/common/KVType";
import DeptTree from "../../models/yellowPage/DeptTree";
import User from "@/models/yellowPage/User";
import DeptMemberRequest from "../../models/yellowPage/request/DeptMemberRequest";

@Component({
  components: {
    MemberList: () => import("./MemberList.vue"),
  },
  methods: {
    ...mapActions(["loadConfigs"]),
  },
})
export default class DeptView extends Vue {
  @Prop() selectedCompany!: KVType;
  private loadConfigs!: () => Promise<any>;
  private companyCode = "";
  private currentDept: DeptTree = {} as DeptTree;
  private langCode = this.$i18n.locale;
  private depts: DeptTree[] = [];
  private pageNumber = 1;
  private pageSize = 10;
  // 멤버
  private members: User[] = [];
  private memberLoading = true;
  private hasMoreMember = true;
  private totalCount = 0;
  // 한 번 조회한 회사의 부서 목록을 가지고 있도록 함
  private companyDeptDic: Map<string, DeptTree[]> = new Map();
  // view 관련
  private isRootDept = true;
  private isDeptLevel1 = true;

  mounted() {
    this.resolvePath();
  }

  // 하위 부서로 이동
  moveToSubDept(index: number): void {
    this.currentDept = this.currentDept.Children[index];
    this.setMemberList();

    // 뒤로가기를 사용할 수 있도록 history 추가
    //this.$router.push({ path: this.currentDept.DeptCode, append: true });
    this.$router.push({
      params: { depts: this.getActualPath() },
      append: false,
    });

    return;
  }

  // 부서 목록 트리 -> 회사를 변경했을 때 실행
  async setDeptTree(): Promise<string> {
    if (this.companyDeptDic.get(this.companyCode) != undefined) {
      var cachedDepts = this.companyDeptDic.get(this.companyCode) ?? [];
      this.depts = cachedDepts;
      this.currentDept = cachedDepts[0];
      return this.currentDept.DeptCode;
    }

    var result = await YellowPageApi.getDeptListAsync(this.companyCode, this.langCode);

    if (result && result[0] != undefined) {
      this.depts = result;
      this.companyDeptDic.set(this.companyCode, result);
      // 최상위 부서는 회사이므로 그 아래로 바인딩한다.
      this.currentDept = result[0];
    }
    return this.currentDept.DeptCode;
  }

  goBack() {
    if (history.length && history.state != null) {
      this.$router.go(-1);
    } else {
      this.$router.replace({ name: "Front" });
    }
  }

  // 최상위 부서로 이동
  moveToCompanyRoot(): void {
    this.setDeptTree().then(() => {
      this.setMemberList();
    });

    // 뒤로가기를 사용할 수 있도록 history 추가
    this.$router.push({ name: "DeptView", params: { companyCode: this.companyCode } });
  }

  handleLoadMore() {
    if (this.memberLoading) return;
    if (this.hasMoreMember) {
      this.pageNumber = this.pageNumber + 1;
      this.setMemberList(true);
    }
  }

  // 부서 소속 멤버
  async setMemberList(loadMore: boolean = false) {
    this.memberLoading = true;

    if (!loadMore) {
      this.pageNumber = 1;
      this.members = [];
    }

    var request: DeptMemberRequest = {
      CompanyCode: this.companyCode,
      PageNumber: this.pageNumber,
      PageSize: this.pageSize,
      DeptCode: this.currentDept.DeptCode,
      LangCode: this.langCode,
    };

    return await YellowPageApi.getDeptMemberListAsync(request)
      .then((result) => {
        this.loadConfigs().then((config) => {
          result.Items.forEach((user) => {
            user.PhotoURL = config.resource.photoUrl.replace("{0}", user.UserID);
          });
        });

        if (loadMore) {
          this.members = this.members.concat(result.Items);
        } else {
          this.members = result.Items;
        }
        this.hasMoreMember = result.HasMore;
        this.memberLoading = false;
        this.totalCount = result.TotalCount;
      })
      .catch((e) => {
        this.$log.debug(e);
        this.memberLoading = false;
      });
  }

  startSearch() {
    this.$router.push({ name: "MemberSearch" });
  }

  async resolvePath(): Promise<void> {
    if (this.$route.name != "DeptView") {
      return;
    }

    this.companyCode = this.$route.params.companyCode;
    var deptPathStr = this.$route.params.depts;

    await this.setDeptTree();
    try {
      if (deptPathStr != undefined) {
        var deptPath = deptPathStr.split("/").filter((value, index, self) => {
          return self.indexOf(value) === index;
        });
        var tempDeptTree = this.depts;

        var temp = tempDeptTree[0];

        if (deptPath.length == 1) {
          this.isRootDept = true;
          this.isDeptLevel1 = false;
        } else if (deptPath.length == 2) {
          this.isRootDept = false;
          this.isDeptLevel1 = true;
        } else {
          this.isRootDept = false;
          this.isDeptLevel1 = false;
        }

        deptPath.forEach((dept) => {
          temp = tempDeptTree.find((o) => o.DeptCode == dept)!;
          tempDeptTree = temp.Children;
        });

        this.currentDept = temp;

        await this.setMemberList();
      } else {
        // 루트 부서를 추가
        this.$router
          .push({
            params: { companyCode: this.companyCode, depts: this.currentDept.DeptCode },
          })
          .catch((e) => {
            this.$log.warn(e);
            //
          });
      }
    } catch (e) {
      this.$log.debug(e);
      this.$router.replace({ params: { companyCode: this.companyCode } }).catch((e) => {
        this.$log.warn(e);
      });
    }
  }

  getActualPath() {
    var path = this.currentDept.DeptPath.replace(/^\//, "");
    var root = path.split("/")[0];

    // NOTE: tb_Dept에서 DeptPath 구조가 개발이랑 운영이 달라서 우선 따로 적용. 개발이랑 운영 데이터 맞으면 다시 수정하기
    return process.env.NODE_ENV === "production" ? path.replace(`${root}/`, "") : path;
  }

  // 뒤로가기를 클릭했을 때, Route 정보를 이용해 이전 부서를 렌더링
  @Watch("$route")
  handleRouteChanged(): void {
    if (this.$route.name == "DeptView") {
      this.resolvePath();
    }
  }
}
